import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import {
  ConformityQuestionItem,
  QualivisaFormPieceContentResponseItem,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { QUALIVISA_ANOMALY_LEVEL } from "@utils/enums/qualivisa.enum";
import { ConformityAnswersDictionary } from "@state/qualivisa/dto/quali.visa.save.request.dto";
import { CATEGORY_CONFORMITY_QUESTION } from "@utils/qualivisa-mapper";
import { QualivisaCriteriaCheck } from "@views/pro/gazProfessional/qualivisa/QualivisaCriteriaCheck";

interface QualivisaCriteriaCheckProps {
  conformityQuestions: ConformityQuestionItem[];
  conformityAnswers: ConformityAnswersDictionary;
  setConformityAnswers: Dispatch<SetStateAction<ConformityAnswersDictionary>>;
  className?: string;
  showNew?: boolean;
  showExisting?: boolean;
  pieceContent: QualivisaFormPieceContentResponseItem[];
}

export const QualivisaConformityQuestions: FC<QualivisaCriteriaCheckProps> = ({
  conformityQuestions,
  conformityAnswers,
  setConformityAnswers,
  className = "",
  showNew = true,
  showExisting = true,
  pieceContent,
}) => {
  const { t } = useTranslation();

  const categoryConformityQuestion = [
    "TUYAUTERIE_FIXE",
    "ORGANE_COUPURE",
    "LYRE_GPL",
    "APPAREILS_ALIMENTATION_GAZ",
    "ALIMENTATION_GAZ_APPAREILS",
    "ETANCHEITE",
    "VENTILATION_A",
    "VENTILATION_B",
    "VENTILATION_A_B",
    "VENTILATION_B_VMC",
    "VENTILATION_A_B_VMC",
    "EVACUATION_COMBUSTION_B_TIRAGE_NAT",
    "EVACUATION_COMBUSTION_B_PRESSION",
    "EVACUATION_COMBUSTION_B_VMC",
    "EVACUATION_COMBUSTION_C",
    "CENR",
    "ALIMENTATION_TIGE_CUISINE",
    "SPE",
    "ESSAIS_APPAREILS_CUISSON",
    "ESSAIS_CENR",
    "ESSAIS_B",
    "PRODUITS_COMBUSTION",
  ];

  const categoryQuestion = categoryConformityQuestion.map((s) =>
    conformityQuestions.filter(
      (conformityQuestion) => conformityQuestion.section === s,
    ),
  );

  const toggleAnswerValue = (
    questionKey: string,
    updatedValue: "ok" | "existing" | "new",
  ) => {
    const newAnswers = JSON.parse(JSON.stringify(conformityAnswers)); // Deep clone de l'objet nécessaire pour que react reconnaisse que le state a été modifié
    let newAnswerSplit = conformityAnswers[questionKey]?.split(",") ?? [];

    const alreadySelected = newAnswerSplit.includes(updatedValue);
    if (updatedValue === "ok" && !alreadySelected) {
      newAnswerSplit = ["ok"]; //On écrase les réponses "new" et "existing" si il y en a
    } else {
      if (alreadySelected) {
        newAnswerSplit = newAnswerSplit.filter((item) => item !== updatedValue);
      } else {
        if (newAnswerSplit.includes("ok")) {
          newAnswerSplit = [updatedValue]; //On écrase la réponses "ok"
        } else {
          newAnswerSplit = [...newAnswerSplit, ...[updatedValue]];
        }
      }
    }
    newAnswers[questionKey] = newAnswerSplit.join(",");
    setConformityAnswers(newAnswers);
  };

  const conformityAnswersWithTab = [
    "7.1",
    "7.5",
    "7.8",
    "8.1",
    "9.1",
    "9.5",
    "9.8",
    "10.1",
    "11.1",
  ];

  const casePieceMapping: Record<string, string[]> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    "7.1": ["CENR_A", "CENR_A+C", "OCA_A", "OCA_A+C", "AdC_A", "AdC_A+C"],
    "7.5": ["CENR_A", "CENR_A+C", "OCA_A", "OCA_A+C", "AdC_A", "AdC_A+C"],
    "7.8": ["OCA_A", "OCA_A+C", "AdC_A", "AdC_A+C"],
    "8.1": ["OCA_B_TN", "OCA_B_PRESSION", "B_TN", "B_PRESSION"],
    "9.1": [
      "AdC_A+B_VMC",
      "AdC_A+B_TN",
      "AdC_A+B_PRESSION",
      "CENR_A+B_TN",
      "CENR_A+B_PRESSION",
      "OCA_B_VMC+AdC_A",
      "OCA_B_TN+CENR_A",
    ],
    "9.5": [
      "AdC_A+B_VMC",
      "AdC_A+B_TN",
      "AdC_A+B_PRESSION",
      "CENR_A+B_TN",
      "CENR_A+B_PRESSION",
      "OCA_B_VMC+AdC_A",
      "OCA_B_TN+CENR_A",
    ],
    "9.8": ["AdC_A+B_VMC", "AdC_A+B_TN", "AdC_A+B_PRESSION", "OCA_B_VMC+AdC_A"],
    "10.1": ["OCA_B_VMC", "B_VMC"],
    "11.1": ["OCA_B_VMC+AdC_A", "AdC_A+B_VMC"],
  };

  const getPieceContent = (conformityAnswerStep: string) => {
    const validCasePieces = casePieceMapping[conformityAnswerStep];
    return validCasePieces
      ? pieceContent.filter((piece) =>
          validCasePieces.includes(piece.casePiece),
        )
      : undefined;
  };

  return (
    <div className={`app-qualivisa-criteria-check ${className}`}>
      <BasicDivider variant="grey" height={1} className="w-100 mt-0 mb-20" />
      <p className="mt-0 mb-10">
        {t<string>("pro.qualivisa.form.criteria.title")}
      </p>
      <div className="qualivisa-criteria-check-items">
        {categoryQuestion.map((category, index) => {
          return (
            <>
              {category.length > 0 && (
                <h2 className="mt-20 text-primary" key={index.toString()}>
                  {category[0]?.section &&
                    CATEGORY_CONFORMITY_QUESTION[category[0]?.section]?.label}
                </h2>
              )}

              {category
                .sort(function (a, b) {
                  return parseInt(a.key.slice(-2)) - parseInt(b.key.slice(-2));
                })
                .map((conformityQuestion, categoryIndex) => {
                  const answer = conformityAnswers[conformityQuestion.key];
                  return (
                    <div key={categoryIndex}>
                      {conformityAnswersWithTab.includes(
                        conformityQuestion.step,
                      ) && (
                        <>
                          {getPieceContent(conformityQuestion.step)?.map(
                            (pieceContent) => {
                              return (
                                <QualivisaCriteriaCheck
                                  pieceContent={pieceContent}
                                  key={pieceContent.id}
                                />
                              );
                            },
                          )}
                        </>
                      )}
                      <div
                        key={conformityQuestion.key}
                        className="qualivisa-criteria-check-item"
                      >
                        <div className="qualivisa-criteria-check-item-content">
                          <p>{`${conformityQuestion.step} ${conformityQuestion.label}`}</p>
                          <div className="qualivisa-criteria-check-item-content-invalidity">
                            <span className="qualivisa-criteria-check-item-content-invalidity-label">
                              {t<string>(
                                "pro.qualivisa.form.criteria.invalidity",
                              )}
                            </span>
                            {showNew && conformityQuestion.showNew && (
                              <>
                                <span className="qualivisa-criteria-check-item-content-invalidity-label-type">
                                  {showExisting &&
                                    t<string>(
                                      t<string>(
                                        "pro.qualivisa.form.criteria.new",
                                      ),
                                    )}
                                </span>
                                <div
                                  className={`qualivisa-criteria-check-item-content-invalidity-tag invalidity-tag-${
                                    QUALIVISA_ANOMALY_LEVEL[
                                      conformityQuestion.anomalyLevelNew
                                    ]?.variant ?? "default"
                                  }`}
                                >
                                  {
                                    QUALIVISA_ANOMALY_LEVEL[
                                      conformityQuestion.anomalyLevelNew
                                    ]?.label
                                  }
                                </div>
                              </>
                            )}
                            {showExisting &&
                              conformityQuestion.showExisting && (
                                <>
                                  <span className="qualivisa-criteria-check-item-content-invalidity-label-type">
                                    {showNew &&
                                      t<string>(
                                        t<string>(
                                          "pro.qualivisa.form.criteria.existing",
                                        ),
                                      )}
                                  </span>
                                  <div
                                    className={`qualivisa-criteria-check-item-content-invalidity-tag invalidity-tag-${
                                      QUALIVISA_ANOMALY_LEVEL[
                                        conformityQuestion.anomalyLevelExisting
                                      ]?.variant ?? "default"
                                    }`}
                                  >
                                    {
                                      QUALIVISA_ANOMALY_LEVEL[
                                        conformityQuestion.anomalyLevelExisting
                                      ]?.label
                                    }
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="qualivisa-criteria-check-item-options">
                          <div
                            onClick={() =>
                              toggleAnswerValue(conformityQuestion.key, "ok")
                            }
                            className={`qualivisa-criteria-check-item-option option-valid ${
                              answer?.includes("ok") ? "option-selected" : ""
                            }`}
                          >
                            {t<string>("pro.qualivisa.form.criteria.valid")}
                          </div>
                          {showNew && (
                            <div
                              onClick={() =>
                                toggleAnswerValue(conformityQuestion.key, "new")
                              }
                              className={`qualivisa-criteria-check-item-option option-invalid ${
                                answer?.includes("new") ? "option-selected" : ""
                              }`}
                            >
                              {t<string>("pro.qualivisa.form.criteria.new")}
                              <br />
                              {t<string>("pro.qualivisa.form.criteria.invalid")}
                            </div>
                          )}
                          {showExisting && (
                            <div
                              onClick={() =>
                                toggleAnswerValue(
                                  conformityQuestion.key,
                                  "existing",
                                )
                              }
                              className={`qualivisa-criteria-check-item-option option-invalid ${
                                answer?.includes("existing")
                                  ? "option-selected"
                                  : ""
                              }`}
                            >
                              {t<string>(
                                "pro.qualivisa.form.criteria.existing",
                              )}
                              <br />
                              {t<string>("pro.qualivisa.form.criteria.invalid")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          );
        })}
      </div>
    </div>
  );
};
