import { FC } from "react";
import { useTranslation } from "react-i18next";
import { QualiVisaRecapResponseItemDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";
import parse from "html-react-parser";

interface QualivisaRecapPieceScreenProps {
  recap: QualiVisaRecapResponseItemDto;
}

export const QualivisaRecapPiece: FC<QualivisaRecapPieceScreenProps> = ({
  recap,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {recap.qualivisaFormPieceContentResponseItems?.map((r) => (
        <div className="qualivisa-recap-body-item" key={r.pieceName}>
          <h3 className="qualivisa-recap-body-item-label">
            {"Pièce : ".concat(r.pieceName)}
          </h3>
          <p className="qualivisa-recap-body-item-content">
            <div className="mb-3">
              <div className="font-weight-bold ">
                {t<string>("Equipements présents dans la pièce :")}
              </div>
              <ul className="mb-3">
                {r.equipmentsInPiece.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
              {r?.items &&
                r.items.map((s) => (
                  <div key={recap.id} className="mb-3">
                    <div className="font-weight-bold">
                      {parse(t<string>(s.questionLabel))}
                    </div>
                    <ul>
                      <li>{parse(t<string>(s.responseLabel))}</li>
                    </ul>
                  </div>
                ))}
            </div>
          </p>
        </div>
      ))}
    </>
  );
};
