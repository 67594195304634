import { createRef, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QualiVisaRecapResponseDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";
import ReactSignatureCanvas from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import { Col, Form, FormInstance, Row, Switch } from "antd";
import BasicButton from "@components/buttons/BasicButton";
import InputFormField from "@components/inputs/InputFormField";
import { formItemBlockLayout } from "@utils/Constant";

interface QualivisaSignatureBlockProps {
  qualivisa: QualiVisaRecapResponseDto;
  formulaireType: string;
  setFormulaireType: (item: string) => void;
  setIsSign: (item: boolean) => void;
  form: FormInstance;
  onUpdateSignatures: (
    managerSignature: string,
    customerSignature: string,
  ) => void;
}

interface FormValues {
  managerName?: string;
  customerRepresentativeName?: string;
  intallerName?: string;
}

export const QualivisaSignatureBlock: FC<QualivisaSignatureBlockProps> = ({
  qualivisa,
  formulaireType,
  setFormulaireType,
  setIsSign,
  form,
  onUpdateSignatures,
}) => {
  const { t } = useTranslation();
  const sigCanvas = createRef<ReactSignatureCanvas>();
  const sigManagerCanvas = createRef<ReactSignatureCanvas>();

  const updateSignatures = useCallback(() => {
    const managerSignature =
      sigManagerCanvas.current
        ?.getSignaturePad()
        .toDataURL("image/png")
        .split("base64,")[1] || "";

    const customerSignature =
      sigCanvas.current
        ?.getSignaturePad()
        .toDataURL("image/png")
        .split("base64,")[1] || "";

    if (onUpdateSignatures) {
      onUpdateSignatures(managerSignature, customerSignature);
    }
  }, [onUpdateSignatures, sigCanvas, sigManagerCanvas]);

  const [isEmpty, setIsEmpty] = useState<boolean>(
    qualivisa?.customerSignature != null,
  );
  const [isManagerSignatureEmpty, setIsManagerSignatureEmpty] =
    useState<boolean>(qualivisa?.managerSignature != null);

  const clearSignature = () => {
    sigCanvas.current?.clear();
    setIsEmpty(true);
  };

  const clearManagerSignature = () => {
    sigManagerCanvas.current?.clear();
    setIsManagerSignatureEmpty(true);
  };

  useEffect(() => {
    if (qualivisa?.customerSignature) {
      sigCanvas.current?.fromDataURL(
        `data:image/png;base64,${qualivisa?.customerSignature}`,
      );
      setIsEmpty(false);
    }
    if (qualivisa?.managerSignature) {
      sigManagerCanvas.current?.fromDataURL(
        `data:image/png;base64,${qualivisa?.managerSignature}`,
      );
      setIsManagerSignatureEmpty(false);
    }
  }, [qualivisa?.managerSignature, qualivisa?.customerSignature]);

  useEffect(() => {
    if (formulaireType === "FVQ") {
      setIsSign(!isManagerSignatureEmpty);
    } else setIsSign(!isManagerSignatureEmpty && !isEmpty);
  }, [formulaireType, isEmpty, isManagerSignatureEmpty, setIsSign]);

  const onChangeFormulaireType = (checked: boolean) => {
    checked ? setFormulaireType("FVQ") : setFormulaireType("PV");
  };

  const initialValue = (): FormValues => {
    return {
      managerName: qualivisa.managerName,
      customerRepresentativeName: qualivisa.customerRepresentativeName,
      intallerName: qualivisa.installerName,
    };
  };

  return (
    <Form form={form} {...formItemBlockLayout} initialValues={initialValue()}>
      <Switch
        defaultChecked
        onChange={onChangeFormulaireType}
        checked={formulaireType === "FVQ"}
        checkedChildren={"Fiche Visa Qualité"}
        unCheckedChildren={"PV de visite"}
        className="mb-4"
      />

      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="signature-container">
          <div
            className={`signature-placeholder ${
              isManagerSignatureEmpty ? "signature-empty" : ""
            }`}
          >
            {t<string>("pro.qualivisa.recap.signature")}
          </div>
          <InputFormField
            module="pro.qualivisa.recap"
            field={`${
              formulaireType === "FVQ" ? "managerName" : "installerName"
            }`}
            disabled={formulaireType === "FVQ"}
            className="flex-fill "
            showLabel
            required
          />
          <SignatureCanvas
            canvasProps={{ className: "sigCanvas" }}
            ref={sigManagerCanvas}
            onEnd={() => {
              setIsManagerSignatureEmpty(false);
              updateSignatures();
            }}
          />
          <Row className="mt-8">
            <Col xs={24}>
              <BasicButton
                className="w-100"
                text={t<string>("buttons.wipe")}
                variant="tertiary"
                disabled={isManagerSignatureEmpty}
                onClick={() => clearManagerSignature()}
              />
            </Col>
          </Row>
        </div>
        {formulaireType === "PV" && (
          <div className="signature-container">
            <div
              className={`signature-placeholder ${
                isEmpty ? "signature-empty" : ""
              }`}
            >
              {t<string>("pro.qualivisa.recap.signature")}
            </div>
            <InputFormField
              module="pro.qualivisa.recap"
              field="customerRepresentativeName"
              className="flex-fill "
              showLabel
              required
            />
            <SignatureCanvas
              canvasProps={{ className: "sigCanvas" }}
              ref={sigCanvas}
              onEnd={() => {
                setIsEmpty(false);
                updateSignatures();
              }}
            />
            <Row className="mt-8">
              <Col xs={24}>
                <BasicButton
                  className="w-100"
                  text={t<string>("buttons.wipe")}
                  variant="tertiary"
                  disabled={isEmpty}
                  onClick={() => clearSignature()}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Form>
  );
};
