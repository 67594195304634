import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { ROUTES } from "@utils/Routes";
import configuration from "@utils/Config";
import {
  GazProfessionalUser,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import NewFunctionalityCard from "@components/cards/NewFunctionalityCard";

interface SideMenuGazProfessionalProps {
  className?: string;
  user: GazProfessionalUser | OrganisationProfessionalUser;
}

const SideMenuGazProfessional: FunctionComponent<
  SideMenuGazProfessionalProps
> = ({ className = "", user }) => {
  const { t } = useTranslation();
  const [proSelectedKey, setProSelectedKey] = useState<string>("");
  const [isMobileProSideMenuOpen, setIsMobileProSideMenuOpen] =
    useState<boolean>(false);
  const isGazEvent = configuration.isGazEventPro;
  const gazEventType = configuration.gazEventType;

  const menu = [
    {
      key: "dashboard",
      link: ROUTES.professional.connectedHome.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-dashboard.svg"
          alt={t<string>("menu.alt.dashboard")}
        />
      ),
      text: t<string>("menu.dashboard"),
      disabledForStatus: [],
    },
    {
      key: "contacts",
      link: ROUTES.professional.contacts.list.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-envelope.svg"
          alt={t<string>("menu.alt.contacts")}
        />
      ),
      text: t<string>("menu.contacts"),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "mails",
      link: ROUTES.professional.mailOP.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-envelope.svg"
          alt={t<string>("menu.alt.mails")}
        />
      ),
      text: t<string>("menu.mails"),
      disabledForStatus: [],
    },
    {
      key: "qualifications",
      link: ROUTES.professional.qualifications.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-qualifications.svg"
          alt={t<string>("menu.alt.qualifications")}
        />
      ),
      text: t<string>("menu.qualifications"),
      disabledForStatus: [],
    },
    {
      key: "audit",
      link: ROUTES.professional.audits.list.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-audit.svg"
          alt={t<string>("menu.alt.audit")}
        />
      ),
      text: t<string>("menu.audit"),
      disabledForStatus: [],
    },
    {
      key: "qualivisa",
      link: ROUTES.professional.qualivisa.list.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-file.svg"
          alt={t<string>("menu.alt.qualivisa")}
        />
      ),
      text: t<string>("menu.qualivisa"),
      disabledForStatus: [],
    },
    {
      key: "pacHybride",
      link: ROUTES.professional.pacsHybrides.mention.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-file.svg"
          alt={t<string>("menu.alt.pacHybride")}
        />
      ),
      text: t<string>("menu.pacHybride"),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "smokeDuct",
      link: ROUTES.professional.smokeDucts.mention.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-file.svg"
          alt={t<string>("menu.alt.smokeDuct")}
        />
      ),
      text: t<string>("menu.smokeDuct"),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "greenGas",
      link: ROUTES.professional.greenGas.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-file.svg"
          alt={t<string>("menu.alt.greenGaz")}
        />
      ),
      text: t<string>("menu.greenGas"),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "gazMonth",
      link: ROUTES.professional.gazMonth.details.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-gaz-month.svg"
          alt={t<string>(`menu.alt.${gazEventType}`)}
        />
      ),
      text: t<string>(`menu.${gazEventType}`),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "maintenanceCertificate",
      link: ROUTES.professional.maintenanceCertificate.list.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-file.svg"
          alt={t<string>("menu.alt.maintenanceCertificate")}
        />
      ),
      text: t<string>("menu.maintenanceCertificate"),
      disabledForStatus: ["REMOVED"],
    },
    {
      key: "personalData",
      link: ROUTES.professional.personalData.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-edit.svg"
          alt={t<string>("menu.alt.personalData")}
        />
      ),
      text: t<string>("menu.personalData"),
      disabledForStatus: [],
    },
    {
      key: "tools",
      link: ROUTES.professional.tools.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-tools.svg"
          alt={t<string>("menu.alt.tools")}
        />
      ),
      text: t<string>("menu.tools"),
      disabledForStatus: [],
    },
  ];

  useEffect(() => {
    const currentItem = menu.find(
      (item) =>
        window.location.pathname.split("/")[3] === item.link.split("/")[3],
    );
    if (currentItem) setProSelectedKey(currentItem.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, menu]);

  return (
    <>
      <div
        className={`app-sidemenu-gazprofessional-overlay ${
          isMobileProSideMenuOpen ? "d-block" : "d-none"
        }`}
        onClick={() => setIsMobileProSideMenuOpen(false)}
      />
      <BasicButton
        variant="pro-side-menu"
        className={isMobileProSideMenuOpen ? "pro-side-menu-open" : ""}
        icon={isMobileProSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        onClick={() => setIsMobileProSideMenuOpen(!isMobileProSideMenuOpen)}
      />
      <div
        className={`app-sidemenu-gazprofessional d-flex flex-column ${
          isMobileProSideMenuOpen ? "mobile-sidemenu-open" : ""
        } ${className}`}
      >
        <div className="flex-grow-1">
          <>
            {menu
              .filter((item) =>
                !isGazEvent
                  ? item.text !== t<string>(`menu.${gazEventType}`)
                  : item,
              )
              .filter(
                (item) => item.disabledForStatus.indexOf(user.status) <= -1,
              )
              .map((item) => (
                <Link
                  key={item.key}
                  to={item.link}
                  className={`link d-flex align-items-center ${
                    proSelectedKey === item.key ? "selected" : ""
                  }`}
                >
                  <div className="icon">{item.icon}</div>
                  <div className="text">{item.text}</div>
                </Link>
              ))}
            <NewFunctionalityCard />
          </>
        </div>
      </div>
    </>
  );
};

export default SideMenuGazProfessional;
