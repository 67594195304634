import { createStore } from "effector";
import { QualivisaReponseDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { setQualivisaStore } from "@state/qualivisa/QualivisaEvents";

export const qualivisaStore = createStore<QualivisaReponseDto>({
  qualivisaItems: [],
  furtherInformation: {
    equipments: [],
    moreInformation: [],
  },
  step: "1",
});

qualivisaStore.on<QualivisaReponseDto>(
  setQualivisaStore,
  (state, payload: QualivisaReponseDto) => payload,
);
