import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { requestUpdateQualivisaInformation } from "@state/qualivisa/QualivisaEffects";
import { Form } from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import {
  QualivisaDetail,
  QualivisaInformation,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { Store } from "rc-field-form/lib/interface";
import SubmitButton from "@components/buttons/SubmitButton";
import { QualivisaFormSteps } from "@utils/enums/qualivisa.enum";

interface QualivisaDetailProps {
  qualivisaDetail: QualivisaInformation;
  setCurrentStep: (value: string) => void;
}

const initialValues = (qualivisaDetail: QualivisaDetail): QualivisaDetail => {
  return {
    customerLastname: qualivisaDetail.customerLastname,
    customerFirstname: qualivisaDetail.customerFirstname,
    customerAddress: qualivisaDetail.customerAddress,
    comment: qualivisaDetail.comment,
    managerName: qualivisaDetail.managerName,
  };
};

export const QualivisaDetailScreen: FC<QualivisaDetailProps> = ({
  qualivisaDetail,
  setCurrentStep,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();

  const formulaireId = location.pathname.split("/").pop() ?? "";

  const handleSubmit = (values: Store) => {
    void requestUpdateQualivisaInformation({
      dto: {
        customerLastname: values.customerLastname as string,
        customerFirstname: values.customerFirstname as string,
        customerAddress: values.customerAddress as string,
        comment: values.comment as string,
        managerName: values.managerName as string,
      },
      id: formulaireId,
    });
  };

  useEffect(() => {
    return requestUpdateQualivisaInformation.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setCurrentStep(QualivisaFormSteps.TYPOLOGIE_CHANTIER);
      }
    });
  });

  return (
    <Form
      form={form}
      {...formItemBlockLayout}
      initialValues={initialValues(qualivisaDetail)}
      onFinish={handleSubmit}
    >
      <>
        <div className="d-flex flex-column flex-sm-row justify-content-between mt-20 gap-sm-5">
          <InputFormField
            module="pro.qualivisa.form.information"
            field="customerFirstname"
            className="flex-fill "
            required
            showLabel
          />
          <InputFormField
            module="pro.qualivisa.form.information"
            field="customerLastname"
            className="flex-fill"
            required
            showLabel
          />
        </div>
        <AddressFields
          module="pro.qualivisa.form.information"
          field="customerAddress"
          showLabel
          emptyErrorMessage={t<string>(
            "search.contacts.form.fields.address.errors.required",
          )}
        />
        <TextAreaFormField
          module="pro.qualivisa.form.information"
          field="comment"
          showLabel
        />
        <InputFormField
          module="pro.qualivisa.form.information"
          field="managerName"
          className="flex-fill"
          required
          showLabel
        />
      </>

      <SubmitButton module="pro.qualivisa.form.information" label="submit" />
    </Form>
  );
};
