import { FC } from "react";
import { useTranslation } from "react-i18next";
import { QualiVisaRecapResponseItemDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";

interface QualivisaRecapEquipmentScreenProps {
  recap: QualiVisaRecapResponseItemDto;
}

export const QualivisaRecapEquipment: FC<
  QualivisaRecapEquipmentScreenProps
> = ({ recap }) => {
  const { t } = useTranslation();

  return (
    <div className="qualivisa-recap-body-item">
      <h3 className="qualivisa-recap-body-item-label">{recap.label}</h3>
      <p className="qualivisa-recap-body-equipment-item-content">
        {recap?.qualivisaFormulaireEquipmentItems &&
          recap.qualivisaFormulaireEquipmentItems.map((s) => (
            <div key={recap.id} className="mb-3 equipment">
              <>
                <span className="equipment-title">
                  {t<string>(s.equipment).concat(" :")}
                </span>
                {s.equipmentDetails.map((equipment) => (
                  <div key={equipment.id}>
                    <div className="font-weight-bold">
                      {t<string>(equipment.questionLabel)}
                    </div>
                    <ul>
                      <li>{t<string>(equipment.responseLabel)}</li>
                    </ul>
                  </div>
                ))}
              </>
            </div>
          ))}
      </p>
    </div>
  );
};
