import { FunctionComponent } from "react";
import {
  QualiVisaItemResponseDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { QualivisaQuestionResponseItem } from "@views/pro/gazProfessional/qualivisa/QualivisaQuestionResponseItem";
import Accordions, {
  AccordionSection,
} from "@components/accordions/Accordions";

interface QualivisaEquipmentQuestionProps {
  currentResponses: QualiVisaItemResponseDto[];
  handleSelectResponse: (item: ResponseDto, value?: number) => void;
  selectedResponses: ResponseDto[];
}

const QualivisaEquipmentQuestion: FunctionComponent<
  QualivisaEquipmentQuestionProps
> = ({ currentResponses, handleSelectResponse, selectedResponses }) => {
  const equipment = currentResponses.filter(
    (s) => s.question.type === "equipment",
  );

  const equipmentQuestions = currentResponses?.filter(
    (q) => q.question.type === "equipment",
  );
  const otherQuestion = currentResponses.filter((q) => !equipment.includes(q));

  const equipmentSelected = selectedResponses?.filter(
    (e) => e?.type === "EQUIPMENT",
  );

  const getSection = () => {
    return equipmentSelected.map((e) => {
      return {
        title: (
          <div className="d-flex align-items-center gap-2">
            <div>{e.label}</div>
          </div>
        ),
        content: (
          <>
            {equipmentQuestions
              ?.filter(
                (ques) =>
                  ques.question.fqKey.slice(0, 9) === e.fqKey.slice(0, 9),
              )
              .sort(
                (a, b) =>
                  parseInt(a.question.fqKey.slice(17, 19)) -
                  parseInt(b.question.fqKey.slice(17, 19)),
              )
              .map((s) => (
                <QualivisaQuestionResponseItem
                  key={s.question.fqKey}
                  handleSelectResponse={handleSelectResponse}
                  qualivisaItem={s}
                  selectedResponses={selectedResponses}
                  questionType="equipment"
                />
              ))}
          </>
        ),
      } as AccordionSection;
    });
  };

  const section = getSection();

  return (
    <>
      {otherQuestion?.map((s) => (
        <QualivisaQuestionResponseItem
          key={s.question.fqKey}
          handleSelectResponse={handleSelectResponse}
          qualivisaItem={s}
          selectedResponses={selectedResponses}
        />
      ))}
      <Accordions className="mb-3" sections={section} />
    </>
  );
};
export default QualivisaEquipmentQuestion;
