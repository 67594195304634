import { FC } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { ResponseDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";

interface QualivisaResponseItemProps {
  response: ResponseDto;
  selectedResponses: ResponseDto[];
  onClick: (response: ResponseDto) => void;
  className?: string;
}

export const QualivisaResponseItem: FC<QualivisaResponseItemProps> = ({
  response,
  selectedResponses,
  onClick,
  className = "",
}) => {
  const { t } = useTranslation();
  const selectedResponsesKeys = selectedResponses
    .map((response) => response?.fqKey)
    .filter((r) => r != null);

  return (
    <div
      onClick={() => onClick(response)}
      className={`qualivisa-form-item-response ${
        !!selectedResponsesKeys.find((key) => key.startsWith(response?.fqKey))
          ? "response-selected"
          : ""
      } ${response.image ? "response-with-image" : ""} ${className}`}
    >
      {response.image && (
        <img
          alt={t<string>("pro.qualivisa.form.item.image")}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(response.image)}`}
        />
      )}
      <h5>{parse(t<string>(response.label))}</h5>
      {response.extraLabel && <p>{parse(t<string>(response.extraLabel))}</p>}
    </div>
  );
};
