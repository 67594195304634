import { FunctionComponent, useEffect } from "react";
import {
  Equipment,
  QualiVisaItemResponseDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { qualivisaUtils } from "@utils/qualivisa-utils";
import { QualivisaQuestionResponseItem } from "@views/pro/gazProfessional/qualivisa/QualivisaQuestionResponseItem";
import { QualivisaEquipmentPiece } from "@views/pro/gazProfessional/qualivisa/QualivisaEquipmentInPiece";
import { useStore } from "effector-react";
import { qualivisaStore } from "@state/qualivisa/QualivisaStore";
import { qualivisaMapper } from "@utils/qualivisa-mapper";

interface QualivisaPieceQuestionProps {
  currentResponses: QualiVisaItemResponseDto[];
  handleSelectResponse: (item: ResponseDto, value?: number) => void;
  selectedResponses: ResponseDto[];
  equipmentInPiece: Equipment[];
  setEquipmentInPiece: (value: Equipment[]) => void;
  setPieceName: (value: string) => void;
  setSelectedResponses: (value: ResponseDto[]) => void;
  pieceName?: string;
  pieceNumber: number;
  setCase: (value: string) => void;
}

const QualivisaPieceQuestion: FunctionComponent<
  QualivisaPieceQuestionProps
> = ({
  currentResponses,
  handleSelectResponse,
  selectedResponses,
  equipmentInPiece,
  setEquipmentInPiece,
  setPieceName,
  pieceName,
  pieceNumber,
  setSelectedResponses,
  setCase,
}) => {
  const qualivisaInfo = useStore(qualivisaStore);

  const getNextResponsesAfterRoom = () => {
    //step 3

    const types = qualivisaUtils.getTypeOfEquipment(equipmentInPiece);
    console.log(equipmentInPiece);

    const equipmentMapped = equipmentInPiece.map(
      (e) =>
        (e = {
          equipment: qualivisaMapper.equipmentMapper.filter(
            (s) => s.value === e.equipment?.slice(0, 9),
          )[0]?.shortLabel,
          evacuationType: qualivisaMapper.evacuationTypeMapper.filter(
            (s) => s.value === e.evacuationType?.slice(0, 9),
          )[0]?.label,
          type: qualivisaMapper.typeMapper.filter(
            (s) => s.value === e.type?.slice(0, 9),
          )[0]?.label,
        }),
    );

    const getNextQuestionKey = (type: string[], length: number) => {
      if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Chauffe-eau non raccordé" && e.type === "Type A",
        ).length > 0 &&
        length === 1
      ) {
        setCase("CENR_A");
        return "fq_q_3.04";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Chauffe-eau non raccordé" && e.type === "Type A",
        ).length > 0 &&
        type.includes("Type C")
      ) {
        setCase("CENR_A+C");
        return "fq_q_3.04";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type A",
        ).length > 0 &&
        length === 1
      ) {
        setCase("OCA_A");
        return "fq_q_3.07";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type A",
        ).length > 0 &&
        type.includes("Type C")
      ) {
        setCase("OCA_A+C");
        return "fq_q_3.07";
      } else if (
        equipmentMapped.filter(
          (e) => e.equipment === "Appareil de cuisson" && e.type === "Type A",
        ).length > 0 &&
        length === 1
      ) {
        setCase("AdC_A");
        return "fq_q_3.07";
      } else if (
        equipmentMapped.filter(
          (e) => e.equipment === "Appareil de cuisson" && e.type === "Type A",
        ).length > 0 &&
        type.includes("Type C")
      ) {
        setCase("AdC_A+C");
        return "fq_q_3.07";
      } else if (
        (equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
          length === 1) ||
        (equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
          length === 1)
      ) {
        setCase("B_TN");
        return "fq_q_3.11";
      } else if (
        (equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Pression",
        ).length > 0 &&
          length === 1) ||
        (equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Pression",
        ).length > 0 &&
          length === 1)
      ) {
        setCase("B_PRESSION");
        return "fq_q_3.11";
      } else if (
        equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) =>
            e.type === "Type A" && e.equipment === "Chauffe-eau non raccordé",
        ).length > 0
      ) {
        setCase("CENR_A+B_TN");
        return "fq_q_3.14";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) =>
            e.type === "Type A" && e.equipment === "Chauffe-eau non raccordé",
        ).length > 0
      ) {
        setCase("OCA_B_TN+CENR_A");
        return "fq_q_3.14";
      } else if (
        equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("AdC_A+B_TN");
        return "fq_q_3.24";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Tirage Naturel",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("OCA_B_TN+AdC_A");
        return "fq_q_3.24";
      } else if (
        equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Pression",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) =>
            e.type === "Type A" && e.equipment === "Chauffe-eau non raccordé",
        ).length > 0
      ) {
        setCase("CENR_A+B_PRESSION");
        return "fq_q_3.36";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Pression",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) =>
            e.type === "Type A" && e.equipment === "Chauffe-eau non raccordé",
        ).length > 0
      ) {
        setCase("OCA_B_PRESSION+CENR_A");
        return "fq_q_3.36";
      } else if (
        equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "Pression",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("AdC_A+B_PRESSION");
        return "fq_q_3.41";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "Pression",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("OCA_B_PRESSION+AdC_A");
        return "fq_q_3.41";
      } else if (
        (equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "VMC gaz",
        ).length > 0 &&
          length === 1) ||
        (equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "VMC gaz",
        ).length > 0 &&
          length === 1)
      ) {
        setCase("B_VMC");
        return "fq_q_3.48";
      } else if (
        equipmentMapped.filter(
          (e) => e.type === "Type B" && e.evacuationType === "VMC gaz",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("AdC_A+B_VMC");
        return "fq_q_3.51";
      } else if (
        equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type B" &&
            e.evacuationType === "VMC gaz",
        ).length > 0 &&
        equipmentMapped.filter(
          (e) => e.type === "Type A" && e.equipment === "Appareil de cuisson",
        ).length > 0
      ) {
        setCase("OCA_B_VMC+AdC_A");
        return "fq_q_3.51";
      } else if (
        (type.includes("Type C") && length === 1) ||
        (equipmentMapped.filter(
          (e) =>
            e.equipment === "Organe de coupure d’appareil" &&
            e.type === "Type C",
        ).length > 0 &&
          length === 1)
      ) {
        setCase("C");
        return null;
      }

      return null;
    };

    const questionKey = getNextQuestionKey(types, equipmentInPiece.length);

    if (questionKey) {
      const nextQuestion = qualivisaInfo?.qualivisaItems.find(
        (question) => question.question.fqKey === questionKey,
      );

      if (nextQuestion) {
        return (
          <QualivisaQuestionResponseItem
            key={nextQuestion.fqKey}
            handleSelectResponse={handleSelectResponse}
            qualivisaItem={nextQuestion}
            selectedResponses={selectedResponses}
          />
        );
      }
    }

    return null;
  };

  useEffect(() => {
    if (equipmentInPiece) {
      getNextResponsesAfterRoom();
    }
  }, [equipmentInPiece, getNextResponsesAfterRoom]);

  const piece = currentResponses.filter((s) => s.question.type === "room");
  const speQuestion = currentResponses.filter(
    (q) => q.question.fqKey === "fq_q_3.02",
  );
  const otherQuestion = currentResponses
    .filter((q) => !piece.includes(q))
    .filter((q) => !speQuestion.includes(q));

  return (
    <>
      {speQuestion?.map((item) => (
        <QualivisaQuestionResponseItem
          key={item.question.fqKey}
          handleSelectResponse={handleSelectResponse}
          qualivisaItem={item}
          selectedResponses={selectedResponses}
        />
      ))}
      {piece.length > 0 && (
        <QualivisaEquipmentPiece
          pieceNumber={pieceNumber}
          equipmentInPiece={equipmentInPiece}
          allEquipments={qualivisaInfo.furtherInformation?.equipments}
          setEquipmentInPiece={setEquipmentInPiece}
          setPieceName={setPieceName}
          pieceName={pieceName}
          selectedResponses={selectedResponses}
          setSelectedResponses={setSelectedResponses}
        />
      )}
      {equipmentInPiece.length > 0 && <>{getNextResponsesAfterRoom()}</>}
      {otherQuestion?.map((item) => (
        <QualivisaQuestionResponseItem
          key={item.question.fqKey}
          handleSelectResponse={handleSelectResponse}
          qualivisaItem={item}
          selectedResponses={selectedResponses}
        />
      ))}
    </>
  );
};
export default QualivisaPieceQuestion;
