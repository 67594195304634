import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import BasicButton from "@components/buttons/BasicButton";
import {
  requestCreateQualivisa,
  requestGetQualivisaFormExport,
  requestGetQualivisas,
} from "@state/qualivisa/QualivisaEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";
import parse from "html-react-parser";
import { FileOutlined } from "@ant-design/icons";
import { QualivisaFile } from "@state/qualivisa/dto/quali.visa.item.response.dto";
import FileSaver from "file-saver";
import { Spin } from "antd";
import SearchFormField from "@components/inputs/SearchFormField";

export const QualivisaListScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qualivisaFiles, setQualivisasFiles] = useState<QualivisaFile[]>([]);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setQuery(value);
  };

  const fetchQualivisaFiles = useCallback(() => {
    setDataHasLoaded(false);
    requestGetQualivisas({ dto: { query: query } })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t, query]);

  useEffect(() => {
    fetchQualivisaFiles();
  }, [fetchQualivisaFiles]);

  useEffect(() => {
    return requestGetQualivisas.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setQualivisasFiles(result.data);
      }
    });
  });

  const handleCreationQualivisa = () => {
    void requestCreateQualivisa({ dto: null });
  };

  useEffect(() => {
    return requestCreateQualivisa.done.watch(({ result }) => {
      if (result.ok && result.data) {
        navigate(
          ROUTES.professional.qualivisa.details.generate(
            result.data.formulaireId,
          ),
        );
      }
    });
  });

  const handleGeneratePdf = (formulaireId: string) => {
    void requestGetQualivisaFormExport(formulaireId);
  };

  useEffect(() => {
    return requestGetQualivisaFormExport.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const data = new Blob([result.data], {
          type: "application/octet-stream",
        });
        FileSaver.saveAs(data, result.fileName);
        toastSuccess(t<string>("pro.qualivisa.messages.success.generate"));
      } else {
        if (result.errorMessage) {
          toastError(result.errorMessage);
        } else {
          toastError(t<string>("pro.qualivisa.messages.errors.generate"));
        }
      }
    });
  });

  return (
    <PageLayout
      container
      title={t<string>("pro.qualivisa.list.title")}
      titleCentered={false}
      subtitle={
        <span>{parse(t<string>("pro.qualivisa.list.description"))}</span>
      }
      bodyClassName="app-qualivisa-list"
    >
      <>
        <BasicButton
          variant="primary"
          text={t<string>("pro.qualivisa.list.button.new")}
          onClick={handleCreationQualivisa}
          className="mention-button"
        />
        <div className="qualivisa-list-table">
          <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
            <h2 className="qualivisa-list-table-title">
              {t<string>("pro.qualivisa.list.table.title")}
            </h2>
            <SearchFormField
              module="pro.mail"
              field="search"
              onChange={onChange}
              onSearch={(value: string) => setQuery(value)}
              value={query}
            />
          </div>
          <div className="qualivisa-list-table-items mt-3">
            {dataHasLoaded ? (
              qualivisaFiles.length > 0 ? (
                qualivisaFiles.map((file) => (
                  <div
                    key={file.id}
                    className="qualivisa-list-table-item"
                    onClick={() =>
                      file.formulaireStatus === "FINISHED"
                        ? navigate(
                            ROUTES.professional.qualivisa.recap.generate(
                              file.id,
                            ),
                          )
                        : navigate(
                            ROUTES.professional.qualivisa.details.generate(
                              file.id,
                            ),
                          )
                    }
                  >
                    <div className="qualivisa-list-table-label">
                      <div className="qualivisa-list-table-label-icon">
                        <FileOutlined />
                      </div>
                      <span>
                        {file?.customerName
                          ? file.customerName.concat(" ", file?.createdAt ?? "")
                          : file.id.concat(" ").concat(file?.createdAt ?? "")}
                      </span>
                    </div>
                    {file.formulaireStatus === "FINISHED" && (
                      <div className="qualivisa-list-table-buttons">
                        <BasicButton
                          variant="primary-outline"
                          text={t<string>("pro.qualivisa.list.button.download")}
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGeneratePdf(file.id);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-center text-grey my-3">
                  {t<string>("pro.qualivisa.list.table.noData")}
                </div>
              )
            ) : (
              <div className="ant-spin-page my-3">
                <Spin />
              </div>
            )}
          </div>
        </div>
      </>
    </PageLayout>
  );
};
