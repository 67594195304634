import React, { useCallback, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicCard from "@components/cards/BasicCard";
import {
  IconCanceled,
  IconWaiting,
  IconWarning,
  InfoBulle,
} from "../../../../static/icons";
import { Empty, Table, Tooltip } from "antd";
import { columns } from "@components/lists/PreviousAuditsColumns";
import { SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";
import { Pageable } from "@type/pagination/pagination.types";
import {
  AUDIT_GLOBAL_STATUS,
  AUDIT_PROGRAMMING_STATUS,
  AUDIT_RESULTS,
} from "@utils/enums/audit.enum";
import { emptySearchResult } from "@utils/Constant";
import BasicButton from "@components/buttons/BasicButton";
import BasicTag from "@components/tags/BasicTag";
import { useViewport } from "@utils/breakpoints-helper";
import {
  AuditGlobalStatus,
  AuditInProgress,
  ProgrammingAuditItem,
} from "@state/audit/dto/audit.item.details.dto";
import {
  AuditListInstallationToChooseDto,
  PreviousAuditItemResponseDto,
} from "@state/audit/dto/previous.audit.item.dto";
import {
  requestGetAuditsInProgress,
  requestGetAuditStatus,
  requestGetPreviousAudit,
} from "@state/audit/AuditEffects";
import { setAuditToProgram } from "@state/audit/AuditEvents";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import TutorialModal from "@components/modals/TutorialModal";
import parse from "html-react-parser";
import AuditBlockDetailModal from "@components/modals/AuditBlockDetailModal";
import AuditDetailModal from "@components/modals/AuditDetailModal";
import DateFormat from "@utils/DateFormat";
import AuditWarningModal from "@components/modals/AuditWarningModal";

export const AuditsListScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useViewport();

  const [audits, setAudits] = useState<AuditInProgress[]>([]);
  const [hasAuditBlocked, setHasAuditBlocked] = useState<boolean>(false);
  const [nbAuditsCanceled, setNbAuditsCanceled] = useState<number>(0);
  const [previousAudits, setPreviousAudits] =
    useState<Pageable<PreviousAuditItemResponseDto>>(emptySearchResult);
  const [isTutorialModalVisible, setIsTutorialModalVisible] =
    useState<boolean>(false);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "date",
    order: "descend",
  });
  const [auditInstallationToChoose, setAuditInstallationToChoose] = useState<
    AuditListInstallationToChooseDto[]
  >([]);
  const [detailAuditBlockModalVisible, setDetailAuditBlockModalVisible] =
    useState<boolean>(false);
  const [detailAuditModalVisible, setDetailAuditModalVisible] =
    useState<boolean>(false);
  const [programmingAuditItems, setProgrammingAuditItems] = useState<
    ProgrammingAuditItem[]
  >([]);
  const [auditGlobalStatus, setAuditGlobalStatus] =
    useState<AuditGlobalStatus>();

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };
  const isControl = auditGlobalStatus && auditGlobalStatus?.pgStatus === "CR";
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const fetchPreviousAudits = useCallback(() => {
    setDataHasLoaded(false);
    requestGetPreviousAudit({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {},
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, sorter]);

  const fetchAuditInProgress = useCallback(() => {
    setDataHasLoaded(false);
    requestGetAuditsInProgress({
      dto: {},
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  useEffect(() => {
    fetchAuditInProgress();
    void requestGetAuditStatus({});
  }, [fetchAuditInProgress]);

  useEffect(() => {
    fetchPreviousAudits();
  }, [fetchPreviousAudits]);

  useEffect(() => {
    return requestGetPreviousAudit.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPreviousAudits(result.data);
      }
    });
  });

  useEffect(() => {
    return requestGetAuditsInProgress.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAudits(result.data);
        result.data.forEach((r) => {
          if (r.isProgrammable) {
            const equi = r.isProgrammable;
            setAuditInstallationToChoose((audit) => [
              {
                id: equi.length > 0 ? equi.filter((e) => e != null)[0] : "",
                ocCcId: r.programmingAuditItems[0].ocCcId ?? "",
                equivalence: r.isProgrammable ?? [],
                rang: r.programmingAuditItems.filter(
                  (status) => status.auditStatus === "ANNULE",
                ).length,
                lastStatus:
                  r.programmingAuditItems[r.programmingAuditItems.length - 1]
                    .auditStatus,
                timerCancelAudit:
                  r.programmingAuditItems[r.programmingAuditItems.length - 1]
                    .timeBeforeAuditCancelled ?? "",
                timerChoixCC:
                  r.programmingAuditItems[r.programmingAuditItems.length - 1]
                    .timeBeforeChoiceCC ?? "",
              },
              ...audit,
            ]);
          }
        });
      }
      setAuditToProgram(auditInstallationToChoose);
    });
  });

  useEffect(() => {
    setAuditToProgram(auditInstallationToChoose);
  }, [auditInstallationToChoose]);

  const getLastProgrammingItem = (audit: AuditInProgress) => {
    return audit?.programmingAuditItems?.slice(-1)[0];
  };

  const isBlock = (audit: AuditInProgress) => {
    return (
      audit.programmingAuditItems.filter((a) => a.auditStatus === "ANNULE")
        .length === 3
    );
  };

  useEffect(() => {
    setHasAuditBlocked(
      audits.filter(
        (audit) => getLastProgrammingItem(audit)?.auditStatus === "ANNULE",
      ).length > 0,
    );
    setNbAuditsCanceled(
      audits.filter(
        (audit) =>
          audit.programmingAuditItems.filter((a) => a.auditStatus === "ANNULE")
            .length > 0,
      ).length,
    );
  }, [audits]);

  useEffect(() => {
    return requestGetAuditStatus.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAuditGlobalStatus(result.data);
        setShowWarningModal(
          result.data.pgStatus === "REC" || result.data.pgStatus === "RAD",
        );
      }
    });
  });

  return (
    <PageLayout
      container
      title={`${t<string>("pro.audit.list.title1")} ${audits.length} ${
        audits.length > 1
          ? t<string>("pro.audit.list.title2")
          : t<string>("pro.audit.list.title2bis")
      }`}
      titleCentered={false}
      rightContent={
        <div
          onClick={() => setIsTutorialModalVisible(true)}
          className="audit-list-tutorial-button"
        >
          {t<string>("pro.audit.list.rightContent")}
        </div>
      }
      subtitle={
        <>
          {t<string>("pro.audit.list.subtitle1")}
          <br />
          {t<string>("pro.audit.list.subtitle2")}
        </>
      }
      bodyClassName="app-audit-list"
    >
      <>
        <BasicCard className="audit-card card-no-margin-mobile audit-list-situation">
          <>
            <div
              className={`audit-list-situation-header ${
                nbAuditsCanceled < 5 && !hasAuditBlocked ? "title-only" : ""
              }`}
            >
              <div className="audit-list-situation-title-container">
                <h2 className="audit-list-situation-title">
                  {t<string>("pro.audit.list.situation.title")}
                  {auditGlobalStatus && (
                    <div className="audit-list-situation-title-status-tag">
                      <BasicTag
                        label={t<string>(
                          `${
                            AUDIT_GLOBAL_STATUS[auditGlobalStatus.pgStatus]
                              .label
                          }`,
                        )}
                        variant={t<string>(
                          `${
                            AUDIT_GLOBAL_STATUS[auditGlobalStatus.pgStatus]
                              .variant
                          }`,
                        )}
                      />
                    </div>
                  )}
                </h2>
                {isControl && (
                  <div className="audit-list-situation-title-status">
                    <div className="audit-list-situation-title-status-date">
                      {t<string>("pro.audit.list.situation.status.date")}
                      {DateFormat.formatUsDateToFrDate(
                        auditGlobalStatus?.dateChaineReport ?? "00/00/0000",
                      )}
                    </div>
                    <div className="audit-list-situation-title-status-days-left">
                      <BasicTag
                        label={`${DateFormat.getNumberOfDaysBeforeTheDeadline(
                          auditGlobalStatus?.dateChaineReport ?? "00/00/0000",
                        )} ${t<string>(
                          "pro.audit.list.situation.status.daysLeft",
                        )}`}
                        variant="warning"
                      />
                    </div>
                  </div>
                )}
              </div>
              {width < 768 && (
                <div className="audit-list-situation-status-jauge">
                  <img
                    alt={
                      nbAuditsCanceled > 3 || hasAuditBlocked
                        ? t<string>(
                            "pro.audit.list.situation.status.imgAltBlocage",
                          )
                        : t<string>("pro.audit.list.situation.status.imgAltRas")
                    }
                    src={
                      nbAuditsCanceled > 3 || hasAuditBlocked
                        ? "/img/pro/audit-jauge-blocage.png"
                        : "/img/pro/audit-jauge-ras.png"
                    }
                  />
                </div>
              )}
            </div>
            <div className="audit-list-situation-status-container">
              {width >= 768 && (
                <div className="audit-list-situation-status-jauge">
                  <img
                    alt={
                      nbAuditsCanceled > 3 || hasAuditBlocked
                        ? t<string>(
                            "pro.audit.list.situation.status.imgAltBlocage",
                          )
                        : t<string>("pro.audit.list.situation.status.imgAltRas")
                    }
                    src={
                      nbAuditsCanceled > 3 || hasAuditBlocked
                        ? "/img/pro/audit-jauge-blocage.png"
                        : "/img/pro/audit-jauge-ras.png"
                    }
                  />
                </div>
              )}
              <div className="audit-list-situation-status-content">
                <div className="audit-list-situation-status-content-title">
                  {nbAuditsCanceled > 3 ||
                    (hasAuditBlocked && (
                      <div className="audit-list-situation-status-content-title-icon">
                        <IconWarning />
                      </div>
                    ))}
                  <div className="audit-list-situation-status-content-title-text">
                    {nbAuditsCanceled === 0 && (
                      <>{t<string>("pro.audit.list.situation.status.title0")}</>
                    )}
                    {nbAuditsCanceled === 1 && (
                      <>
                        {t<string>(
                          `pro.audit.list.situation.status.title1${
                            hasAuditBlocked ? "Blocked" : ""
                          }`,
                        )}
                      </>
                    )}
                    {nbAuditsCanceled < 1 && nbAuditsCanceled > 4 && (
                      <>
                        {t<string>(
                          `pro.audit.list.situation.status.title2${
                            hasAuditBlocked ? "Blocked" : ""
                          }`,
                          { nbAudit: nbAuditsCanceled },
                        )}
                      </>
                    )}
                    {nbAuditsCanceled > 3 && !isControl && (
                      <>
                        {t<string>(
                          `pro.audit.list.situation.status.title${
                            hasAuditBlocked ? 2 : 3
                          }${hasAuditBlocked ? "Blocked" : ""}`,
                          { nbAudit: nbAuditsCanceled },
                        )}
                      </>
                    )}
                    {nbAuditsCanceled > 3 && isControl && (
                      <>
                        {t<string>(
                          `pro.audit.list.situation.status.title${
                            hasAuditBlocked ? 2 : 4
                          }${hasAuditBlocked ? "Blocked" : ""}`,
                          { nbAudit: nbAuditsCanceled },
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="audit-list-situation-status-content-description">
                  {t<string>("pro.audit.list.situation.status.description")}
                </div>
                <div className="audit-list-situation-status-content-info">
                  <InfoBulle className="audit-list-situation-status-content-info-icon" />
                  <div className="audit-list-situation-status-content-info-text">
                    {t<string>("pro.audit.list.situation.status.info")}
                  </div>
                </div>
              </div>
            </div>
            <div className="audit-list-situation-recap">
              <h4 className="audit-list-situation-recap-title">
                {t<string>("pro.audit.list.situation.recap.title")}
              </h4>
              <div className="audit-list-situation-recap-table">
                <div className="audit-list-situation-recap-table-header">
                  <div className="audit-list-situation-recap-table-title title-id">
                    {`${audits.length} ${t<string>(
                      "pro.audit.list.situation.recap.table.headers.id",
                    )}`}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "pro.audit.list.situation.recap.table.headers.first",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "pro.audit.list.situation.recap.table.headers.second",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "pro.audit.list.situation.recap.table.headers.third",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "pro.audit.list.situation.recap.table.headers.threshold",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-action">
                    {t<string>(
                      "pro.audit.list.situation.recap.table.headers.action",
                    )}
                  </div>
                </div>
                <div className="audit-list-situation-recap-table-rows">
                  {[...audits]
                    .sort(function (a, b) {
                      return (
                        parseInt(b.timerNextAction) -
                        parseInt(a.timerNextAction)
                      );
                    })
                    .map((audit, index) => {
                      const isBlocked =
                        getLastProgrammingItem(audit)?.auditStatus === "ANNULE";
                      const isWaiting =
                        getLastProgrammingItem(audit)?.auditStatus ===
                        "EN_ATTENTE_CC";
                      const isWaitingValidation =
                        getLastProgrammingItem(audit)?.auditStatus ===
                        "WAITING_VALIDATION";
                      const nbCanceled = audit?.programmingAuditItems?.filter(
                        (a) => a.auditStatus === "ANNULE",
                      ).length;

                      return (
                        <div
                          key={index.valueOf()}
                          className="audit-list-situation-recap-table-row"
                        >
                          <div className="audit-list-situation-recap-table-value table-id">
                            <div className="table-id-title">
                              {`${t<string>(
                                "pro.audit.list.situation.recap.table.row.id",
                              )}${index + 1}`}
                            </div>
                            <div className="table-id-days">
                              <IconWaiting />
                              {`${audit.timerNextAction} ${t<string>(
                                "pro.audit.list.situation.recap.table.row.limitDays",
                              )}`}
                            </div>
                            <div className="table-id-status">
                              {isWaiting &&
                                t<string>(
                                  "pro.audit.list.situation.recap.table.row.programmingStatus.waiting",
                                )}
                              {isWaitingValidation &&
                                t<string>(
                                  "pro.audit.list.situation.recap.table.row.programmingStatus.waitingValidation",
                                )}
                              {!audit.programmingAuditItems[0] && (
                                <div className="status-success">
                                  {t<string>(
                                    "pro.audit.list.situation.recap.table.row.new",
                                  )}
                                </div>
                              )}
                              {nbCanceled > 0 &&
                                !isWaiting &&
                                !isWaitingValidation && (
                                  <div className="status-canceled">
                                    <IconCanceled />
                                    {`${nbCanceled} ${t<string>(
                                      "pro.audit.list.situation.recap.table.row.canceled",
                                    )}`}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            <div
                              className={`table-programming-card card-${
                                AUDIT_PROGRAMMING_STATUS[
                                  audit.programmingAuditItems[0].auditStatus
                                ].variant
                              }`}
                            >
                              <Tooltip
                                title={t<string>(
                                  "pro.audit.list.situation.recap.table.row.tooltip",
                                  {
                                    ocCcId:
                                      audit.programmingAuditItems[0].ocCcId ??
                                      "",
                                  },
                                )}
                              >
                                {audit.programmingAuditItems[0].auditStatus ===
                                  "ANNULE" && <IconCanceled />}
                                {t<string>(
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[0].auditStatus
                                  ].label,
                                )}
                              </Tooltip>
                            </div>
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {audit.programmingAuditItems[1] && (
                              <div
                                className={`table-programming-card card-${
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[1].auditStatus
                                  ].variant
                                }`}
                              >
                                <Tooltip
                                  title={t<string>(
                                    "pro.audit.list.situation.recap.table.row.tooltip",
                                    {
                                      ocCcId:
                                        audit.programmingAuditItems[1].ocCcId ??
                                        "",
                                    },
                                  )}
                                >
                                  {audit.programmingAuditItems[1]
                                    .auditStatus === "ANNULE" && (
                                    <IconCanceled />
                                  )}
                                  {t<string>(
                                    AUDIT_PROGRAMMING_STATUS[
                                      audit.programmingAuditItems[1].auditStatus
                                    ].label,
                                  )}
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {audit.programmingAuditItems[2] && (
                              <div
                                className={`table-programming-card card-${
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[2].auditStatus
                                  ].variant
                                }`}
                              >
                                <Tooltip
                                  title={t<string>(
                                    "pro.audit.list.situation.recap.table.row.tooltip",
                                    {
                                      ocCcId:
                                        audit.programmingAuditItems[2].ocCcId ??
                                        "",
                                    },
                                  )}
                                >
                                  {audit.programmingAuditItems[2]
                                    .auditStatus === "ANNULE" && (
                                    <IconCanceled />
                                  )}
                                  {t<string>(
                                    AUDIT_PROGRAMMING_STATUS[
                                      audit.programmingAuditItems[2].auditStatus
                                    ].label,
                                  )}
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {isBlocked && (
                              <div className="table-programming-card card-danger">
                                <div className="text-center">
                                  <div>
                                    {t<string>(
                                      "pro.audit.list.situation.recap.table.row.threshold1",
                                    )}
                                  </div>
                                  <div>
                                    {t<string>(
                                      "pro.audit.list.situation.recap.table.row.threshold2",
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-action">
                            {isBlock(audit) ? (
                              <BasicButton
                                size="sm"
                                text={t<string>(
                                  "pro.audit.list.situation.recap.table.row.action.seeAuditCanceled",
                                )}
                                onClick={() => {
                                  setDetailAuditBlockModalVisible(true);
                                  setProgrammingAuditItems(
                                    audit.programmingAuditItems,
                                  );
                                }}
                              />
                            ) : (
                              <BasicButton
                                size="sm"
                                text={
                                  !audit.isProgrammable
                                    ? t<string>(
                                        "pro.audit.list.situation.recap.table.row.action.seeDetails",
                                      )
                                    : t<string>(
                                        "pro.audit.list.situation.recap.table.row.action.program",
                                      )
                                }
                                onClick={() => {
                                  if (audit.isProgrammable) {
                                    navigate(
                                      ROUTES.professional.audits.details.generate(
                                        audit.isProgrammable[0]
                                          ? audit.isProgrammable[0]
                                          : "0",
                                      ),
                                    );
                                  } else {
                                    setDetailAuditModalVisible(true);
                                    setProgrammingAuditItems(
                                      audit.programmingAuditItems,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="audit-list-situation-recap-info">
                <InfoBulle className="audit-list-situation-recap-info-icon" />
                <div className="audit-list-situation-recap-info-text">
                  {t<string>("pro.audit.list.situation.recap.info")}
                </div>
              </div>
            </div>
          </>
        </BasicCard>
        <div className="audit-list-infos">
          <h3 className="audit-list-infos-title">
            {t<string>("pro.audit.list.infos.title")}
          </h3>
          <div className="audit-list-infos-texts">
            <div className="audit-list-infos-text">
              {t<string>("pro.audit.list.infos.text1")}
            </div>
            <div className="audit-list-infos-text mt-0">
              {t<string>("pro.audit.list.infos.text2")}
            </div>
            <div className="audit-list-infos-text">
              {t<string>("pro.audit.list.infos.text3")}
            </div>
          </div>
        </div>
        <BasicCard className="audit-card audit-list-tips">
          <>
            <h3 className="audit-list-tips-title">
              {t<string>("pro.audit.list.tips.title")}
            </h3>
            <div className="audit-list-tips-texts">
              <div className="audit-list-tips-text">
                {t<string>("pro.audit.list.tips.text1")}
              </div>
              <div className="audit-list-tips-text mt-0">
                {t<string>("pro.audit.list.tips.text2")}
              </div>
              <div className="audit-list-tips-text mt-0">
                <b>{t<string>("pro.audit.list.tips.text3")}</b>
              </div>
            </div>
          </>
        </BasicCard>
        <div className="audit-list-historic">
          <h2 className="audit-list-historic-title">
            {t<string>("pro.audit.list.historic.title")}
          </h2>
          {previousAudits.content.length > 0 ? (
            <>
              <Table
                locale={{
                  emptyText: <Empty description={t<string>("common.noData")} />,
                }}
                columns={columns}
                dataSource={previousAudits.content}
                loading={!dataHasLoaded}
                size="small"
                className="app-contact-pro-table"
                pagination={{
                  total: previousAudits.totalElements,
                  pageSize: previousAudits.size,
                  current: previousAudits.number + 1,
                  onChange: setPageNumber,
                  onShowSizeChange: onChangeLimit,
                  showSizeChanger: true,
                }}
                onChange={(pagination, filters, sorter) =>
                  setSorter(sorter as SorterResult<any>)
                }
              />
              <div className="audit-list-historic-captions">
                <div className="audit-list-historic-captions-title">
                  {t<string>("pro.audit.list.historic.captions.title")}
                </div>
                {Object.values(AUDIT_RESULTS).map((result, index) => (
                  <div
                    key={index.valueOf()}
                    className="audit-list-historic-caption"
                  >
                    <div
                      className={`audit-list-historic-caption-circle circle-${result.variant}`}
                    />
                    {result.longLabel && (
                      <div className="audit-list-historic-caption-text">
                        {t<string>(result.longLabel)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>{t<string>("pro.audit.list.historic.noPreviousAudits")}</>
          )}
        </div>
        <TutorialModal
          title={t<string>("pro.audit.list.tutorial.title")}
          slides={[
            {
              title: t<string>("pro.audit.list.tutorial.slide1.title"),
              description: (
                <div>
                  <div className="description-dot dot-success">
                    <div>
                      {parse(
                        t<string>(
                          "pro.audit.list.tutorial.slide1.description1",
                        ),
                      )}
                    </div>
                  </div>
                  <div className="description-dot dot-warning">
                    <div>
                      {parse(
                        t<string>(
                          "pro.audit.list.tutorial.slide1.description2",
                        ),
                      )}
                    </div>
                  </div>
                </div>
              ),
              src: "/img/pro/audit-tutorial-slide1.png",
              alt: t<string>("pro.audit.list.tutorial.slide1.alt"),
              additionalText: t<string>(
                "pro.audit.list.tutorial.slide1.additionalText",
              ),
            },
            {
              title: t<string>("pro.audit.list.tutorial.slide2.title"),
              description: (
                <div>
                  <div>
                    {parse(
                      t<string>("pro.audit.list.tutorial.slide2.description1"),
                    )}
                  </div>
                  <div className="description-dot dot-grey">
                    <div>
                      {t<string>("pro.audit.list.tutorial.slide2.description2")}
                    </div>
                  </div>
                  <div className="description-dot dot-light-grey">
                    <div>
                      {t<string>("pro.audit.list.tutorial.slide2.description3")}
                    </div>
                  </div>
                </div>
              ),
              src: "/img/pro/audit-tutorial-slide2.png",
              alt: t<string>("pro.audit.list.tutorial.slide2.alt"),
            },
            {
              title: t<string>("pro.audit.list.tutorial.slide3.title"),
              description: (
                <div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide3.description1")}
                  </div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide3.description2")}
                  </div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide3.description3")}
                  </div>
                </div>
              ),
              src: "/img/pro/audit-tutorial-slide3.png",
              alt: t<string>("pro.audit.list.tutorial.slide3.alt"),
            },
            {
              title: t<string>("pro.audit.list.tutorial.slide4.title"),
              description: (
                <div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide4.description1")}
                  </div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide4.description2")}
                  </div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide4.description3")}
                  </div>
                </div>
              ),
              src: "/img/pro/audit-tutorial-slide4.png",
              alt: t<string>("pro.audit.list.tutorial.slide4.alt"),
            },
            {
              title: t<string>("pro.audit.list.tutorial.slide5.title"),
              description: (
                <div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide5.description1")}
                  </div>
                  <div>
                    {t<string>("pro.audit.list.tutorial.slide5.description2")}
                  </div>
                </div>
              ),
              src: "/img/pro/audit-tutorial-slide5.png",
              alt: t<string>("pro.audit.list.tutorial.slide5.alt"),
            },
          ]}
          open={isTutorialModalVisible}
          onCancel={() => setIsTutorialModalVisible(false)}
        />
        <AuditBlockDetailModal
          title={t<string>("pro.audit.modals.auditBlockDetail.title")}
          open={detailAuditBlockModalVisible}
          onCancel={() => setDetailAuditBlockModalVisible(false)}
          programmingAuditItems={programmingAuditItems}
          className={"app-audit-block-detail"}
        />
        <AuditDetailModal
          title={t<string>("pro.audit.modals.auditDetail.title")}
          open={detailAuditModalVisible}
          onCancel={() => setDetailAuditModalVisible(false)}
          programmingAuditItem={
            programmingAuditItems[programmingAuditItems.length - 1]
          }
          className={"app-audit-detail"}
        />
        <AuditWarningModal
          open={showWarningModal}
          onCancel={() => setShowWarningModal(false)}
          status={auditGlobalStatus?.pgStatus}
        />
      </>
    </PageLayout>
  );
};
