import {
  Equipment,
  FormPieceSectionResponse,
  QualivisaInformation,
  QualiVisaItemResponseDto,
  QuestionDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { qualivisaMapper } from "@utils/qualivisa-mapper";
import { QualivisaFormSteps } from "@utils/enums/qualivisa.enum";

const getPreviousQuestion = (
  resp: ResponseDto,
  qualivisaInfo: QualiVisaItemResponseDto[],
) => {
  return qualivisaInfo.find(
    (q) => q?.question.id === resp?.previousQuestionId,
  ) as QualiVisaItemResponseDto;
};

const getNextQuestion = (
  resp: ResponseDto,
  qualivisaInfo: QualiVisaItemResponseDto[],
) => {
  return qualivisaInfo.find(
    (q) => q?.question.id === resp?.nextQuestionId,
  ) as QualiVisaItemResponseDto;
};

const getPreviousResponse = (
  quest: QuestionDto,
  currentQuestion: ResponseDto[],
) => {
  return currentQuestion.filter((c) => c.nextQuestionId === quest.id);
};

const deleteChildResponses = (
  selectedResponses: ResponseDto[],
  oldResponses: ResponseDto,
) => {
  const responsesAfterBasicDeletion = deleteChildResponsesForBasicResponses(
    selectedResponses,
    oldResponses,
  );
  return deleteChildForEquipmentResponses(
    responsesAfterBasicDeletion,
    oldResponses,
  );
};

const deleteChildResponsesForBasicResponses = (
  selectedResponses: ResponseDto[],
  oldResponses: ResponseDto,
): ResponseDto[] => {
  const childResponses = selectedResponses.filter(
    (q) => q.previousQuestionId === oldResponses.nextQuestionId,
  );

  const updatedResponses = childResponses.reduce(
    (acc, child) => deleteChildResponses(acc, child),
    selectedResponses,
  );

  return updatedResponses.filter((s) => s !== oldResponses);
};

const deleteChildForEquipmentResponses = (
  selectedResponses: ResponseDto[],
  oldResponses: ResponseDto,
): ResponseDto[] => {
  const childResponses = selectedResponses.filter(
    (q) =>
      q.fqKey.slice(0, 9) === oldResponses.fqKey.slice(0, 9) &&
      q.previousQuestionId === oldResponses.nextQuestionId,
  );

  const updatedResponses = childResponses.reduce(
    (acc, child) => deleteChildResponses(acc, child),
    selectedResponses,
  );

  return updatedResponses.filter(
    (s) =>
      !(
        s.fqKey.slice(0, 9) === oldResponses.fqKey.slice(0, 9) &&
        s.id === oldResponses.id
      ),
  );
};

const removeDuplicatesQuestions = (arr: QualiVisaItemResponseDto[]) => {
  const seen: QualiVisaItemResponseDto[] = [];

  arr.map((s) => {
    if (!(seen.includes(s) && !s?.question.beginningLoop)) {
      seen.push(s);
    }
  });

  return seen;
};

const getLabelQuestionForEquipment = (arr: QualiVisaItemResponseDto[]) => {
  const sameLabel: string[] = [];

  arr.map((s) => {
    if (!sameLabel.includes(s?.question.label)) {
      sameLabel.push(s?.question.label);
    }
  });

  return sameLabel;
};

const getEvacuationType = (equipments: Equipment[]) => {
  return equipments
    .filter((e) => e.evacuationType !== undefined)
    .map((e) => (e ? e?.evacuationType ?? null : null))
    .map(
      (t) =>
        qualivisaMapper.evacuationTypeMapper.filter(
          (s) => s.value === t?.slice(0, 9),
        )[0]?.label,
    );
};

const getTypeOfEquipment = (equipments: Equipment[]) => {
  return equipments
    .map((e) => e.type)
    .map(
      (t) =>
        qualivisaMapper.typeMapper.filter((s) => s.value === t.slice(0, 9))[0]
          ?.label,
    );
};

const extractSelectedResponseFromQualivisaResponseTypologie = (
  qualivisa: string,
) => {
  const response = qualivisa.substring(1, qualivisa.length - 1);
  return response.split(",").map((e) => e.split("|")[1].replace(/\s+/g, ""));
};

const extractSelectedResponseFromQualivisaResponseEquipment = (
  qualivisa: string,
  qualivisaInfo: QualiVisaItemResponseDto[],
) => {
  const response = qualivisa.substring(1, qualivisa.length - 1);
  const b = response
    .split(",")
    .map((e) => e.split("|")[1].replace(/\s+/g, "").split("-"));
  return b.map((s) => {
    const resp = getResponseDtoWithFQKey(
      s[1] === "fq_q_2.01" ||
        s[1] === "fq_q_2.02" ||
        s[1] === "fq_q_2.03" ||
        s[1] === "fq_q_2.10"
        ? s[0]
        : s[1],
      qualivisaInfo,
    );
    return {
      id: resp?.id,
      previousQuestionId: resp?.previousQuestionId,
      nextQuestionId: resp?.nextQuestionId,
      fqKey: s?.[0].concat("-", s?.[1]),
      label: resp?.label,
      type: resp?.type,
      end: resp?.end,
    };
  });
};

const extractSelectedResponseFromQualivisaResponsePiece = (
  qualivisa: string,
  qualivisaInfo: QualiVisaItemResponseDto[],
  currentRoom: number,
) => {
  const jsonResponseParse = JSON.parse(qualivisa) as FormPieceSectionResponse;
  const nbRoom = jsonResponseParse.nbRoom;
  const pieceNumberResponse = getResponseDtoWithFQKey(
    qualivisaMapper.pieceNumberMapper.filter(
      (piece) => piece.label === nbRoom,
    )[0].value,
    qualivisaInfo,
  );
  const responsePiece = jsonResponseParse.piece?.filter(
    (piece) => piece?.pieceIndex === currentRoom,
  )[0];

  const selectedResponse = responsePiece?.responsesId
    ?.filter((response) => response.questionId !== "fq_q_3.01")
    .map((r) => {
      const [fqKey] =
        r.responseId.length > 9 ? r.responseId.split("-") : [r.responseId];
      const resp = getResponseDtoWithFQKey(fqKey, qualivisaInfo);
      return {
        id: resp?.id,
        previousQuestionId: resp?.previousQuestionId,
        nextQuestionId: resp?.nextQuestionId,
        fqKey: r.responseId,
        label: resp?.label,
        type: resp?.type,
        end: resp?.end,
      };
    }) as ResponseDto[];
  return [
    pieceNumberResponse,
    ...(Array.isArray(selectedResponse) ? selectedResponse : []),
  ];
};

const extractEquipmentInPieceFromQualivisaResponsePiece = (
  qualivisa: string,
  allEquipment: Equipment[],
  currentRoom: number,
) => {
  const jsonResponseParse = JSON.parse(qualivisa) as FormPieceSectionResponse;
  const responsePiece = jsonResponseParse.piece?.filter(
    (piece) => piece?.pieceIndex === currentRoom,
  )[0];
  return allEquipment?.filter((equipment) =>
    responsePiece?.equipment.includes(equipment.equipment),
  );
};

const extractNamePieceFromQualivisaResponsePiece = (
  qualivisa: string,
  currentRoom: number,
) => {
  const jsonResponseParse = JSON.parse(qualivisa) as FormPieceSectionResponse;
  const responsePiece = jsonResponseParse.piece?.filter(
    (piece) => piece?.pieceIndex === currentRoom,
  )[0];
  return responsePiece?.pieceName ?? undefined;
};

const getResponseDtoWithFQKeys = (
  fqkeys: string[],
  qualivisaInfo: QualiVisaItemResponseDto[],
) => {
  const responsesDto = [] as ResponseDto[];
  qualivisaInfo.map((resp) =>
    resp.responses.map((s) => {
      if (fqkeys.includes(s.fqKey)) {
        responsesDto.push(s);
      }
    }),
  );
  return responsesDto;
};

const getResponseDtoWithFQKey = (
  fqkeys: string,
  qualivisaInfo: QualiVisaItemResponseDto[],
) => {
  const responsesDto = [] as ResponseDto[];
  qualivisaInfo.map((resp) =>
    resp.responses.map((s) => {
      if (fqkeys === s.fqKey) {
        responsesDto.push(s);
      }
    }),
  );
  return responsesDto?.[0];
};

const getMostAdvancedStep = (qualivisa: QualivisaInformation) => {
  const form3 = qualivisa?.formResponse?.filter(
    (formResponse) => formResponse.formStep === QualivisaFormSteps.PIECES,
  );
  const form22 = qualivisa?.formResponse?.filter(
    (formResponse) =>
      formResponse.formStep === QualivisaFormSteps.EQUIPEMENT_EXISTANT,
  );
  const form21 = qualivisa?.formResponse?.filter(
    (formResponse) =>
      formResponse.formStep === QualivisaFormSteps.EQUIPEMENT_NEUF,
  );
  const form1 = qualivisa?.formResponse?.filter(
    (formResponse) =>
      formResponse.formStep === QualivisaFormSteps.TYPOLOGIE_CHANTIER,
  );

  if (form3 != null && form3?.length > 0) {
    return QualivisaFormSteps.PIECES;
  } else if (form22 != null && form22?.length > 0) {
    return QualivisaFormSteps.EQUIPEMENT_EXISTANT;
  } else if (form21 != null && form21?.length > 0) {
    return QualivisaFormSteps.EQUIPEMENT_NEUF;
  } else if (form1 != null && form1?.length > 0) {
    return QualivisaFormSteps.TYPOLOGIE_CHANTIER;
  } else return QualivisaFormSteps.INFORMATION_CHANTIER;
};

export const qualivisaUtils = {
  deleteChildResponses,
  deleteChildForEquipmentResponses,
  extractEquipmentInPieceFromQualivisaResponsePiece,
  extractNamePieceFromQualivisaResponsePiece,
  extractSelectedResponseFromQualivisaResponseEquipment,
  extractSelectedResponseFromQualivisaResponseTypologie,
  extractSelectedResponseFromQualivisaResponsePiece,
  getEvacuationType,
  getMostAdvancedStep,
  getNextQuestion,
  getPreviousQuestion,
  getPreviousResponse,
  getLabelQuestionForEquipment,
  getResponseDtoWithFQKeys,
  getTypeOfEquipment,
  removeDuplicatesQuestions,
};
