import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { columns } from "@components/lists/MailsOrganisationProfessionalColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";
import BasicButton from "@components/buttons/BasicButton";
import { MailOutlined } from "@ant-design/icons";
import MailDetailModal from "@components/modals/MailDetailModal";
import SearchFormField from "@components/inputs/SearchFormField";
import {
  requestGetOrganisationProfessionalHabitAPlusMails,
  requestUpdateOrganisationProfessionalHabitAPlusMailRead,
} from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";
import toastAlert from "@components/alerts/ToastAlert";
import { Loader } from "@components/loader/Loader";
import configuration from "@utils/Config";
import { useStore } from "effector-react";
import {
  MailProfessionalContext,
  mailProfessionalStore,
} from "@state/mailprofessional/MailProfessionalStore";
import { setOpMails } from "@state/mailprofessional/MailProfessionalEvents";

export const MailsOrganisationProfessionalScreen: React.FC = () => {
  const { t } = useTranslation();
  const { loading, opMails } = useStore<MailProfessionalContext>(
    mailProfessionalStore,
  );

  const [mails, setMails] =
    useState<Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>>(
      opMails,
    );

  const searchTimeout = useRef<number>();
  const shouldFetch = useRef<boolean>(false);
  const [localQuery, setLocalQuery] = useState<string>("");

  const [selectedRows, setSelectedRows] = useState<
    OrganisationProfessionalHabitAPlusMailsResponseDto[]
  >([]);

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(!loading);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "date",
    order: "descend",
  });

  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [detailModalTitle, setDetailModalTitle] = useState<string>("");
  const [detailModalUrl, setDetailModalUrl] = useState<string>("");

  const onChangeLimit = (current: number, newLimit: number): void => {
    shouldFetch.current = true;
    setLimit(newLimit);
  };

  const updateRead = (
    mail: OrganisationProfessionalHabitAPlusMailsResponseDto,
  ) => {
    if (!mail.read) {
      requestUpdateOrganisationProfessionalHabitAPlusMailRead({
        id: mail.id,
        dto: { read: true },
      })
        .then(() => {
          // Mise à jour des données du state
          setMails(
            (
              state: Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>,
            ) => {
              return {
                ...state,
                content: state.content.map(
                  (
                    m: OrganisationProfessionalHabitAPlusMailsResponseDto,
                  ): OrganisationProfessionalHabitAPlusMailsResponseDto => ({
                    ...m,
                    read: m.id === mail.id || m.read,
                  }),
                ),
              };
            },
          );
          // Mise à jour des données du store
          setOpMails({
            ...opMails,
            content: opMails.content.map(
              (
                m: OrganisationProfessionalHabitAPlusMailsResponseDto,
              ): OrganisationProfessionalHabitAPlusMailsResponseDto => ({
                ...m,
                read: m.id === mail.id || m.read,
              }),
            ),
          });
        })
        .catch(() => {
          toastAlert(t<string>("pro.mail.error"));
        });
    }
  };

  const onRowClick = (
    record: OrganisationProfessionalHabitAPlusMailsResponseDto,
  ): void => {
    setDetailModalTitle(record.title);
    setDetailModalUrl(getPDFLink(record.id));
    if (record.title) {
      setShowDetailModal(true);
    }
    updateRead(record);
  };

  const fetchMails = useCallback(() => {
    setDataHasLoaded(false);
    requestGetOrganisationProfessionalHabitAPlusMails({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: { query: localQuery },
    }).catch(() => {
      toastAlert(t<string>("pro.mail.error"));
    });
  }, [pageNumber, limit, t, sorter, localQuery]);

  useEffect(() => {
    if (shouldFetch.current) {
      fetchMails();
    }
  }, [fetchMails]);

  useEffect(() => {
    return requestGetOrganisationProfessionalHabitAPlusMails.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setMails(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    shouldFetch.current = true;
    const value = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setLocalQuery(value), 800);
  };

  const onPageChange = (page: number) => {
    shouldFetch.current = true;
    setPageNumber(page);
  };

  const getPDFLink = (id: string) => {
    return `${configuration.backendBaseUrl}/organisation-professionals/mails/${id}/file`;
  };

  const unreadMailCount = mails.content.filter(
    (mail: OrganisationProfessionalHabitAPlusMailsResponseDto): boolean =>
      !mail.read,
  ).length;
  return (
    <PageLayout
      noProSideMenu
      organisationProSideMenu
      container
      titleCentered={false}
      title={t<string>("organisationprofessional.mails.op.title")}
    >
      {mails == emptySearchResult ? (
        <Loader text={t<string>("pro.loader")} />
      ) : (
        <>
          <h3 className="text-primary my-3 d-flex align-items-center">
            {t("organisationprofessional.mails.op.newMail", {
              count: unreadMailCount,
            })}
            <BasicButton
              className="ms-3"
              text={t<string>("organisationprofessional.mails.op.refresh")}
              onClick={fetchMails}
              isLoading={!dataHasLoaded}
            />
          </h3>
          <div className="d-flex align-items-center justify-content-end flex-wrap mb-3 gap-3">
            <div className="flex-grow-1 d-flex align-items-center flex-wrap gap-3">
              <h4 className="text-primary mb-0">
                {t<string>("organisationprofessional.mails.op.selectedMail", {
                  count: selectedRows.length,
                })}
              </h4>
              <BasicButton
                text={t<string>(
                  "organisationprofessional.mails.op.groupedMail",
                )}
                icon={<MailOutlined />}
                iconRight
                disabled={!selectedRows.length}
                onClick={() => {
                  const emails = selectedRows
                    .map((row) => row.mailRecipient)
                    .filter(Boolean)
                    .join(",");
                  window.location.href = `mailto:${emails}`;
                }}
                size="sm"
              />
            </div>
            <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
              <SearchFormField
                module="organisationprofessional.mails"
                field="search"
                onChange={onChange}
                onSearch={(value: string) => setLocalQuery(value)}
                value={localQuery}
              />
            </div>
          </div>
          <Table
            locale={{
              emptyText: <Empty description={t<string>("common.noData")} />,
            }}
            columns={columns(onRowClick, updateRead, getPDFLink)}
            dataSource={mails.content}
            loading={{
              spinning: !dataHasLoaded,
              indicator: <Loader />,
            }}
            size="small"
            rowKey={(record) => record.id}
            rowClassName={(
              record: OrganisationProfessionalHabitAPlusMailsResponseDto,
            ) => (record.read ? "" : "new")}
            className="app-contact-pro-table pro-table-large-font"
            pagination={{
              total: mails.totalElements,
              pageSize: mails.size,
              current: mails.number + 1,
              onChange: onPageChange,
              onShowSizeChange: onChangeLimit,
              showSizeChanger: true,
            }}
            onChange={(pagination, filters, sorter) => {
              shouldFetch.current = true;
              setSorter(sorter as SorterResult<any>);
            }}
            onRow={(record) => {
              return {
                onClick: () => onRowClick(record),
              };
            }}
            rowSelection={{
              type: "checkbox",
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: OrganisationProfessionalHabitAPlusMailsResponseDto[],
              ) => {
                setSelectedRows(selectedRows);
              },
            }}
          />
          <MailDetailModal
            visible={showDetailModal}
            onCancel={() => setShowDetailModal(false)}
            title={detailModalTitle}
            url={detailModalUrl}
          />
        </>
      )}
    </PageLayout>
  );
};
