import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import {
  QualiVisaItemResponseDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { QualivisaResponseItem } from "@views/pro/gazProfessional/qualivisa/QualivisaResponseItem";
import { InputNumber } from "antd";

interface QualivisaQuestionResponseItemPros {
  qualivisaItem: QualiVisaItemResponseDto;
  handleSelectResponse: (item: ResponseDto, value?: number) => void;
  selectedResponses: ResponseDto[];
  questionType?: string;
}

export const QualivisaQuestionResponseItem: FC<
  QualivisaQuestionResponseItemPros
> = ({
  qualivisaItem,
  handleSelectResponse,
  selectedResponses,
  questionType,
}) => {
  const { t } = useTranslation();

  const handleSingleResponse = useCallback(() => {
    const singleResponse = qualivisaItem.responses.length < 2;
    const selectedResponseKey = selectedResponses.map((m) => m?.fqKey);
    const responseToSelect = qualivisaItem.responses[0];

    if (
      singleResponse &&
      (questionType === "equipment"
        ? !selectedResponseKey.includes(responseToSelect.fqKey)
        : !selectedResponses.includes(responseToSelect))
    ) {
      handleSelectResponse(responseToSelect);
    }
  }, [
    handleSelectResponse,
    qualivisaItem.responses,
    questionType,
    selectedResponses,
  ]);

  useEffect(() => {
    handleSingleResponse();
  }, []);

  return (
    <div className="app-qualivisa-form-item">
      <p className="qualivisa-form-item-question">
        {parse(t<string>(qualivisaItem?.question.label))}
        {qualivisaItem.question.type !== "input" && (
          <span className="qualivisa-form-item-question-infos">
            {" "}
            {qualivisaItem.question.type === "multiple"
              ? t<string>("pro.qualivisa.form.item.infos.multiple")
              : t<string>("pro.qualivisa.form.item.infos.others")}
          </span>
        )}
      </p>
      {qualivisaItem.question.type === "input" ? (
        <>
          <InputNumber
            value={
              selectedResponses
                .filter((response) => response?.end)[0]
                ?.fqKey.split("-")[1]
            }
            onChange={(e) =>
              e != null &&
              handleSelectResponse(
                qualivisaItem.responses[0],
                parseInt(e.toString()),
              )
            }
            placeholder={t<string>("pro.qualivisa.form.item.placeholder")}
            className="qualivisa-form-item-input"
            controls={false}
          />
        </>
      ) : (
        <div className="qualivisa-form-item-responses">
          {qualivisaItem.responses
            .sort(
              (a, b) =>
                parseInt(a.fqKey.slice(7, 9)) - parseInt(b.fqKey.slice(7, 9)),
            )
            .map((resp) => (
              <QualivisaResponseItem
                key={resp.fqKey}
                response={resp}
                selectedResponses={selectedResponses}
                onClick={(resp) => handleSelectResponse(resp)}
              />
            ))}
        </div>
      )}
    </div>
  );
};
