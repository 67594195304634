import i18n from "i18next";
import { ColumnsType } from "antd/es/table";
import {
  ComplementaryInfosItem,
  ComplementaryInfosItemContent,
} from "@views/pro/gazProfessional/qualivisa/QualivisaCriteriaCheck";
import { ReactNode } from "react";
import parse from "html-react-parser";
import {
  IconAirSupply,
  IconConduit,
  IconNotValid,
  IconValid,
  IconVentilation,
  IconVMC,
  IconWarningWhite,
} from "../../static/icons";

const getItemRender = (
  record: ComplementaryInfosItemContent | ComplementaryInfosItemContent[],
) => {
  const items = Array.isArray(record) ? record : [record];
  return (
    <div className="qualivisa-criteria-check-complementary-infos-table-items">
      {items.map((item, index) => (
        <div
          key={index}
          className="qualivisa-criteria-check-complementary-infos-table-item"
        >
          {item?.icon && (
            <div className="qualivisa-criteria-check-complementary-infos-table-item-icon">
              {item.icon === "airSupply" && <IconAirSupply />}
              {item.icon === "conduit" && <IconConduit />}
              {item.icon === "ventilation" && <IconVentilation />}
              {item.icon === "vmc" && <IconVMC />}
              {item.status && (
                <div
                  className={`qualivisa-criteria-check-complementary-infos-table-item-icon-status status-${item.status}`}
                >
                  {item.status === "success" && <IconValid />}
                  {item.status === "warning" && <IconWarningWhite />}
                  {item.status === "danger" && <IconNotValid />}
                </div>
              )}
            </div>
          )}
          <div className="qualivisa-criteria-check-complementary-infos-table-item-text">
            <span>{parse(item?.text ?? "")}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const columns = (
  indirectTitle: string,
): ColumnsType<ComplementaryInfosItem> => [
  {
    title: i18n.t(
      "pro.qualivisa.form.criteria.complementaryInfos.table.airOutlet",
    ),
    dataIndex: "airOutlet",
    key: "airOutlet",
    sorter: false,
    render: (airOutlet): ReactNode => {
      return getItemRender(airOutlet);
    },
  },
  {
    title: i18n.t(
      "pro.qualivisa.form.criteria.complementaryInfos.table.airSupply",
    ),
    children: [
      {
        title: indirectTitle,
        dataIndex: "indirectAirSupply",
        key: "indirectAirSupply",
        sorter: false,
        render: (indirectAirSupply): ReactNode => {
          return indirectAirSupply ? getItemRender(indirectAirSupply) : null;
        },
      },
    ],
  },
];
