import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicButton from "@components/buttons/BasicButton";
import {
  requestGetQualivisaFormExport,
  requestGetRecapQualivisa,
  requestUpdateConformityAnswersQualivisa,
} from "@state/qualivisa/QualivisaEffects";
import FileSaver from "file-saver";
import { toastError, toastSuccess } from "@utils/toast-helper";
import {
  QualiVisaRecapResponseDto,
  QualiVisaRecapResponseItemDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { IconEdit } from "../../../../../static/icons";
import { ROUTES } from "@utils/Routes";
import { useNavigate } from "react-router-dom";
import { ConformityAnswersDictionary } from "@state/qualivisa/dto/quali.visa.save.request.dto";
import { QualivisaConformityQuestions } from "@views/pro/gazProfessional/qualivisa/QualivisaConformityQuestions";
import Section from "@components/section/Section";
import { QualivisaRecapTypologie } from "@components/qualivisa/recap/QualivisaRecapTypologie";
import { QualivisaRecapEquipment } from "@components/qualivisa/recap/QualivisaRecapEquipment";
import { QualivisaRecapPiece } from "@components/qualivisa/recap/QualivisaRecapPiece";
import { QualivisaSignatureBlock } from "@components/qualivisa/recap/QualivisaSignatureBlock";
import { Form } from "antd";

export const QualivisaRecapScreen: FC = () => {
  const { t } = useTranslation();
  const [stepRecap, setStepRecap] = useState<QualiVisaRecapResponseDto>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSign, setIsSign] = useState<boolean>(false);
  const [formulaireType, setFormulaireType] = useState<string>("FVQ");
  const [isConformityQuestionVisible, setIsConformityQuestionVisible] =
    useState<boolean>(false);
  const sortList = ["1", "2.1", "2.2", "3"];
  const navigate = useNavigate();
  const [conformityAnswers, setConformityAnswers] =
    useState<ConformityAnswersDictionary>({});
  const formulaireId =
    location.pathname.split("/")[location.pathname.split("/").length - 2] || "";
  const [customerSignature, setCustomerSignature] = useState<string>(
    stepRecap?.customerSignature || "",
  );
  const [managerSignature, setManagerSignature] = useState<string>(
    stepRecap?.managerSignature || "",
  );

  const fetchRecap = useCallback(() => {
    void requestGetRecapQualivisa(formulaireId);
  }, [formulaireId]);

  useEffect(() => {
    return requestGetRecapQualivisa.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setStepRecap(result.data);
      }
    });
  }, [formulaireId, fetchRecap]);

  useEffect(() => {
    fetchRecap();
    setConformityAnswers({});
  }, [fetchRecap]);

  useEffect(() => {
    return requestUpdateConformityAnswersQualivisa?.done?.watch(
      ({ result }) => {
        if (result.ok && result.data) {
          setIsLoading(false);
          toastSuccess(t<string>("pro.qualivisa.messages.success.generate"));
          void requestGetRecapQualivisa(formulaireId);
        }
      },
    );
  }, [formulaireId]);

  const handleGeneratePdf = () => {
    void requestGetQualivisaFormExport(formulaireId);
    setIsLoading(true);
  };

  const handleSignatureUpdate = (
    managerSignature: string,
    customerSignature: string,
  ) => {
    if (managerSignature) {
      setManagerSignature(managerSignature);
    }
    if (customerSignature) {
      setCustomerSignature(customerSignature);
    }
  };

  const handleSubmit = () => {
    void requestUpdateConformityAnswersQualivisa({
      dto: {
        conformityAnswers: conformityAnswers,
        customerSignature: customerSignature,
        managerSignature: managerSignature,
        formulaireType: formulaireType,
        installerName: form.getFieldValue("installerName"),
        customerRepresentativeName: form.getFieldValue(
          "customerRepresentativeName",
        ),
      },
      id: formulaireId,
    });
    setIsLoading(true);
  };

  useEffect(() => {
    return requestGetQualivisaFormExport.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const data = new Blob([result.data], {
          type: "application/octet-stream",
        });
        FileSaver.saveAs(data, result.fileName);
        toastSuccess(t<string>("pro.qualivisa.messages.success.generate"));
      } else {
        if (result.errorMessage) {
          toastError(result.errorMessage);
        } else {
          toastError(t<string>("pro.qualivisa.messages.errors.generate"));
        }
      }
      setIsLoading(false);
    });
  });

  const getRecapBlock = (recap: QualiVisaRecapResponseItemDto) => {
    if (recap.items) {
      return <QualivisaRecapTypologie recap={recap} />;
    } else if (recap.qualivisaFormulaireEquipmentItems) {
      return <QualivisaRecapEquipment recap={recap} />;
    } else if (recap.qualivisaFormPieceContentResponseItems) {
      return <QualivisaRecapPiece recap={recap} />;
    } else return null;
  };

  const areConformityQuestionsCompleted = useMemo(() => {
    if (
      stepRecap?.conformityQuestions &&
      stepRecap?.conformityQuestions.length > 0 &&
      conformityAnswers
    ) {
      const conformityQuestions = stepRecap?.conformityQuestions;
      return conformityQuestions
        ? conformityQuestions.every(
            (question) => conformityAnswers[question.key],
          )
        : false;
    }
    return false;
  }, [conformityAnswers, stepRecap?.conformityQuestions]);

  return (
    <PageLayout
      container
      title={t<string>("pro.qualivisa.recap.title")}
      titleCentered={false}
      bodyClassName="app-qualivisa-recap"
    >
      <>
        <div className="qualivisa-recap-header">
          <h2>{t<string>("pro.qualivisa.recap.formRecap")}</h2>
          <div
            className="qualivisa-recap-header-edit"
            onClick={() =>
              navigate(
                ROUTES.professional.qualivisa.details.generate(formulaireId),
              )
            }
          >
            <IconEdit />
            <span>{t<string>("pro.qualivisa.recap.edit")}</span>
          </div>
        </div>
        <div className="qualivisa-recap-body">
          {stepRecap?.content
            .sort((a, b) => sortList.indexOf(a.id) - sortList.indexOf(b.id))
            .map((recap) => getRecapBlock(recap))}
        </div>
        <div className="qualivisa-recap-footer mb-2">
          <BasicButton
            variant="primary"
            text={t<string>(
              `${
                isConformityQuestionVisible
                  ? "Masquer les critères"
                  : "Afficher les critères"
              }`,
            )}
            onClick={() =>
              setIsConformityQuestionVisible(!isConformityQuestionVisible)
            }
            className="mention-button"
            isLoading={isLoading}
          />
        </div>

        {isConformityQuestionVisible && (
          <Section title="Critères de conformité">
            <>
              {stepRecap?.conformityQuestions &&
                stepRecap?.conformityQuestions.length > 0 && (
                  <QualivisaConformityQuestions
                    conformityQuestions={stepRecap?.conformityQuestions}
                    conformityAnswers={conformityAnswers}
                    setConformityAnswers={setConformityAnswers}
                    showNew={true}
                    pieceContent={
                      stepRecap.content.filter(
                        (content) => content.id === "3",
                      )[0].qualivisaFormPieceContentResponseItems ?? []
                    }
                    showExisting={
                      !stepRecap.hideConformityQuestionColumnExistant
                    }
                  />
                )}
            </>
          </Section>
        )}

        {stepRecap && (
          <Section title="Signature">
            <QualivisaSignatureBlock
              qualivisa={stepRecap}
              setIsSign={setIsSign}
              setFormulaireType={setFormulaireType}
              formulaireType={formulaireType}
              form={form}
              onUpdateSignatures={handleSignatureUpdate}
            />
          </Section>
        )}
        <div className="qualivisa-recap-footer">
          <div className="d-flex flex-column-reverse flex-md-row gap-3">
            <BasicButton
              variant="primary"
              text={t<string>("Valider les réponses")}
              disabled={!(areConformityQuestionsCompleted && isSign)}
              onClick={handleSubmit}
              className="mention-button"
              isLoading={isLoading}
            />
            <BasicButton
              variant="primary"
              text={t<string>("Générer le récapitulatif PDF")}
              disabled={stepRecap?.formulaireStatus !== "FINISHED"}
              onClick={handleGeneratePdf}
              className="mention-button"
              isLoading={isLoading}
            />
          </div>
          <img
            src="/img/pro/qualivisa-recap.svg"
            alt={t<string>("pro.qualivisa.recap.alt")}
          />
        </div>
      </>
    </PageLayout>
  );
};
