import { FunctionComponent } from "react";
import {
  QualiVisaItemResponseDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { QualivisaQuestionResponseItem } from "@views/pro/gazProfessional/qualivisa/QualivisaQuestionResponseItem";

interface QualivisaTypologieQuestionProps {
  currentResponses: QualiVisaItemResponseDto[];
  handleSelectResponse: (item: ResponseDto, value?: number) => void;
  selectedResponses: ResponseDto[];
}

const QualivisaTypologieQuestion: FunctionComponent<
  QualivisaTypologieQuestionProps
> = ({ currentResponses, handleSelectResponse, selectedResponses }) => {
  return (
    <>
      {currentResponses?.map((s) => (
        <QualivisaQuestionResponseItem
          key={s.question.fqKey}
          handleSelectResponse={handleSelectResponse}
          qualivisaItem={s}
          selectedResponses={selectedResponses}
        />
      ))}
    </>
  );
};
export default QualivisaTypologieQuestion;
