import { FC } from "react";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import { Table } from "antd";
import { columns } from "@components/lists/QualivisaCriteriaCheckComplementaryInfosColumns";
import { QualivisaFormPieceContentResponseItem } from "@state/qualivisa/dto/quali.visa.item.response.dto";

export interface ComplementaryInfosItemContent {
  icon?: "airSupply" | "conduit" | "ventilation" | "vmc";
  status?: "success" | "warning" | "danger";
  text: string;
  title?: string;
}

export interface ComplementaryInfosItem {
  key: string;
  airOutlet?: ComplementaryInfosItemContent | ComplementaryInfosItemContent[];
  indirectAirSupply?: ComplementaryInfosItemContent;
  otherValue?: string;
}

interface QualivisaCriteriaCheckProps {
  className?: string;
  pieceContent: QualivisaFormPieceContentResponseItem;
}

export const QualivisaCriteriaCheck: FC<QualivisaCriteriaCheckProps> = ({
  className = "",
  pieceContent,
}) => {
  const { t } = useTranslation();

  const getCriteriaCheck = (
    pieceContent: QualivisaFormPieceContentResponseItem,
  ) => {
    const airOutlet = pieceContent.items.filter((item) =>
      item.questionLabel.includes("sortie"),
    )[0]?.responseLabel;
    const airSupply = pieceContent.items.filter(
      (item) =>
        item.questionLabel.includes("entrée") ||
        item.questionLabel.includes("amenée"),
    )[0];
    const itemPower = pieceContent.items.filter((item) =>
      item.questionLabel.includes("puissance"),
    )[0];
    const power = parseInt(itemPower?.responseLabel ?? 0);

    const getAirOutletItem = () => {
      switch (airOutlet) {
        case "Directe":
          return { icon: "ventilation", text: "<b>Directe</b>" };
        case "Conduit vertical":
          return { icon: "conduit", text: "<b>Conduit</b>" };
        case "Par coupe tirage":
          return { text: "<b>Coupe Tirage</b>" };
        case "Par dispositif sous avis technique":
          return { text: "<b>Par dispositif sous avis technique</b>" };
        case "VMC":
          return { icon: "vmc", text: "<b>VMC</b>" };
      }
      return { text: "<b>Pas de sortie d’air supplémentaire</b>" };
    };

    const getAirSupplyItem = () => {
      const getText = () => {
        let indirectAirSupply;

        const extractMultiplier = (label: string): number => {
          const match = label.match(/\[(\d+(\.\d+)?)\s*\*\s*Pu]/);
          return match ? parseFloat(match[1]) : 1;
        };
        if (itemPower?.responseType === "INPUT") {
          if (power <= 25)
            indirectAirSupply = {
              icon: "ventilation",
              text: "Pu* inférieure ou égale à 25kW : section min <b>50cm2</b><br/>",
              status: "success",
            };
          else if (power <= 35)
            indirectAirSupply = {
              icon: "ventilation",
              text: "Pu* supérieure à 25kW et jusqu’à 35kW : section min <b>70cm2</b><br/>",
              status: "success",
            };
          else if (power <= 50)
            indirectAirSupply = {
              icon: "ventilation",
              status: "success",
              text: "Pu* supérieure à 35kW et jusqu’à 50kW : section min <b>100cm2</b><br/>",
            };
          else if (power <= 70)
            indirectAirSupply = {
              icon: "ventilation",
              status: "success",
              text: "Pu* supérieure à 50kW et jusqu’à 70kW : section min <b>150cm2</b><br/>",
            };
          else
            indirectAirSupply = {
              icon: "ventilation",
              text: "Pu trop élevée",
              status: "danger",
            };
        } else if (itemPower?.responseType === "FORMULA_INPUT") {
          indirectAirSupply = {
            icon: "airSupply",
            text: t<string>(
              `La somme des modules d’entrée d’air est au moins égale à : <b>${(
                power * extractMultiplier(itemPower.responseExtraLabel)
              ).toFixed(2)} m3/h</b>`,
            ),
          };
        } else if (airSupply.responseExtraLabel) {
          indirectAirSupply = {
            text: airSupply?.responseExtraLabel,
          };
        } else
          indirectAirSupply = {
            text: "",
          };
        return indirectAirSupply;
      };

      switch (airSupply?.responseLabel) {
        case "Directe":
          return {
            title: "Directe",
            text: getText().text,
            icon: getText()?.icon,
          };
        case "Module d’entrée d’air":
          return {
            title: "Module d’entrée d’air",
            text: getText().text,
            icon: getText()?.icon,
          };
        case "Grille d’entrée d’air":
          return {
            title: "Grille d’entrée d’air",
            text: getText().text,
            icon: getText()?.icon,
          };
        case "Par module d’entrée d’air":
          return {
            title: "Module d’entrée d’air",
            text: getText().text,
            icon: getText()?.icon,
          };
        case "Indirecte par grille":
          return {
            title: "Indirecte par grille",
            text: getText().text,
            icon: getText()?.icon,
          };
        case "Indirecte par module d’entrée d’air":
          return {
            title: "Indirecte par module d’entrée d’air",
            text: getText().text,
            icon: getText()?.icon,
          };
      }
      return undefined;
    };

    const createInfo = (
      airOutlet: any,
      indirectAirSupply: any,
      otherValue: any = undefined,
    ) => {
      return {
        key: "1",
        airOutlet: airOutlet,
        indirectAirSupply: indirectAirSupply,
        otherValue: otherValue,
      } as ComplementaryInfosItem;
    };
    return createInfo(getAirOutletItem(), getAirSupplyItem(), undefined);
  };

  const data = getCriteriaCheck(pieceContent);

  return (
    <div className={`app-qualivisa-criteria-check ${className}`}>
      <BasicDivider variant="grey" height={1} className="w-100 mt-0 mb-20" />
      <p className="mt-0 mb-10">
        {t<string>("pro.qualivisa.form.criteria.title")}
      </p>

      <div className="qualivisa-criteria-check-complementary-infos">
        {!(data != undefined && data.otherValue) ? (
          <>
            <p className="mt-0 mb-10">
              {t<string>(
                "pro.qualivisa.form.criteria.complementaryInfos.title",
              )}
            </p>
            <Table
              columns={columns(data?.indirectAirSupply?.title ?? "Indirecte")}
              dataSource={[data]}
              bordered
              pagination={false}
              className="qualivisa-criteria-check-complementary-infos-table"
            />
          </>
        ) : (
          <div className="qualivisa-criteria-check-complementary-infos-legend">
            <span>{t<string>(data.otherValue)}</span>
          </div>
        )}
      </div>
    </div>
  );
};
