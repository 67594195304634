import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useStore } from "effector-react";
import { qualivisaStore } from "@state/qualivisa/QualivisaStore";
import { QualivisaQuestionResponseItem } from "@views/pro/gazProfessional/qualivisa/QualivisaQuestionResponseItem";
import {
  Equipment,
  QualivisaInformation,
  QualiVisaItemResponseDto,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import BasicButton from "@components/buttons/BasicButton";
import {
  requestGetQualivisaInformation,
  requestGetQualivisaStructure,
  requestUpdateQualivisa,
  requestUpdateRoomQualivisa,
} from "@state/qualivisa/QualivisaEffects";
import { setQualivisaStore } from "@state/qualivisa/QualivisaEvents";
import { useLocation, useNavigate } from "react-router-dom";
import { qualivisaUtils } from "@utils/qualivisa-utils";
import {
  ConformityAnswersDictionary,
  QualiVisaAnswersItem,
} from "@state/qualivisa/dto/quali.visa.save.request.dto";
import { ROUTES } from "@utils/Routes";
import { QualivisaFormStepper } from "@views/pro/gazProfessional/qualivisa/QualivisaFormStepper";
import { toastError } from "@utils/toast-helper";
import { QualivisaDetailScreen } from "@views/pro/gazProfessional/qualivisa/QualivisaDetailScreen";
import QualivisaEquipmentQuestion from "@components/qualivisa/QualivisaEquipmentQuestion";
import QualivisaTypologieQuestion from "@components/qualivisa/QualivisaTypologieQuestion";
import QualivisaPieceQuestion from "@components/qualivisa/QualivisaPieceQuestion";
import { QualivisaFormSteps } from "@utils/enums/qualivisa.enum";

export const QualivisaFormScreen: FC = () => {
  const { t } = useTranslation();
  const qualivisaInfo = useStore(qualivisaStore);
  const [selectedResponses, setSelectedResponses] = useState<ResponseDto[]>([]);
  const [previousSelectedResponses, setPreviousSelectedResponses] = useState<
    ResponseDto[]
  >([]);
  const [equipmentInPiece, setEquipmentInPiece] = useState<Equipment[]>([]);
  const [conformityAnswers, setConformityAnswers] =
    useState<ConformityAnswersDictionary>({});
  const location = useLocation();
  const navigate = useNavigate();
  const questionNumberPieceId = "e429dfc0-ec98-4e6d-bf13-8516bd216021";
  const formulaireId = location.pathname.split("/").pop() || "";

  const [firstQuestionResponses, setFirstQuestionResponses] =
    useState<QualiVisaItemResponseDto>(
      qualivisaInfo?.qualivisaItems?.filter((q) => q.first)[0],
    );
  const [qualivisa, setQualivisa] = useState<QualivisaInformation>();
  const [pieceNumber, setPieceNumber] = useState<number>(1);
  const [currentStep, setCurrentStep] = useState<string>(
    QualivisaFormSteps.INFORMATION_CHANTIER,
  );
  const [currentPiece, setCurrentPiece] = useState<number>(1);
  const [casePiece, setCasePiece] = useState<string>();
  const [namePiece, setNamePiece] = useState<string>();

  const fetchQualivisaStructure = useCallback(() => {
    requestGetQualivisaStructure({
      dto: { id: formulaireId, step: currentStep },
    }).catch(() => {
      toastError(t<string>("structure.error.loading"));
    });
  }, [currentStep, t]);

  const fetchQualivisaInformation = useCallback(() => {
    requestGetQualivisaInformation(formulaireId).catch(() => {
      toastError(t<string>("structure.error.loading"));
    });
  }, [formulaireId, t]);

  useEffect(() => {
    if (currentStep || currentPiece) {
      fetchQualivisaStructure();
      fetchQualivisaInformation();
    }
  }, [
    currentPiece,
    currentStep,
    fetchQualivisaInformation,
    fetchQualivisaStructure,
  ]);

  useEffect(() => {
    return requestGetQualivisaStructure.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setQualivisaStore({
          qualivisaItems: result.data.qualivisaItems,
          furtherInformation:
            result.data.furtherInformation ?? qualivisaInfo.furtherInformation,
          step: result.data.step,
        });
      }
    });
  });

  useEffect(() => {
    return requestGetQualivisaInformation.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setQualivisa(result.data);
      }
    });
  });

  useEffect(() => {
    const getFormResponse = (step: string) =>
      qualivisa?.formResponse?.find((s) => s.formStep === step)?.response;

    if (currentStep === QualivisaFormSteps.TYPOLOGIE_CHANTIER) {
      const formResponse = getFormResponse(
        QualivisaFormSteps.TYPOLOGIE_CHANTIER,
      );
      if (formResponse) {
        const extractKey =
          qualivisaUtils.extractSelectedResponseFromQualivisaResponseTypologie(
            formResponse,
          );
        setSelectedResponses(
          qualivisaUtils?.getResponseDtoWithFQKeys(
            extractKey,
            qualivisaInfo.qualivisaItems,
          ) ?? [],
        );
      }
    } else if (
      currentStep === QualivisaFormSteps.EQUIPEMENT_NEUF ||
      currentStep === QualivisaFormSteps.EQUIPEMENT_EXISTANT
    ) {
      const formResponse = getFormResponse(currentStep);
      if (formResponse) {
        setSelectedResponses(
          qualivisaUtils.extractSelectedResponseFromQualivisaResponseEquipment(
            formResponse,
            qualivisaInfo.qualivisaItems,
          ) ?? [],
        );
      }
    } else if (currentStep === QualivisaFormSteps.PIECES) {
      const formResponse = getFormResponse(QualivisaFormSteps.PIECES);
      if (formResponse) {
        setSelectedResponses(
          qualivisaUtils.extractSelectedResponseFromQualivisaResponsePiece(
            formResponse,
            qualivisaInfo.qualivisaItems,
            currentPiece,
          ) ?? [],
        );
      }
    }
  }, [
    currentPiece,
    currentStep,
    qualivisa?.formResponse,
    qualivisaInfo.qualivisaItems,
  ]);

  useEffect(() => {
    setFirstQuestionResponses(
      qualivisaInfo?.qualivisaItems?.filter((q) => q.first)[0],
    );
    setConformityAnswers({});
  }, [qualivisaInfo]);

  useEffect(() => {
    if (selectedResponses) {
      getCurrentQuestions();
    }
  }, []);

  const getItemSaveRequest = (response: ResponseDto) => {
    const quest = qualivisaUtils.getPreviousQuestion(
      response,
      qualivisaInfo.qualivisaItems,
    );
    return {
      questionId: quest?.question.fqKey,
      responseId: response.fqKey,
    } as QualiVisaAnswersItem;
  };

  const handleUpdateQualivisa = (room?: boolean): void => {
    setPreviousSelectedResponses([
      ...previousSelectedResponses,
      ...selectedResponses,
    ]);
    if (room) {
      void requestUpdateRoomQualivisa({
        dto: {
          selectedResponsesId: selectedResponses?.map((r) =>
            getItemSaveRequest(r),
          ),
          equipments: equipmentInPiece.map((e) => e.equipment),
          currentRoom: currentPiece,
          nbRoom: pieceNumber,
          pieceName: namePiece,
          conformityAnswers: conformityAnswers,
          casePiece: casePiece,
        },
        id: formulaireId,
      });
    } else if (
      pieceNumber === currentPiece &&
      currentStep === QualivisaFormSteps.PIECES
    ) {
      void requestUpdateRoomQualivisa({
        dto: {
          selectedResponsesId: selectedResponses?.map((r) =>
            getItemSaveRequest(r),
          ),
          equipments: equipmentInPiece.map((e) => e.equipment),
          currentRoom: currentPiece,
          nbRoom: pieceNumber,
          pieceName: namePiece,
          casePiece: casePiece,
          conformityAnswers: conformityAnswers,
        },
        id: formulaireId,
      });
    } else {
      void requestUpdateQualivisa({
        dto: {
          selectedResponsesId: selectedResponses?.map((r) =>
            getItemSaveRequest(r),
          ),
          conformityAnswers: conformityAnswers,
          step: currentStep,
        },
        id: formulaireId,
      });
    }
  };

  useEffect(() => {
    return requestUpdateQualivisa.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setQualivisaStore(result.data);
        setCurrentStep(result.data.step);
        setSelectedResponses([]);
      }
    });
  });

  useEffect(() => {
    return requestUpdateRoomQualivisa.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setCurrentPiece(currentPiece + 1);
        const responses = selectedResponses.filter(
          (selectedResponse) =>
            selectedResponse.previousQuestionId === questionNumberPieceId,
        );
        setSelectedResponses(responses);
        setQualivisaStore(result.data);
        if (
          pieceNumber === currentPiece &&
          currentStep === QualivisaFormSteps.PIECES
        ) {
          navigate(ROUTES.professional.qualivisa.recap.generate(formulaireId));
        }
      }
    });
  });

  useEffect(() => {
    if (currentPiece || currentStep) {
      setEquipmentInPiece(
        qualivisa?.formResponse?.filter(
          (s) => s.formStep === QualivisaFormSteps.PIECES,
        )[0]?.response != null
          ? qualivisaUtils.extractEquipmentInPieceFromQualivisaResponsePiece(
              qualivisa?.formResponse?.filter(
                (s) => s.formStep === QualivisaFormSteps.PIECES,
              )[0]?.response,
              qualivisaInfo.furtherInformation?.equipments,
              currentPiece,
            )
          : [],
      );
    }
  }, [
    currentPiece,
    currentStep,
    qualivisa?.formResponse,
    qualivisaInfo.furtherInformation?.equipments,
  ]);

  useEffect(() => {
    if (currentPiece) {
      setNamePiece(
        qualivisa?.formResponse?.filter(
          (s) => s.formStep === QualivisaFormSteps.PIECES,
        )[0]?.response != null
          ? qualivisaUtils.extractNamePieceFromQualivisaResponsePiece(
              qualivisa?.formResponse?.filter(
                (s) => s.formStep === QualivisaFormSteps.PIECES,
              )[0]?.response,
              currentPiece,
            )
          : undefined,
      );
    }
  }, [currentPiece, qualivisa?.formResponse]);

  const getCurrentQuestions = () => {
    const currentQuestions = selectedResponses.map((r) => {
      let nextQuestion = qualivisaUtils.getNextQuestion(
        r,
        qualivisaInfo.qualivisaItems,
      );
      if (
        selectedResponses?.filter(
          (s) => nextQuestion?.question.showIf === s?.fqKey,
        ).length > 0
      ) {
        nextQuestion = qualivisaUtils.getNextQuestion(
          nextQuestion?.responses[0],
          qualivisaInfo.qualivisaItems,
        );
      }

      if (nextQuestion?.question.type === "equipment") {
        const questionFqKey =
          nextQuestion.question.fqKey.length === 9
            ? nextQuestion.question.fqKey
            : nextQuestion.question.fqKey.slice(-9);

        nextQuestion.question.fqKey = questionFqKey;

        return {
          first: nextQuestion.first,
          question: {
            ...nextQuestion.question,
            fqKey: r.fqKey.slice(0, 9).concat("-", questionFqKey),
          },
          responses: nextQuestion.responses.map((resp) => {
            return {
              ...resp,
              fqKey: r.fqKey.slice(0, 9).concat("-", resp.fqKey),
            };
          }),
          step: nextQuestion.step,
        } as QualiVisaItemResponseDto;
      }
      return nextQuestion;
    });

    return qualivisaUtils
      .removeDuplicatesQuestions(currentQuestions)
      .sort(
        (a, b) =>
          parseInt(a.question.fqKey.slice(7, 9)) -
          parseInt(b.question.fqKey.slice(7, 9)),
      )
      .filter((q) => q?.question);
  };

  const currentResponses = getCurrentQuestions();

  const getEndOfTheStep = () => {
    const step = firstQuestionResponses?.step;
    if (
      step === QualivisaFormSteps.EQUIPEMENT_NEUF ||
      step === QualivisaFormSteps.EQUIPEMENT_EXISTANT
    ) {
      const isNoChoiceOnly =
        selectedResponses.some((s) => s?.label === "Aucun choix") &&
        selectedResponses.length === 1;
      if (isNoChoiceOnly) return true;

      const nbEquipmentEnd = selectedResponses.filter(
        (resp) => resp?.end,
      ).length;
      const listEquipmentPossibleIds = qualivisaInfo?.qualivisaItems
        .flatMap((i) => i.responses)
        .filter((r) => r.type === "EQUIPMENT")
        .map((i) => i.id);

      const nbEquipment = listEquipmentPossibleIds.filter((id) =>
        selectedResponses.some((s) => s?.id === id),
      ).length;

      return nbEquipment !== 0 && nbEquipmentEnd === nbEquipment;
    }

    if (casePiece === "C" && equipmentInPiece.length === 1) {
      return true;
    }

    return selectedResponses.some((s) => s?.end && s.nextQuestionId == null);
  };

  const endOfTheStep = getEndOfTheStep();

  const handleSelectResponses = (resp: ResponseDto, value?: number) => {
    const itemQuestionResponse = qualivisaUtils.getPreviousQuestion(
      resp,
      qualivisaInfo.qualivisaItems,
    );
    const { type, beginningLoop } = itemQuestionResponse.question;
    const baseResponse = {
      id: resp.id,
      previousQuestionId: resp.previousQuestionId,
      nextQuestionId: resp.nextQuestionId,
      fqKey: resp.fqKey.slice(0, 9),
      label: resp.label,
      type: resp.type,
      end: resp.end,
    };

    const addOrUpdateResponse = (newResp: ResponseDto): ResponseDto[] => {
      setSelectedResponses([newResp, ...selectedResponses]);
      return [newResp, ...selectedResponses];
    };
    const removeResponse = (response: ResponseDto): ResponseDto[] => {
      setSelectedResponses(
        qualivisaUtils.deleteChildResponses(selectedResponses, response),
      );
      return qualivisaUtils.deleteChildResponses(selectedResponses, response);
    };

    if (resp.label === "Aucun choix" && !selectedResponses.includes(resp)) {
      setSelectedResponses([resp]);
    } else if (type === "multiple") {
      if (beginningLoop) {
        const existing = selectedResponses.map((s) => s.id).includes(resp.id);
        const newSelectedResponses = existing
          ? removeResponse(resp)
          : addOrUpdateResponse({
              ...baseResponse,
              fqKey: `${resp.fqKey
                .slice(0, 9)
                .concat(
                  "-",
                  itemQuestionResponse.question.fqKey.slice(
                    itemQuestionResponse.question.fqKey.length - 9,
                    itemQuestionResponse.question.fqKey.length,
                  ),
                )}`,
            });
        if (
          currentStep === QualivisaFormSteps.EQUIPEMENT_NEUF ||
          currentStep === QualivisaFormSteps.EQUIPEMENT_EXISTANT
        ) {
          setSelectedResponses(
            newSelectedResponses.filter(
              (selectedResponse) => selectedResponse.label !== "Aucun choix",
            ),
          );
        }
      } else {
        const existing = selectedResponses.includes(resp);
        existing ? removeResponse(resp) : addOrUpdateResponse(resp);
      }
    } else if (type === "equipment") {
      if (beginningLoop) {
        const existItem = selectedResponses.filter((l) => {
          return (
            l.fqKey.slice(0, 9) === resp.fqKey.slice(0, 9) &&
            l.previousQuestionId === resp.previousQuestionId
          );
        });
        if (existItem.length < 1) {
          addOrUpdateResponse(resp);
        } else {
          setSelectedResponses([
            resp,
            ...qualivisaUtils.deleteChildForEquipmentResponses(
              selectedResponses,
              existItem[0],
            ),
          ]);
        }
      } else {
        const existing = selectedResponses.includes(resp);
        existing
          ? removeResponse(selectedResponses.filter((s) => s !== resp)[0])
          : addOrUpdateResponse(resp);
        setSelectedResponses(selectedResponses.filter((v) => v !== resp));
      }
    } else if (type === "input") {
      const filterResponses = selectedResponses.filter(
        (s) => s.id !== itemQuestionResponse.responses[0].id,
      );
      setSelectedResponses([
        {
          ...baseResponse,
          fqKey: `${resp.fqKey
            .slice(0, 9)
            .concat("-", value?.toString() ?? "")}`,
        },
        ...filterResponses,
      ]);
    } else {
      const existing = selectedResponses
        .map((selectedResponse) => selectedResponse?.previousQuestionId)
        .includes(resp.previousQuestionId);
      existing
        ? setSelectedResponses([
            resp,
            ...qualivisaUtils.deleteChildResponses(
              selectedResponses,
              selectedResponses.filter(
                (s) => s?.previousQuestionId === resp.previousQuestionId,
              )[0],
            ),
          ])
        : addOrUpdateResponse(resp);
    }
  };

  useEffect(() => {
    if (currentStep === QualivisaFormSteps.PIECES) {
      if (
        selectedResponses.filter((s) =>
          firstQuestionResponses?.responses.includes(s),
        )[0]?.label
      ) {
        setPieceNumber(
          parseInt(
            selectedResponses.filter((s) =>
              firstQuestionResponses?.responses.includes(s),
            )[0]?.label,
          ),
        );
      }
    }
  }, [currentStep, firstQuestionResponses?.responses, selectedResponses]);

  const getQuestionResponsesItem = useCallback(() => {
    const getQuestion = (step: string) => {
      switch (step) {
        case QualivisaFormSteps.TYPOLOGIE_CHANTIER:
          return (
            <QualivisaTypologieQuestion
              currentResponses={currentResponses}
              handleSelectResponse={handleSelectResponses}
              selectedResponses={selectedResponses}
            />
          );
        case QualivisaFormSteps.EQUIPEMENT_NEUF:
        case QualivisaFormSteps.EQUIPEMENT_EXISTANT:
          return (
            <QualivisaEquipmentQuestion
              currentResponses={currentResponses}
              handleSelectResponse={handleSelectResponses}
              selectedResponses={selectedResponses}
            />
          );
        case QualivisaFormSteps.PIECES:
          return (
            <QualivisaPieceQuestion
              currentResponses={currentResponses}
              handleSelectResponse={handleSelectResponses}
              selectedResponses={selectedResponses}
              setSelectedResponses={setSelectedResponses}
              equipmentInPiece={equipmentInPiece}
              setEquipmentInPiece={setEquipmentInPiece}
              setPieceName={setNamePiece}
              pieceName={namePiece}
              pieceNumber={currentPiece}
              setCase={setCasePiece}
            />
          );
        default:
          return null;
      }
    };
    return <>{getQuestion(qualivisaInfo?.step)}</>;
  }, [
    currentPiece,
    currentResponses,
    equipmentInPiece,
    handleSelectResponses,
    namePiece,
    qualivisaInfo?.step,
    selectedResponses,
  ]);

  useEffect(() => {
    getQuestionResponsesItem();
  }, [currentResponses, getQuestionResponsesItem]);

  return (
    <PageLayout
      container
      returnRoute={ROUTES.professional.qualivisa.list.generate()}
      title={t<string>("pro.qualivisa.form.title")}
      titleCentered={false}
      subtitle={t<string>("pro.qualivisa.form.description")}
      bodyClassName="app-qualivisa-form"
    >
      <>
        <QualivisaFormStepper
          currentStep={qualivisaInfo?.step}
          qualivisa={qualivisa}
          onClick={(currentStep: string) => {
            setCurrentStep(currentStep);
          }}
        />

        {qualivisa?.customerFirstname == null ||
        currentStep === QualivisaFormSteps.INFORMATION_CHANTIER ? (
          <>
            {qualivisa && (
              <QualivisaDetailScreen
                setCurrentStep={setCurrentStep}
                qualivisaDetail={qualivisa}
              />
            )}
          </>
        ) : (
          <>
            {firstQuestionResponses && (
              <QualivisaQuestionResponseItem
                handleSelectResponse={handleSelectResponses}
                qualivisaItem={firstQuestionResponses}
                selectedResponses={selectedResponses}
              />
            )}
            {getQuestionResponsesItem()}
            {endOfTheStep && (
              <>
                {endOfTheStep && (
                  <div className="d-flex justify-content-evenly">
                    {currentPiece !== 1 && (
                      <BasicButton
                        variant="primary"
                        text={t<string>("Piece précédente")}
                        onClick={() => setCurrentPiece(currentPiece - 1)}
                        className="mention-button"
                      />
                    )}
                    <BasicButton
                      variant="primary"
                      text={
                        pieceNumber !== currentPiece &&
                        currentStep === QualivisaFormSteps.PIECES
                          ? t<string>("Piece suivante")
                          : t<string>("Valider")
                      }
                      onClick={() =>
                        pieceNumber !== currentPiece &&
                        currentStep === QualivisaFormSteps.PIECES
                          ? handleUpdateQualivisa(true)
                          : handleUpdateQualivisa()
                      }
                      className="mention-button"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </PageLayout>
  );
};
