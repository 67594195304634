import React, { FunctionComponent, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

interface PdfViewerProps {
  url: string | any;
  className?: string;
  withData?: boolean;
}

const PdfViewer: FunctionComponent<PdfViewerProps> = ({
  url,
  className = "",
  withData = false,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage(e: React.MouseEvent<HTMLButtonElement>) {
    changePage(1);
    e.preventDefault();
  }

  return (
    <div className={`app-pdf-viewer ${className}`}>
      <div className="pdf-header">
        {`Page ${pageNumber || (numPages ? 1 : "--")}/${numPages ?? "--"}`}
      </div>
      <div className="pdf-container">
        <Document
          file={
            withData
              ? url
              : {
                  url: url,
                  withCredentials: true,
                }
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
      <div className="pdf-pagination">
        <div className="pagination-container d-flex align-items-stretch">
          <BasicButton
            icon={<LeftOutlined />}
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className="pagination-button"
          />
          <div className="d-flex align-items-center px-2">
            {`Page ${pageNumber || (numPages ? 1 : "--")}/${numPages ?? "--"}`}
          </div>
          <BasicButton
            icon={<RightOutlined />}
            disabled={!!numPages && pageNumber >= numPages}
            onClick={nextPage}
            className="pagination-button"
          />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
