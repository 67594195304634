/* eslint-disable */

export enum QualivisaFormSteps {
  INFORMATION_CHANTIER = "INFORMATION_CHANTIER",
  TYPOLOGIE_CHANTIER = "TYPOLOGIE_CHANTIER",
  EQUIPEMENT_NEUF = "EQUIPEMENT_NEUF",
  EQUIPEMENT_EXISTANT = "EQUIPEMENT_EXISTANT",
  PIECES = "PIECES",
}

export interface QualivisaFormStepsProperties {
  key: string;
  step: string;
  label: string;
  position: number;
}

export const qualivisaFormStepsProperties: Record<
  QualivisaFormSteps,
  QualivisaFormStepsProperties
> = {
  [QualivisaFormSteps.INFORMATION_CHANTIER]: {
    key: "INFORMATION_CHANTIER",
    step: "0",
    label: "pro.qualivisa.form.steps.INFORMATION_CHANTIER",
    position: 0,
  },
  [QualivisaFormSteps.TYPOLOGIE_CHANTIER]: {
    key: "TYPOLOGIE_CHANTIER",
    step: "1",
    label: "pro.qualivisa.form.steps.TYPOLOGIE_CHANTIER",
    position: 1,
  },
  [QualivisaFormSteps.EQUIPEMENT_NEUF]: {
    key: "EQUIPEMENT_NEUF",
    step: "2.1",
    label: "pro.qualivisa.form.steps.EQUIPEMENT_NEUF",
    position: 2,
  },
  [QualivisaFormSteps.EQUIPEMENT_EXISTANT]: {
    key: "EQUIPEMENT_EXISTANT",
    step: "2.2",
    label: "pro.qualivisa.form.steps.EQUIPEMENT_EXISTANT",
    position: 3,
  },

  [QualivisaFormSteps.PIECES]: {
    key: "PIECES",
    step: "3",
    label: "pro.qualivisa.form.steps.PIECES",
    position: 4,
  },
};

export const QUALIVISA_FORM_STEPS = Object.keys(QualivisaFormSteps)
  .map((key) => key as QualivisaFormSteps)
  .map((key) => ({
    key: key,
    label: qualivisaFormStepsProperties[key].label,
    step: qualivisaFormStepsProperties[key].step,
    position: qualivisaFormStepsProperties[key].position,
  })) as QualivisaFormStepsProperties[];
/* eslint-enable */

export const QUALIVISA_ANOMALY_LEVEL: { [index: string]: AnomalyLevelType } = {
  DGI: {
    key: "DGI",
    label: "DGI",
    variant: "danger",
  },
  A1: {
    key: "A1",
    variant: "default",
    label: "A1",
  },
  A2: {
    key: "A2",
    variant: "warning",
    label: "A2",
  },
  OBSERVATION: {
    key: "Observation",
    variant: "transparent",
    label: "OBSERVATION",
  },
  DEFAULT: {
    key: "DEFAULT",
    variant: "default",
    label: "/",
  },
};

interface AnomalyLevelType {
  key: string;
  variant: string;
  label: string;
}
