import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  QUALIVISA_FORM_STEPS,
  QualivisaFormSteps,
} from "@utils/enums/qualivisa.enum";
import { Link } from "react-router-dom";
import { QualivisaInformation } from "@state/qualivisa/dto/quali.visa.item.response.dto";
import { qualivisaUtils } from "@utils/qualivisa-utils";

interface QualivisaFormStepperProps {
  currentStep: string;
  onClick: (step: string) => void;
  qualivisa?: QualivisaInformation;
}

export const QualivisaFormStepper: FC<QualivisaFormStepperProps> = ({
  currentStep,
  onClick,
  qualivisa,
}) => {
  const { t } = useTranslation();
  const [stepCompleted, setStepCompleted] = useState<number[]>();
  const [mostAdvancedStep, setMostAdvancedStep] = useState<string>(
    QualivisaFormSteps.INFORMATION_CHANTIER,
  );

  useEffect(() => {
    setStepCompleted([
      ...(qualivisa?.formResponse?.map(
        (response) =>
          QUALIVISA_FORM_STEPS.find((step) => step.key === response.formStep)
            ?.position ?? 0,
      ) || []),
      QUALIVISA_FORM_STEPS.find((step) => step.step === "0")?.position ?? 0,
    ]);
    qualivisa
      ? setMostAdvancedStep(qualivisaUtils.getMostAdvancedStep(qualivisa))
      : setMostAdvancedStep(QualivisaFormSteps.INFORMATION_CHANTIER);
  }, [qualivisa, qualivisa?.formResponse]);

  const currentStepIndex =
    QUALIVISA_FORM_STEPS.find((step) => step.key === currentStep)?.position ??
    0;

  const mostAdvancedStepIndex =
    QUALIVISA_FORM_STEPS.find((step) => step.key === mostAdvancedStep)
      ?.position ?? 0;

  const mostAdvancedStepIndexInProgress = mostAdvancedStepIndex + 1;

  const isClickable = (index: number) => {
    return (
      index < mostAdvancedStepIndexInProgress &&
      index !== currentStepIndex &&
      stepCompleted?.includes(index)
    );
  };

  const getStepStyle = (currentStepIndex: number, index: number) => {
    if (isClickable(index)) {
      return "previous-step";
    } else if (index === currentStepIndex) {
      return "active-step";
    } else return "next-step";
  };

  const onSelect = (step: string) => {
    return onClick(step);
  };

  return (
    <div className="app-qualivisa-form-stepper">
      <div className="qualivisa-form-stepper-container">
        {t<string>("pro.qualivisa.form.steps.title")}
        {QUALIVISA_FORM_STEPS.map((step, index) => (
          <div key={index.valueOf()}>
            {index < mostAdvancedStepIndexInProgress && isClickable(index) ? (
              <Link className="link-without-underline" to="#">
                <div
                  key={step.key}
                  className={`qualivisa-form-stepper-step ${getStepStyle(
                    currentStepIndex,
                    index,
                  )}`}
                  onClick={() => onSelect(step.key)}
                >
                  {t<string>(step.label)}
                </div>
              </Link>
            ) : (
              <div
                key={step.key}
                className={`qualivisa-form-stepper-step ${getStepStyle(
                  currentStepIndex,
                  index,
                )}`}
              >
                {t<string>(step.label)}
              </div>
            )}
          </div>
        ))}
      </div>
      <h2>{t<string>(QUALIVISA_FORM_STEPS[currentStepIndex]?.label)}</h2>
    </div>
  );
};
