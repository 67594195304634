import { FC } from "react";
import { useTranslation } from "react-i18next";
import { QualiVisaRecapResponseItemDto } from "@state/qualivisa/dto/quali.visa.item.response.dto";

interface QualivisaRecapTypologieScreenProps {
  recap: QualiVisaRecapResponseItemDto;
}

export const QualivisaRecapTypologie: FC<
  QualivisaRecapTypologieScreenProps
> = ({ recap }) => {
  const { t } = useTranslation();

  const groupedByQuestion = (recap?.items ?? []).reduce((acc, item) => {
    const key = item.questionLabel;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item.responseLabel);
    return acc;
  }, {} as Record<string, string[]>);

  return (
    <div className="qualivisa-recap-body-item">
      <h3 className="qualivisa-recap-body-item-label">{recap.label}</h3>
      <p className="qualivisa-recap-body-item-content">
        {Object.entries(groupedByQuestion).map(
          ([questionLabel, responseLabels]) => (
            <div key={questionLabel}>
              <div className="font-weight-bold mt-3">
                {t<string>(questionLabel)}
              </div>
              <ul>
                {responseLabels.map((responseLabel, index) => (
                  <li key={index}>{t<string>(responseLabel)}</li>
                ))}
              </ul>
            </div>
          ),
        )}
      </p>
    </div>
  );
};
