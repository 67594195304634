import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import {
  InstallationItem,
  ProgrammingAuditItem,
} from "@state/audit/dto/audit.item.details.dto";
import { requestGetInstallationDetail } from "@state/audit/AuditEffects";
import { useTranslation } from "react-i18next";
import { auditUtils } from "@utils/audit-utils";
import parse from "html-react-parser";
import { addressUtils } from "@utils/address-utils";

interface AuditBlockDetailModalProps {
  title: string;
  open: boolean;
  onCancel: () => void;
  className?: string;
  programmingAuditItems?: ProgrammingAuditItem[];
}

const AuditBlockDetailModal: FunctionComponent<AuditBlockDetailModalProps> = ({
  title,
  open,
  onCancel,
  className = "",
  programmingAuditItems,
}) => {
  const { t } = useTranslation();
  const [auditInstallations, setAuditInstallations] = useState<
    InstallationItem[]
  >([]);
  useEffect(() => {
    if (programmingAuditItems != null && open) {
      setAuditInstallations([]);
      const installationToDetailId = programmingAuditItems?.map(
        (item) => item.ccId,
      );
      installationToDetailId?.forEach((installation) => {
        void requestGetInstallationDetail(installation);
      });
    }
  }, [programmingAuditItems, open]);

  useEffect(() => {
    return requestGetInstallationDetail.done.watch(({ result }) => {
      if (result.ok && result.data && open) {
        const installationDetail = result.data;
        setAuditInstallations((installation) => [
          {
            id: installationDetail.id,
            installationId: installationDetail.installationId,
            ocId: installationDetail.ocId,
            ocCcId: installationDetail.ocCcId,
            nameRg: installationDetail.nameRg,
            address1: installationDetail.address1,
            address2: installationDetail.address2,
            address3: installationDetail.address3,
            address4: installationDetail.address4,
            city: installationDetail.city,
            zipCode: installationDetail.zipCode,
            dateOfRegistration: installationDetail.dateOfRegistration,
          },
          ...installation,
        ]);
      }
    });
  });

  return (
    <>
      <BasicModal
        title={title}
        open={open}
        onCancel={onCancel}
        className={`${className}`}
        footer={null}
      >
        <>
          <div className="audit-detail-installations-modal">
            <div className="audit-detail-installations-items">
              {auditInstallations?.map((installation, index) => (
                <div key={index} className="audit-detail-installations-item">
                  <div className="audit-detail-installations-item-choice">{`${t<string>(
                    "pro.audit.detail.installations.items.choice",
                  )} ${index + 1}`}</div>
                  <div className="audit-detail-installations-item-name">
                    {auditUtils.renderIconOC(installation.ocId)}
                  </div>
                  <div className="audit-detail-installations-item-number">{`${t<string>(
                    "pro.audit.detail.installations.items.number",
                  )} ${installation.ocCcId}`}</div>
                  <div className="audit-detail-installations-item-rgi">
                    {t<string>("pro.audit.detail.installations.items.rgi", {
                      name: installation.nameRg,
                    })}
                  </div>
                  <div className="audit-detail-installations-item-address">
                    <div>
                      {installation &&
                        parse(addressUtils.getAddress(installation))}
                    </div>
                    <div>
                      {installation?.zipCode
                        .concat(" ")
                        .concat(installation?.city)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      </BasicModal>
    </>
  );
};

export default AuditBlockDetailModal;
