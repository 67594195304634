/* eslint-disable @typescript-eslint/naming-convention */
export const CATEGORY_CONFORMITY_QUESTION: { [index: string]: EnumType } = {
  TUYAUTERIE_FIXE: {
    value: "TUYAUTERIE_FIXE",
    label: "Tuyauterie fixe",
  },
  ORGANE_COUPURE: {
    value: "ORGANE_COUPURE",
    label: "Organe de coupure",
  },
  LYRE_GPL: {
    value: "LYRE_GPL",
    label: "GPL et Lyre GPL",
  },
  APPAREILS_ALIMENTATION_GAZ: {
    value: "APPAREILS_ALIMENTATION_GAZ",
    label: "Appareil et alimentation en gaz",
  },
  ALIMENTATION_GAZ_APPAREILS: {
    value: "ALIMENTATION_GAZ_APPAREILS",
    label: "Alimentation en gaz des appareils",
  },
  ETANCHEITE: {
    value: "ETANCHEITE",
    label: "Etanchéité de l’installation",
  },
  VENTILATION_A: {
    value: "VENTILATION_A",
    label:
      "Ventilation des locaux contenant des appareils à gaz  : Appareil non raccordé seul (type A)",
  },
  VENTILATION_B: {
    value: "VENTILATION_B",
    label:
      "Ventilation des locaux contenant des appareils à gaz : Appareil raccordé seul (type B)",
  },
  VENTILATION_A_B: {
    value: "VENTILATION_A_B",
    label:
      "Ventilation des locaux contenant des appareils à gaz : Appareil non raccordé (type A) + appareil raccordé (type B)",
  },
  VENTILATION_B_VMC: {
    value: "VENTILATION_B_VMC",
    label:
      "Ventilation des locaux contenant des appareils à gaz : Appareil raccordé seul (type B) sur VMC gaz",
  },
  VENTILATION_A_B_VMC: {
    value: "VENTILATION_A_B_VMC",
    label:
      "Ventilation des locaux contenant des appareils à gaz : Appareil non raccordé (type A) + appareil raccordé (type B) sur VMC gaz",
  },
  EVACUATION_COMBUSTION_B_TIRAGE_NAT: {
    value: "EVACUATION_COMBUSTION_B_TIRAGE_NAT",
    label:
      "Evacuation des produits de combustion: Appareil de type B en tirage naturel",
  },
  EVACUATION_COMBUSTION_B_PRESSION: {
    value: "EVACUATION_COMBUSTION_B_PRESSION",
    label:
      "Evacuation des produits de combustion: Appareil de type B en pression",
  },
  EVACUATION_COMBUSTION_B_VMC: {
    value: "EVACUATION_COMBUSTION_B_VMC",
    label: "Evacuation des produits de combustion: Appareil de type B VMC gaz",
  },
  EVACUATION_COMBUSTION_C: {
    value: "EVACUATION_COMBUSTION_C",
    label: "Evacuation des produits de combustion: Appareil de type C étanche",
  },
  CENR: {
    value: "CENR",
    label: "CENR",
  },
  ALIMENTATION_TIGE_CUISINE: {
    value: "ALIMENTATION_TIGE_CUISINE",
    label: "Alimentation par tige cuisine",
  },
  SPE: {
    value: "SPE",
    label: "SPE",
  },
  ESSAIS_APPAREILS_CUISSON: {
    value: "ESSAIS_APPAREILS_CUISSON",
    label: "Essais des appareils : Appareils de cuisson (sur feu uniquement)",
  },
  ESSAIS_CENR: {
    value: "ESSAIS_CENR",
    label: "Essais des appareils : CENR",
  },
  ESSAIS_B: {
    value: "ESSAIS_B",
    label: "Essais des appareils : Appareils raccordés (type B uniquement)",
  },
  PRODUITS_COMBUSTION: {
    value: "PRODUITS_COMBUSTION",
    label: "Produits de combustion (Type B et type C)",
  },
};

const equipmentMapper = [
  {
    value: "fq_r_2.01",
    shortLabel: "Chaudière",
    label: "Chaudière neuve",
  },
  {
    value: "fq_r_2.02",
    shortLabel: "Appareil de cuisson",
    label: "Appareil de cuisson neuf",
  },
  {
    value: "fq_r_2.04",
    shortLabel: "Organe de coupure d’appareil",
    label: "Organe de coupure d’appareil neuf",
  },

  {
    value: "fq_r_2.06",
    shortLabel: "Chauffe-bains gaz",
    label: "Chauffe-bains neuf",
  },
  {
    value: "fq_r_2.07",
    shortLabel: "Chauffe-eau non raccordé",
    label: "Chauffe-eau non raccordé neuf",
  },
  {
    value: "fq_r_2.08",
    shortLabel: "Poêle et insert gaz",
    label: "Poêle et insert gaz neuve",
  },
  {
    value: "fq_r_2.09",
    shortLabel: "Radiateur",
    label: "Radiateur neuf",
  },
  {
    value: "fq_r_2.10",
    shortLabel: "Accumulateur d’eau chaude",
    label: "Accumulateur d’eau chaude neuf",
  },
  {
    value: "fq_r_2.34",
    shortLabel: "Chaudière",
    label: "Chaudière existante",
  },
  {
    value: "fq_r_2.35",
    shortLabel: "Appareil de cuisson",
    label: "Appareil de cuisson existant",
  },
  {
    value: "fq_r_2.37",
    shortLabel: "Organe de coupure d’appareil",
    label: "Organe de coupure d’appareil existant",
  },
  {
    value: "fq_r_2.39",
    shortLabel: "Chauffe-bains gaz",
    label: "Chauffe-bains existant",
  },
  {
    value: "fq_r_2.40",
    shortLabel: "Chauffe-eau non raccordé",
    label: "Chauffe-eau non raccordé existant",
  },
  {
    value: "fq_r_2.41",
    shortLabel: "Poêle et insert gaz",
    label: "Poêle et insert gaz existante",
  },
  {
    value: "fq_r_2.42",
    shortLabel: "Radiateur",
    label: "Radiateur existant",
  },
  {
    value: "fq_r_2.43",
    shortLabel: "Accumulateur d’eau chaude",
    label: "Accumulateur d’eau chaude existant",
  },
];

const pieceNumberMapper = [
  {
    value: "fq_r_3.01",
    label: 1,
  },
  {
    value: "fq_r_3.02",
    label: 2,
  },
  {
    value: "fq_r_3.03",
    label: 3,
  },
  {
    value: "fq_r_3.04",
    label: 4,
  },
  {
    value: "fq_r_3.05",
    label: 5,
  },
];

const typeMapper = [
  {
    value: "fq_r_2.12",
    label: "Type B",
  },
  {
    value: "fq_r_2.13",
    label: "Type C",
  },
  {
    value: "fq_r_2.17",
    label: "Type A",
  },
  {
    value: "fq_r_2.18",
    label: "Type A",
  },
  {
    value: "fq_r_2.21",
    label: "Type A",
  },
  {
    value: "fq_r_2.22",
    label: "Type B",
  },
  {
    value: "fq_r_2.23",
    label: "Type C",
  },
  {
    value: "fq_r_2.29",
    label: "Type B",
  },
  {
    value: "fq_r_2.30",
    label: "Type C",
  },
  {
    value: "fq_r_2.44",
    label: "Type B",
  },
  {
    value: "fq_r_2.45",
    label: "Type C",
  },
  {
    value: "fq_r_2.49",
    label: "Type A",
  },
  {
    value: "fq_r_2.50",
    label: "Type A",
  },
  {
    value: "fq_r_2.53",
    label: "Type A",
  },
  {
    value: "fq_r_2.54",
    label: "Type B",
  },
  {
    value: "fq_r_2.55",
    label: "Type C",
  },
  {
    value: "fq_r_2.61",
    label: "Type B",
  },
  {
    value: "fq_r_2.62",
    label: "Type C",
  },
];

const evacuationTypeMapper = [
  {
    value: "fq_r_2.14",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.15",
    label: "Pression",
  },
  {
    value: "fq_r_2.16",
    label: "VMC gaz",
  },
  {
    value: "fq_r_2.26",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.27",
    label: "Pression",
  },
  {
    value: "fq_r_2.28",
    label: "VMC gaz",
  },
  {
    value: "fq_r_2.31",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.32",
    label: "Pression",
  },
  {
    value: "fq_r_2.33",
    label: "VMC gaz",
  },
  {
    value: "fq_r_2.46",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.47",
    label: "Pression",
  },
  {
    value: "fq_r_2.48",
    label: "VMC gaz",
  },
  {
    value: "fq_r_2.58",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.59",
    label: "Pression",
  },
  {
    value: "fq_r_2.60",
    label: "VMC gaz",
  },
  {
    value: "fq_r_2.63",
    label: "Tirage Naturel",
  },
  {
    value: "fq_r_2.64",
    label: "Pression",
  },
  {
    value: "fq_r_2.65",
    label: "VMC gaz",
  },
];

export interface EnumType {
  value: string;
  label: string;
  shortLabel?: string;
}

export const qualivisaMapper = {
  pieceNumberMapper,
  typeMapper,
  equipmentMapper,
  evacuationTypeMapper,
};
