import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "@views/home/HomeScreen";
import "./styles/styles.scss";
import "./utils/i18n/i18n";
import LoginScreen from "@views/auth/LoginScreen";
import AppInfosRibbon from "@components/ribbons/AppInfosRibbon";
import ForgotPasswordScreen from "@views/auth/ForgotPasswordScreen";
import InitPasswordScreen from "@views/auth/InitPasswordScreen";
import RecoverPasswordScreen from "@views/auth/RecoverPasswordScreen";
import RegistrationScreen from "@views/auth/RegistrationScreen";
import { PageScreen } from "@views/page/PageScreen";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { SearchScreen } from "@views/search/SearchScreen";
import { UNIVERSES } from "@utils/enums/universe.enum";
import GazProfessionalDetailsScreen from "@views/gazprofessional/GazProfessionalDetailsScreen";
import { ContactsListScreen } from "@views/pro/gazProfessional/contact/ContactsListScreen";
import { ContactDetailScreen } from "@views/pro/gazProfessional/contact/ContactDetailScreen";
import SummaryScreen from "@views/summary/SummaryScreen";
import PersonalDataScreen from "@views/pro/gazProfessional/account/PersonalDataScreen";
import Error404Screen from "@views/error404/Error404Screen";
import MaintenanceScreen from "@views/maintenance/MaintenanceScreen";
import ProHomeScreen from "@views/home/ProHomeScreen";
import WrongIdScreen from "@views/auth/WrongIdScreen";
import TagManager from "react-gtm-module";
import { PagePreviewScreen } from "@views/page/PagePreviewScreen";
import LoginOrganisationProfessionalScreen from "@views/auth/LoginOrganisationProfessionalScreen";
import { PacHybrideMentionRenewalScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionRenewalScreen";
import { ToolsGazProfessionalScreen } from "@views/pro/gazProfessional/tools/ToolsGazProfessionalScreen";
import GazMonthMaintenanceScreen from "@views/maintenance/GazMonthMaintenanceScreen";
import configuration, { ENV_PRODUCTION } from "@utils/Config";
import { SmokeDuctMentionScreen } from "@views/pro/gazProfessional/mentions/smoke-duct/SmokeDuctMentionScreen";
import { SmokeDuctMentionFormScreen } from "@views/pro/gazProfessional/mentions/smoke-duct/SmokeDuctMentionFormScreen";
import { SmokeDuctMentionIframeScreen } from "@views/pro/gazProfessional/mentions/smoke-duct/SmokeDuctMentionIframeScreen";
import { WorksiteFormScreen } from "@views/pro/gazProfessional/worksite/WorksiteFormScreen";
import { WorksiteDetailScreen } from "@views/pro/gazProfessional/worksite/WorksiteDetailScreen";
import RentreeDuChauffageMaintenanceScreen from "@views/maintenance/RentreeDuChauffageMaintenanceScreen";
import { MailsListScreen } from "@views/pro/gazProfessional/mail/MailsListScreen";
import { QualificationsScreen } from "@views/pro/gazProfessional/qualifications/QualificationsScreen";
import { PGSubscribersOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/pgSubscribers/PGSubscribersOrganisationProfessionalScreen";
import { RGOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/rg/RGOrganisationProfessionalScreen";
import { MailsOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/mails/opMails/MailsOrganisationProfessionalScreen";
import { RequestsOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/requests/RequestsOrganisationProfessionalScreen";
import IndividualGazMonthScreen from "@views/gazMonth/IndividualGazMonthScreen";
import IndividualGazMonthConfirmationScreen from "@views/gazMonth/IndividualGazMonthConfirmationScreen";
import { MyContextProvider } from "./MyContext";
import UniverseLayout from "@components/routes/UniverseLayout";
import { ROUTES } from "@utils/Routes";
import AuthLayout from "@components/routes/AuthLayout";
import { useStructures } from "@state/structure/Structures";
import { MailsProfessionalGazScreen } from "@views/pro/organisationProfessional/mails/pgMails/MailsProfessionalGazScreen";
import { ContactFormScreen } from "@views/pro/gazProfessional/contact/ContactFormScreen";
import GazProfessionalHomeScreen from "@views/pro/gazProfessional/GazProfessionalHomeScreen";
import { PacHybrideMentionScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionScreen";
import { PacHybrideMentionFormScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionFormScreen";
import { DetailsPGSubscribersOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/pgSubscribers/details/DetailsPGSubscribersOrganisationProfessionalScreen";
import AuthOPLayout from "@components/routes/AuthOPLayout";
import { ToolsOrganisationProfessionalScreen } from "@views/pro/organisationProfessional/tools/ToolsOrganisationProfessionalScreen";
import { PacHybrideMentionIframeScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionIframeScreen";
import { requestGetGazProfessionalHabitAPlusMails } from "@state/gazprofessional/GazProfessionalEffects";
import {
  setMailLoading,
  setOpMails,
  setPgMails,
} from "@state/mailprofessional/MailProfessionalEvents";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { Pageable } from "@type/pagination/pagination.types";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import { requestGetOrganisationProfessionalHabitAPlusMails } from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";
import { ContactsContext } from "@views/pro/gazProfessional/contact/ContactsContext";
import { AuditsListScreen } from "@views/pro/gazProfessional/audit/AuditsListScreen";
import { AuditDetailScreen } from "@views/pro/gazProfessional/audit/AuditDetailScreen";
import { GreenGasScreen } from "@views/pro/gazProfessional/greenGas/GreenGasScreen";
import GazEventScreen from "@views/pro/gazProfessional/gazEvent/GazEventScreen";
import LandingPageTempsFortScreen from "@views/rentreeDuChauffage/tempsFort/LandingPageTempsFortScreen";
import LandingPageTempsFortConfirmationScreen from "@views/rentreeDuChauffage/tempsFort/LandingPageTempsFortConfirmationScreen";
import ToastAlert from "@components/alerts/ToastAlert";
import { MaintenancesCertificateListScreen } from "@views/pro/gazProfessional/maitenanceCertificate/MaintenancesCertificateListScreen";
import { MaintenanceCertificateDetailScreen } from "@views/pro/gazProfessional/maitenanceCertificate/MaintenanceCertificateDetailScreen";
import { MaintenanceCertificateAddScreen } from "@views/pro/gazProfessional/maitenanceCertificate/MaintenanceCertificateAddScreen";
import LandingPageFilRougeScreen from "@views/rentreeDuChauffage/filRouge/LandingPageFilRougeScreen";
import LandingPageFilRougeConfirmationScreen from "@views/rentreeDuChauffage/filRouge/LandingPageFilRougeConfirmationScreen";
import TertiaryModal from "@components/modals/tertiary/TertiaryModal";
import { QualivisaListScreen } from "@views/pro/gazProfessional/qualivisa/QualivisaListScreen";
import { QualivisaFormScreen } from "@views/pro/gazProfessional/qualivisa/QualivisaFormScreen";
import { QualivisaRecapScreen } from "@views/pro/gazProfessional/qualivisa/recap/QualivisaRecapScreen";

export const App: FC = () => {
  const authentifiedContext = useStore(authentifiedStore);
  const structure = useStructures();
  const mainCategories = structure.universe.filter(
    (categorie) => categorie.type === "CATEGORY",
  );
  mainCategories.forEach((cat) =>
    cat.children
      .filter((c) => c.type === "CATEGORY")
      .forEach((s) => mainCategories.push(s)),
  );
  const isGazEvent = configuration.isGazEvent;
  const gazEventType = configuration.gazEventType;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        cible: authentifiedContext.universe.urlValue,
      },
    });
  }, [authentifiedContext.universe]);

  useEffect(() => {
    if (authentifiedContext.user?.id) {
      setMailLoading(true);
      if ("email" in authentifiedContext.user) {
        // PG
        void requestGetGazProfessionalHabitAPlusMails({
          page: 0,
          limit: 20,
          sorter: {
            columnKey: "date",
            order: "descend",
          },
          dto: { query: "" },
        })
          .then(
            (
              result: ParsedResponse<
                Pageable<GazProfessionalHabitAPlusMailsResponseDto>
              >,
            ) => {
              if (result.ok && result.data) {
                setPgMails(result.data);
              } else {
                setMailLoading(false);
              }
            },
          )
          .catch(() => setMailLoading(false));
      } else {
        // OP
        void requestGetOrganisationProfessionalHabitAPlusMails({
          page: 0,
          limit: 10,
          sorter: {
            columnKey: "date",
            order: "descend",
          },
          dto: { query: "" },
        })
          .then(
            (
              result: ParsedResponse<
                Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>
              >,
            ) => {
              if (result.ok && result.data) {
                setOpMails(result.data);
              } else {
                setMailLoading(false);
              }
            },
          )
          .catch(() => setMailLoading(false));
      }
    }
  }, [authentifiedContext.user?.id]);

  return (
    <div
      className={`global ${authentifiedContext.universe.value.toLowerCase()}`}
    >
      {configuration.environment !== ENV_PRODUCTION && <AppInfosRibbon />}
      <ToastAlert />
      <MyContextProvider>
        <ContactsContext />
        <Routes>
          {mainCategories.map((cat) => (
            <Route
              key={cat.key}
              path={ROUTES.public.summary.generate(cat.seoFriendlyTitle)}
              element={<SummaryScreen />}
            />
          ))}
          <Route
            path={ROUTES.public.actualites.generate(
              authentifiedContext.universe,
            )}
            element={<SummaryScreen />}
          />

          {/*Professionnels du gaz*/}
          <Route
            path={ROUTES.public.gazProfessionals.details.generate(":yoocanId")}
            element={<GazProfessionalDetailsScreen />}
          />
          <Route
            path={ROUTES.public.preview.generate()}
            element={<PagePreviewScreen />}
          />

          {/*Rentrée du chauffage*/}
          <Route
            path={ROUTES.public.rentreeChauffage.maintenance.generate()}
            element={<RentreeDuChauffageMaintenanceScreen />}
          />

          {/*Mois du gaz*/}
          <Route
            path={ROUTES.public.gazMonth.maintenance.generate()}
            element={<GazMonthMaintenanceScreen />}
          />

          <Route
            path={ROUTES.public.pageFooter.generate()}
            element={<PageScreen />}
          />

          {/*****************INDIVIDUAL*****************/}
          <Route element={<UniverseLayout universe={UNIVERSES.INDIVIDUAL} />}>
            <Route
              path={ROUTES.individual.gazProfessionals.search.generate()}
              element={<SearchScreen />}
            />
            <Route path="/" element={<HomeScreen />} />
            <Route
              path={ROUTES.individual.publicHome.generate()}
              element={<HomeScreen />}
            />
            {isGazEvent && gazEventType === "gazMonth" && (
              <>
                <Route
                  path={ROUTES.individual.gazMonth.landingPage.generate()}
                  element={<IndividualGazMonthScreen />}
                />
                <Route
                  path={ROUTES.individual.gazMonth.confirmation.generate()}
                  element={<IndividualGazMonthConfirmationScreen />}
                />
              </>
            )}
            <Route
              path={ROUTES.individual.rentreeChauffage.filRouge.landingPage.generate()}
              element={<LandingPageFilRougeScreen />}
            />
            <Route
              path={ROUTES.individual.rentreeChauffage.filRouge.confirmation.generate()}
              element={<LandingPageFilRougeConfirmationScreen />}
            />
            {/*<Route*/}
            {/*  path={ROUTES.individual.rentreeChauffage.tempsFortV1.landingPage.generate()}*/}
            {/*  element={<IndividualRentreeDuChauffageTempsFortV2Screen />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={ROUTES.individual.rentreeChauffage.tempsFortV1.confirmation.generate()}*/}
            {/*  element={*/}
            {/*    <LandingPageTempsFortConfirmationScreen />*/}
            {/*  }*/}
            {/*/>*/}
            <Route
              path={ROUTES.individual.rentreeChauffage.tempsFort.landingPage.generate()}
              element={<LandingPageTempsFortScreen />}
            />
            <Route
              path={ROUTES.individual.rentreeChauffage.tempsFort.confirmation.generate()}
              element={<LandingPageTempsFortConfirmationScreen />}
            />
            <Route
              path={ROUTES.public.pageIndividual.generate()}
              element={<PageScreen />}
            />
          </Route>

          {/*****************PROFESSIONAL*****************/}
          <Route element={<UniverseLayout universe={UNIVERSES.PROFESSIONAL} />}>
            <Route
              path={ROUTES.professional.publicHome.generate()}
              element={<ProHomeScreen />}
            />
            <Route
              path={ROUTES.public.pagePro.generate()}
              element={<PageScreen />}
            />

            {/*Auth*/}
            <Route
              path={ROUTES.professional.auth.login.generate()}
              element={<LoginScreen />}
            />
            <Route
              path={ROUTES.professional.auth.loginOp.generate()}
              element={<LoginOrganisationProfessionalScreen />}
            />
            <Route
              path={ROUTES.professional.auth.forgotPassword.generate()}
              element={<ForgotPasswordScreen />}
            />
            <Route
              path={ROUTES.professional.auth.initPassword.generate()}
              element={<InitPasswordScreen />}
            />
            <Route
              path={ROUTES.professional.auth.recoverPassword.generate()}
              element={<RecoverPasswordScreen />}
            />
            <Route
              path={ROUTES.professional.auth.registration.generate()}
              element={<RegistrationScreen />}
            />

            {/*Contact BO*/}
            <Route
              path={ROUTES.professional.contacts.publicForm.generate()}
              element={<ContactFormScreen />}
            />

            <Route element={<AuthLayout />}>
              <Route
                path={ROUTES.professional.connectedHome.generate()}
                element={<GazProfessionalHomeScreen />}
              />

              {/*Contacts*/}
              <Route
                path={ROUTES.professional.contacts.list.generate()}
                element={<ContactsListScreen />}
              />
              <Route
                path={ROUTES.professional.contacts.details.generate(":id")}
                element={<ContactDetailScreen />}
              />

              {/*Attestation d'entretien*/}
              <Route
                path={ROUTES.professional.maintenanceCertificate.list.generate()}
                element={<MaintenancesCertificateListScreen />}
              />
              <Route
                path={ROUTES.professional.maintenanceCertificate.add.generate()}
                element={<MaintenanceCertificateAddScreen />}
              />
              <Route
                path={ROUTES.professional.maintenanceCertificate.details.generate(
                  ":id",
                )}
                element={<MaintenanceCertificateDetailScreen />}
              />

              {/*Qualification PG*/}
              <Route
                path={ROUTES.professional.qualifications.generate()}
                element={<QualificationsScreen />}
              />

              {/*Mail OP*/}
              <Route
                path={ROUTES.professional.mailOP.generate()}
                element={<MailsListScreen />}
              />

              {/*Audits*/}
              <Route
                path={ROUTES.professional.audits.list.generate()}
                element={<AuditsListScreen />}
              />
              <Route
                path={ROUTES.professional.audits.details.generate(":id")}
                element={<AuditDetailScreen />}
              />

              {/*Pac Hybride*/}
              <Route
                path={ROUTES.professional.pacsHybrides.mention.generate()}
                element={<PacHybrideMentionScreen />}
              />
              <Route
                path={ROUTES.professional.pacsHybrides.form.generate()}
                element={<PacHybrideMentionFormScreen />}
              />
              <Route
                path={ROUTES.professional.pacsHybrides.iframe.generate()}
                element={<PacHybrideMentionIframeScreen />}
              />
              <Route
                path={ROUTES.professional.pacsHybrides.renewal.generate()}
                element={<PacHybrideMentionRenewalScreen />}
              />

              {/*Smoke Duct*/}
              <Route
                path={ROUTES.professional.smokeDucts.mention.generate()}
                element={<SmokeDuctMentionScreen />}
              />
              <Route
                path={ROUTES.professional.smokeDucts.form.generate()}
                element={<SmokeDuctMentionFormScreen />}
              />
              <Route
                path={ROUTES.professional.smokeDucts.iframe.generate()}
                element={<SmokeDuctMentionIframeScreen />}
              />

              {/*Worksite*/}
              <Route
                path={ROUTES.professional.worksites.details.generate(":id")}
                element={<WorksiteDetailScreen />}
              />
              <Route
                path={ROUTES.professional.worksites.form.generate()}
                element={<WorksiteFormScreen />}
              />

              {/*Gaz Month*/}
              <Route
                path={ROUTES.professional.gazMonth.details.generate()}
                element={<GazEventScreen />}
              />

              {/*Fiche Qualivisa*/}
              <Route
                path={ROUTES.professional.qualivisa.list.generate()}
                element={<QualivisaListScreen />}
              />
              <Route
                path={ROUTES.professional.qualivisa.details.generate(":id")}
                element={<QualivisaFormScreen />}
              />
              <Route
                path={ROUTES.professional.qualivisa.recap.generate(":id")}
                element={<QualivisaRecapScreen />}
              />

              {/*Tools*/}
              <Route
                path={ROUTES.professional.tools.generate()}
                element={<ToolsGazProfessionalScreen />}
              />

              {/*Green gas*/}
              <Route
                path={ROUTES.professional.greenGas.generate()}
                element={<GreenGasScreen />}
              />

              {/*Personal data*/}
              <Route
                path={ROUTES.professional.personalData.generate()}
                element={<PersonalDataScreen />}
              />
            </Route>

            <Route element={<AuthOPLayout />}>
              {/*Organisation */}
              <Route>
                <Route
                  path={ROUTES.professional.organisationProfessional.tools.generate()}
                  element={<ToolsOrganisationProfessionalScreen />}
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.adherentsPg.home.generate()}
                  element={<PGSubscribersOrganisationProfessionalScreen />}
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.adherentsPg.detail.generate(
                    ":id",
                  )}
                  element={
                    <DetailsPGSubscribersOrganisationProfessionalScreen />
                  }
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.adherentsPg.mail.generate(
                    ":id",
                  )}
                  element={<MailsProfessionalGazScreen />}
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.rg.home.generate()}
                  element={<RGOrganisationProfessionalScreen />}
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.rg.detail.generate(
                    ":id",
                  )}
                  element={
                    <DetailsPGSubscribersOrganisationProfessionalScreen />
                  }
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.requests.generate()}
                  element={<RequestsOrganisationProfessionalScreen />}
                />
                <Route
                  path={ROUTES.professional.organisationProfessional.mailOp.generate()}
                  element={<MailsOrganisationProfessionalScreen />}
                />
              </Route>
            </Route>

            {/*Errors*/}
            <Route
              path={ROUTES.professional.errors.wrongId.generate()}
              element={<WrongIdScreen />}
            />

            <Route
              path={ROUTES.professional.publicHome.generate()}
              element={<PageScreen />}
            />
          </Route>

          <Route
            path={ROUTES.public.maintenance.generate()}
            element={<MaintenanceScreen />}
          />
          <Route
            path={ROUTES.public.error404.generate()}
            element={<Error404Screen />}
          />
        </Routes>
      </MyContextProvider>
      <TertiaryModal
        open={
          authentifiedContext.user != undefined &&
          authentifiedContext?.user?.yoocanId !== undefined &&
          authentifiedContext.user.gazProfessionalTertiaryDetailId == null
        }
        className="large-modal"
      />
    </div>
  );
};

export default App;
