import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Equipment,
  ResponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import parse from "html-react-parser";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { qualivisaUtils } from "@utils/qualivisa-utils";
import InputFormField from "@components/inputs/InputFormField";
import { qualivisaStore } from "@state/qualivisa/QualivisaStore";
import { useStore } from "effector-react";
import { qualivisaMapper } from "@utils/qualivisa-mapper";
import { Form } from "antd";

interface QualivisaEquipmentPieceProps {
  pieceNumber: number;
  allEquipments: Equipment[];
  className?: string;
  equipmentInPiece: Equipment[];
  setEquipmentInPiece: (value: Equipment[]) => void;
  setPieceName: (value: string) => void;
  pieceName?: string;
  setSelectedResponses: (value: ResponseDto[]) => void;
  selectedResponses: ResponseDto[];
}

export const QualivisaEquipmentPiece: FC<QualivisaEquipmentPieceProps> = ({
  pieceNumber,
  allEquipments,
  equipmentInPiece,
  setEquipmentInPiece,
  setPieceName,
  pieceName,
  className = "",
  selectedResponses,
  setSelectedResponses,
}) => {
  const { t } = useTranslation();
  const qualivisaInfo = useStore(qualivisaStore);
  const [form] = Form.useForm();

  const InitialValues = {
    pieceName: pieceName,
  };

  return (
    <div className={`app-qualivisa-equipment-in-piece ${className}`}>
      <h3>{`${t<string>("pro.qualivisa.form.pieces.title")} ${pieceNumber}${
        pieceName ? " - ".concat(pieceName) : ""
      }`}</h3>
      <div>
        <Form form={form} initialValues={InitialValues}>
          <InputFormField
            module="pro.qualivisa.form.information"
            field="pieceName"
            className="flex-fill"
            onChange={(e) => setPieceName(e.target.value)}
            showLabel
          />
        </Form>
      </div>
      <div className="qualivisa-equipment-in-piece-cards">
        <div className="qualivisa-equipment-in-piece-card">
          <div className="qualivisa-equipment-in-piece-card-header">
            <p>
              <b>{t<string>("pro.qualivisa.form.pieces.left.title")}</b>
            </p>
            <span>
              {t<string>("pro.qualivisa.form.pieces.left.description")}
            </span>
          </div>
          <div className="qualivisa-equipment-in-piece-card-body">
            <div className="qualivisa-equipment-in-piece-card-body-items">
              {allEquipments?.map((item, key) => (
                <div
                  key={key}
                  className={`qualivisa-equipment-in-piece-card-body-item ${
                    equipmentInPiece.length < 2 ? "item-selectable" : ""
                  } ${equipmentInPiece.includes(item) ? "item-selected" : ""}`}
                  onClick={() => {
                    if (
                      !equipmentInPiece.includes(item) &&
                      equipmentInPiece.length < 2
                    ) {
                      setEquipmentInPiece([...equipmentInPiece, item]);
                      setSelectedResponses(
                        selectedResponses.filter(
                          (s) =>
                            qualivisaUtils.getPreviousQuestion(
                              s,
                              qualivisaInfo.qualivisaItems,
                            )?.first ||
                            qualivisaUtils.getPreviousQuestion(
                              s,
                              qualivisaInfo.qualivisaItems,
                            )?.question.fqKey === "fq_q_3.02",
                        ),
                      );
                    }
                  }}
                >
                  {parse(
                    t<string>(
                      qualivisaMapper.equipmentMapper?.filter(
                        (s) => s.value === item.equipment,
                      )[0]?.label,
                    ),
                  )}
                  {equipmentInPiece.includes(item) && <CheckOutlined />}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="qualivisa-equipment-in-piece-card">
          <div className="qualivisa-equipment-in-piece-card-header">
            <p>
              <b>{`${t<string>(
                "pro.qualivisa.form.pieces.right.title",
              )} ${pieceNumber}`}</b>
            </p>
            <span>
              {t<string>("pro.qualivisa.form.pieces.right.description")}
            </span>
          </div>
          <div className="qualivisa-equipment-in-piece-card-body">
            <div className="qualivisa-equipment-in-piece-card-body-items">
              {equipmentInPiece.map((item, key) => (
                <div
                  key={key}
                  className="qualivisa-equipment-in-piece-card-body-item item-added"
                  onClick={() => {
                    setEquipmentInPiece(
                      equipmentInPiece.filter((v) => v !== item),
                    );
                    setSelectedResponses(
                      selectedResponses.filter(
                        (s) =>
                          qualivisaUtils.getPreviousQuestion(
                            s,
                            qualivisaInfo.qualivisaItems,
                          )?.first ||
                          qualivisaUtils.getPreviousQuestion(
                            s,
                            qualivisaInfo.qualivisaItems,
                          )?.question.fqKey === "fq_q_3.02",
                      ),
                    );
                  }}
                >
                  {parse(
                    t<string>(
                      qualivisaMapper.equipmentMapper.filter(
                        (s) => s.value === item.equipment.slice(0, 9),
                      )[0]?.label,
                    ),
                  )}
                  <CloseOutlined />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
