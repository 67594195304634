import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Divider, Form, FormInstance, Modal, Row } from "antd";
import { formItemBlockLayout, phoneNumberFormatRegex } from "@utils/Constant";
import {
  requestPublishMaintenanceCertificate,
  requestPublishUpdatedMaintenanceCertificate,
} from "@state/maintenancecertificate/MaintenanceCertificateEffects";
import Accordions, {
  AccordionSection,
} from "@components/accordions/Accordions";
import Section from "@components/section/Section";
import InputFormField from "@components/inputs/InputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import RadioFormField from "@components/inputs/RadioFormField";
import parse from "html-react-parser";
import { MaintenanceCertificateResponseDto } from "@state/maintenancecertificate/dto/maintenance.certificate.item.response.dto";
import BasicButton from "@components/buttons/BasicButton";
import { toastError } from "@utils/toast-helper";
import moment, { MomentInput } from "moment/moment";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";

import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { getPublishRequest } from "@utils/maintenanceCertificate-utils";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import BasicModal from "@components/modals/BasicModal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import InputNumberFormField from "@components/inputs/InputNumberFormField";
import MaintenanceCertificateHelp2Modal from "@components/modals/maintenancecertificate/MaintenanceCertificateHelp2Modal";
import MaintenanceCertificateHelp3Modal from "@components/modals/maintenancecertificate/MaintenanceCertificateHelp3Modal";
import MaintenanceCertificateHelp4Modal from "@components/modals/maintenancecertificate/MaintenanceCertificateHelp4Modal";

const { confirm } = Modal;

interface MaintenanceCertificateFormProps {
  maintenanceCertificate?: MaintenanceCertificateResponseDto;
  setStatus?: (status: string) => void;
  handleSave: () => void;
  form: FormInstance;
  panelsWithError: string[];
  isLoadingParent?: boolean;
}

interface FormValues {
  section1clientFirstname: string;
  section1clientLastname: string;
  section1clientPhone: string;

  section1address: string;
  section1complementCity: string;
  section1boilerRoom: string;

  section2boilerBrand: string;
  section2boilerCapacity: string;
  section2boilerEvacuationMode: string;
  section2boilerCommissioningDate: MomentInput;
  section2boilerSerialNumero: string;
  section2boilerLastMaintenanceDate: MomentInput;

  section2bruleurAirSouffleBrand: string;
  section2bruleurAirSouffleCapacity: string;
  section2bruleurAirSouffleCommissioningDate: MomentInput;
  section2bruleurAirSouffleSerialNumero: string;
  section2bruleurAirSouffleLastMaintenanceDate: MomentInput;

  section3checkpointText1: string;
  section3checkpointText2: string;
  section3checkpointText3: string;
  section3checkpointText4: string;
  section3checkpointText5: string;
  section3checkpointText6: string;
  section3checkpointText7: string;
  section3checkpointText8: string;
  section3checkpointText9: string;
  section3checkpointText10: string;
  section3checkpointText11: string;
  section3checkpointText12: string;
  section3checkpointText13: string;
  section3checkpointText14: string;
  section3checkpointText15: string;
  section3checkpointText16: string;
  section3checkpointText17: string;
  section3checkpointText18: string;
  section3checkpointText19: string;
  section3checkpointText20: string;
  section3checkpointText21: string;
  section3checkpointText22: string;
  section3checkpointText23: string;
  section3checkpointText24: string;

  section3checkpointComment: string;
  section4Text1: string;
  section4Text2: string;
  section4Text3: string;
  section4Text4: string;
  section4Text5: string;
  section4Text6: string;
  section4Text7: string;
  section4Text8: string;
  section4Text9: string;
  section4Text10: string;
  section4Text11: string;
  section4Text12: string;
  section4mandatoryMeasureOfBruleurAirSouffleComment: string;
  section5adviceText1: string;
  section5adviceText2: string;
  section5adviceText3: string;
  section5adviceText4: string;
  section5adviceText5: string;
  section5adviceText6: string;
  section5adviceText7: string;
  section5adviceText8: string;
  section5adviceText9: string;
  section5adviceText10: string;
  section5adviceText11: string;
  section5adviceText12: string;
  section5adviceText13: string;
  section5adviceText14: string;
  section5adviceText15: string;
  section5adviceText16: string;
  section5adviceText17: string;
  section5adviceText18: string;
  section5adviceText19: string;
  section5adviceInstallationComment: string;
  section6dateOfVisite: MomentInput;
  section6nameOfPersonPresent: string;
  section6comment: string;
  section6technicianName: string;

  customerSignature: string;
  technicianSignature: string;
}

const initialValue = (
  maintenanceCertificate: MaintenanceCertificateResponseDto,
): FormValues => {
  return {
    section1clientFirstname: maintenanceCertificate.clientFirstname,
    section1clientLastname: maintenanceCertificate.clientLastname,
    section1clientPhone: maintenanceCertificate?.clientPhone,

    section1address: maintenanceCertificate.address,
    section1complementCity: maintenanceCertificate.complementCity,
    section1boilerRoom: maintenanceCertificate.boilerRoom,

    section2boilerBrand: maintenanceCertificate.boilerBrand,
    section2boilerCapacity: maintenanceCertificate.boilerCapacity,
    section2boilerEvacuationMode: maintenanceCertificate.boilerEvacuationMode,
    section2boilerCommissioningDate:
      maintenanceCertificate?.boilerCommissioningDate
        ? moment.utc(maintenanceCertificate.boilerCommissioningDate)
        : undefined,
    section2boilerSerialNumero: maintenanceCertificate.boilerSerialNumero,
    section2boilerLastMaintenanceDate:
      maintenanceCertificate?.boilerLastMaintenanceDate
        ? moment.utc(maintenanceCertificate.boilerLastMaintenanceDate)
        : null,

    section2bruleurAirSouffleBrand:
      maintenanceCertificate.bruleurAirSouffleBrand,
    section2bruleurAirSouffleCapacity:
      maintenanceCertificate.bruleurAirSouffleCapacity,
    section2bruleurAirSouffleCommissioningDate:
      maintenanceCertificate.bruleurAirSouffleCommissioningDate
        ? moment.utc(maintenanceCertificate.bruleurAirSouffleCommissioningDate)
        : null,
    section2bruleurAirSouffleSerialNumero:
      maintenanceCertificate.bruleurAirSouffleSerialNumero,
    section2bruleurAirSouffleLastMaintenanceDate:
      maintenanceCertificate.bruleurAirSouffleLastMaintenanceDate
        ? moment.utc(
            maintenanceCertificate.bruleurAirSouffleLastMaintenanceDate,
          )
        : null,
    section3checkpointText1: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text1,
    section3checkpointText2: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text2,
    section3checkpointText3: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text3,
    section3checkpointText4: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text4,
    section3checkpointText5: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text5,
    section3checkpointText6: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text6,
    section3checkpointText7: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text7,
    section3checkpointText8: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text8,
    section3checkpointText9: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text9,
    section3checkpointText10: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text10,
    section3checkpointText11: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text11,
    section3checkpointText12: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text12,
    section3checkpointText13: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text13,
    section3checkpointText14: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text14,
    section3checkpointText15: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text15,
    section3checkpointText16: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text16,
    section3checkpointText17: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text17,
    section3checkpointText18: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text18,
    section3checkpointText19: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text19,
    section3checkpointText20: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text20,
    section3checkpointText21: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text21,
    section3checkpointText22: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text22,
    section3checkpointText23: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text23,
    section3checkpointText24: JSON.parse(maintenanceCertificate?.checkpoint)
      ?.text24,
    section3checkpointComment: maintenanceCertificate.checkpointComment,
    section4Text1: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text1,
    section4Text2: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text2,
    section4Text3: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text3,
    section4Text4: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text4,
    section4Text5: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text5,
    section4Text6: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text6,
    section4Text7: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text7,
    section4Text8: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text8,
    section4Text9: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text9,
    section4Text10: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text10,
    section4Text11: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text11,
    section4Text12: JSON.parse(
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffle,
    )?.text12,
    section4mandatoryMeasureOfBruleurAirSouffleComment:
      maintenanceCertificate?.mandatoryMeasureOfBruleurAirSouffleComment,
    section5adviceText1: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text1,
    section5adviceText2: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text2,
    section5adviceText3: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text3,
    section5adviceText4: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text4,
    section5adviceText5: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text5,
    section5adviceText6: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text6,
    section5adviceText7: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text7,
    section5adviceText8: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text8,
    section5adviceText9: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text9,
    section5adviceText10: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text10,
    section5adviceText11: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text11,
    section5adviceText12: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text12,
    section5adviceText13: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text13,
    section5adviceText14: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text14,
    section5adviceText15: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text15,
    section5adviceText16: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text16,
    section5adviceText17: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text17,
    section5adviceText18: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text18,
    section5adviceText19: JSON.parse(maintenanceCertificate?.adviceInstallation)
      ?.text19,
    section5adviceInstallationComment:
      maintenanceCertificate.adviceInstallationComment,
    section6dateOfVisite: maintenanceCertificate.dateOfVisite
      ? moment.utc(maintenanceCertificate.dateOfVisite)
      : null,
    section6nameOfPersonPresent: maintenanceCertificate.nameOfPersonPresent,
    section6comment: maintenanceCertificate.comment,
    section6technicianName: maintenanceCertificate.technicianName,
    customerSignature: maintenanceCertificate.customerSignature,
    technicianSignature: maintenanceCertificate.technicianSignature,
  };
};

export const MaintenanceCertificateFormScreen: React.FC<
  MaintenanceCertificateFormProps
> = ({
  maintenanceCertificate,
  setStatus,
  form,
  isLoadingParent,
  handleSave,
  panelsWithError,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingParent ?? false);
  const sigCanvas = createRef<ReactSignatureCanvas>();
  const sigTechicianCanvas = createRef<ReactSignatureCanvas>();
  const [isEmpty, setIsEmpty] = useState<boolean>(
    maintenanceCertificate?.customerSignature != null,
  );
  const [isTechnicianSignatureEmpty, setIsTechnicianSignatureEmpty] = useState(
    maintenanceCertificate?.technicianSignature != null,
  );
  const [isModalHelpOpen2, setModalHelpOpen2] = useState<boolean>(false);
  const [isModalHelpOpen1, setModalHelpOpen1] = useState<boolean>(false);
  const [isModalHelpOpen3, setModalHelpOpen3] = useState<boolean>(false);
  const [isModalHelpOpen4, setModalHelpOpen4] = useState<boolean>(false);
  const [panelWithError, setPanelWithError] =
    useState<string[]>(panelsWithError);

  const clearSignature = () => {
    sigCanvas.current?.clear();
    setIsEmpty(true);
  };

  const clearTechnicianSignature = () => {
    sigTechicianCanvas.current?.clear();
    setIsTechnicianSignatureEmpty(true);
  };

  useEffect(() => {
    if (maintenanceCertificate?.technicianSignature) {
      sigTechicianCanvas.current?.fromDataURL(
        `data:image/png;base64,${maintenanceCertificate?.technicianSignature}`,
      );
      setIsEmpty(false);
    }
    if (maintenanceCertificate?.customerSignature) {
      sigCanvas.current?.fromDataURL(
        `data:image/png;base64,${maintenanceCertificate?.customerSignature}`,
      );
      setIsTechnicianSignatureEmpty(false);
    }
  }, [
    maintenanceCertificate?.technicianSignature,
    maintenanceCertificate?.customerSignature,
  ]);

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: t<string>("pro.certificate.form.modal.save.title"),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        className: "app-modal professional",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const hasErrors = (id: string) => {
    return panelWithError?.includes(id);
  };
  const section: AccordionSection[] = [
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section1") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section1")}
          </div>
          {hasErrors("section1") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <Section title="Client" type="h3">
            <div className="d-flex flex-column flex-sm-row justify-content-between mt-20 gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="clientFirstname"
                className="flex-fill "
                required
                showLabel
                fieldPrefix="section1"
              />
              <InputFormField
                module="pro.certificate.form"
                field="clientLastname"
                className="flex-fill"
                required
                showLabel
                fieldPrefix="section1"
              />
            </div>
            <div>
              <MaskInputFormField
                type="tel"
                mask="## ## ## ## ##"
                showLabel
                module="pro.certificate.form"
                field="clientPhone"
                fieldPrefix="section1"
                rules={[
                  {
                    pattern: new RegExp(phoneNumberFormatRegex),
                    message: t<string>("forms.errors.format"),
                  },
                  {
                    min: 14,
                    message: t<string>("forms.errors.length", { size: "10" }),
                  },
                ]}
                emptyErrorMessage={t<string>(
                  "search.contacts.form.fields.phone.errors.required",
                )}
              />
            </div>
          </Section>
          <Section title="Adresse" type="h3">
            <AddressFields
              module="pro.certificate.form"
              field="address"
              showLabel
              required
              className="mt-20"
              fieldPrefix="section1"
              placeholder={t<string>(
                "pro.certificate.form.form.fields.address.placeholder",
              )}
            />
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="complementCity"
                className="flex-fill"
                showLabel
                fieldPrefix="section1"
              />
              <InputFormField
                module="pro.certificate.form"
                field="boilerRoom"
                className="flex-fill"
                showLabel
                fieldPrefix="section1"
              />
            </div>
          </Section>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section2") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section2")}
          </div>
          {hasErrors("section2") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <Section title="Caractéristiques de la chaudière" type="h3">
            <div onClick={() => setModalHelpOpen3(true)}>
              <QuestionCircleOutlined className="mr-10" />
              {t<string>("pro.certificate.form.help.title")}
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5 mt-20">
              <InputFormField
                module="pro.certificate.form"
                field="boilerBrand"
                showLabel
                className="flex-fill"
                required
                fieldPrefix="section2"
              />
              <InputFormField
                module="pro.certificate.form"
                field="boilerCapacity"
                className="flex-fill"
                showLabel
                fieldPrefix="section2"
                required
              />
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="boilerEvacuationMode"
                className="flex-fill"
                showLabel
                fieldPrefix="section2"
                required
              />
              <DatePickerFormField
                module="pro.certificate.form"
                field="boilerCommissioningDate"
                showLabel
                fieldPrefix="section2"
              />
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="boilerSerialNumero"
                className="flex-fill"
                showLabel
                fieldPrefix="section2"
              />

              <DatePickerFormField
                module="pro.certificate.form"
                field="boilerLastMaintenanceDate"
                showLabel
                fieldPrefix="section2"
              />
            </div>
          </Section>
          <Section title="Caractéristiques du brûleur à air soufflé" type="h3">
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5 mt-20">
              <InputFormField
                module="pro.certificate.form"
                field="bruleurAirSouffleBrand"
                className="flex-fill"
                showLabel
                fieldPrefix="section2"
              />
              <InputFormField
                module="pro.certificate.form"
                field="bruleurAirSouffleCapacity"
                className="flex-fill"
                showLabel
                fieldPrefix="section2"
                autocomplete="nope"
              />
            </div>

            <DatePickerFormField
              module="pro.certificate.form"
              showLabel
              field="bruleurAirSouffleCommissioningDate"
              fieldPrefix="section2"
            />

            <InputFormField
              module="pro.certificate.form"
              field="bruleurAirSouffleSerialNumero"
              showLabel
              className="flex-fill"
              fieldPrefix="section2"
            />

            <DatePickerFormField
              module="pro.certificate.form"
              field="bruleurAirSouffleLastMaintenanceDate"
              showLabel
              fieldPrefix="section2"
            />
          </Section>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section3") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section3.title")}
          </div>
          {hasErrors("section3") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text1")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText1"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text2")}
            </span>
            <RadioFormField
              required
              fieldPrefix="section3"
              field="checkpointText2"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text3")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              field="checkpointText3"
              required
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text4")}
            </span>
            <RadioFormField
              required
              fieldPrefix="section3"
              field="checkpointText4"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text5")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText5"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text6")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText6"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text7")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText7"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text8")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText8"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text9")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText9"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text10")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText10"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text11")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText11"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text12")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText12"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text13")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText13"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text14")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText14"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text15")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText15"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text16")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText16"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text17")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText17"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text18")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText18"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section3.text19")}
            </span>
            <RadioFormField
              fieldPrefix="section3"
              required
              field="checkpointText19"
              options={[
                {
                  value: "validated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.validated",
                  ),
                },
                {
                  value: "noValidated",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.noValidated",
                  ),
                },
                {
                  value: "sansObjet",
                  label: t<string>(
                    "pro.certificate.form.section3.radioButton.sansObjet",
                  ),
                },
              ]}
            />
          </div>
          <Section
            title={t<string>("pro.certificate.form.section3.subtitle")}
            type="h3"
            className="mt-20"
          >
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section3.text20")}
              </span>
              <RadioFormField
                fieldPrefix="section3"
                required
                field="checkpointText20"
                options={[
                  {
                    value: "validated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.validated",
                    ),
                  },
                  {
                    value: "noValidated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.noValidated",
                    ),
                  },
                  {
                    value: "sansObjet",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.sansObjet",
                    ),
                  },
                ]}
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section3.text21")}
              </span>
              <RadioFormField
                fieldPrefix="section3"
                required
                field="checkpointText21"
                options={[
                  {
                    value: "validated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.validated",
                    ),
                  },
                  {
                    value: "noValidated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.noValidated",
                    ),
                  },
                  {
                    value: "sansObjet",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.sansObjet",
                    ),
                  },
                ]}
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section3.text22")}
              </span>
              <RadioFormField
                fieldPrefix="section3"
                required
                field="checkpointText22"
                options={[
                  {
                    value: "validated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.validated",
                    ),
                  },
                  {
                    value: "noValidated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.noValidated",
                    ),
                  },
                  {
                    value: "sansObjet",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.sansObjet",
                    ),
                  },
                ]}
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section3.text23")}
              </span>
              <RadioFormField
                fieldPrefix="section3"
                required
                field="checkpointText23"
                options={[
                  {
                    value: "validated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.validated",
                    ),
                  },
                  {
                    value: "noValidated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.noValidated",
                    ),
                  },
                  {
                    value: "sansObjet",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.sansObjet",
                    ),
                  },
                ]}
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section3.text24")}
              </span>
              <RadioFormField
                fieldPrefix="section3"
                required
                field="checkpointText24"
                options={[
                  {
                    value: "validated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.validated",
                    ),
                  },
                  {
                    value: "noValidated",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.noValidated",
                    ),
                  },
                  {
                    value: "sansObjet",
                    label: t<string>(
                      "pro.certificate.form.section3.radioButton.sansObjet",
                    ),
                  },
                ]}
              />
            </div>
          </Section>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section4b") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section4.title")}
          </div>
          {hasErrors("section4b") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <Section
            title={t<string>("pro.certificate.form.section4.subtitle1")}
            type="h3"
          >
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5 mt-20">
              <InputFormField
                module="pro.certificate.form"
                field="section4Text1"
                className="flex-fill"
                maxLength={3}
                showLabel
              />
              <InputFormField
                module="pro.certificate.form"
                field="section4Text2"
                className="flex-fill"
                maxLength={3}
                showLabel
              />
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="section4Text3"
                className="flex-fill"
                maxLength={3}
                showLabel
              />
              <InputFormField
                module="pro.certificate.form"
                field="section4Text4"
                className="flex-fill"
                maxLength={3}
                showLabel
              />
            </div>
            <InputFormField
              module="pro.certificate.form"
              field="section4Text5"
              className="flex-fill"
              showLabel
            />
          </Section>
          <Section
            title={t<string>("pro.certificate.form.section4.subtitle2")}
            type="h3"
          >
            <InputNumberFormField
              module="pro.certificate.form"
              field="section4Text6"
              className="flex-fill"
              labelTooltip={t<string>(
                "pro.certificate.form.form.fields.section4Text6.label",
              )}
              showLabel
            />
          </Section>
          <Divider />
          <div className="mb-3" onClick={() => setModalHelpOpen1(true)}>
            <QuestionCircleOutlined className="mr-10" />
            {t<string>("pro.certificate.form.help.title")}
          </div>
          <Section
            title={t<string>("pro.certificate.form.section4.subtitle3")}
            type="h3"
          >
            <InputFormField
              module="pro.certificate.form"
              field="section4Text7"
              className="flex-fill mt-20"
              showLabel
              labelTooltip={t<string>(
                "pro.certificate.form.form.fields.section4Text7.label",
              )}
            />
            <InputFormField
              module="pro.certificate.form"
              field="section4Text8"
              className="flex-fill"
              showLabel
              labelTooltip={t<string>(
                "pro.certificate.form.form.fields.section4Text8.label",
              )}
            />
            <Divider />
            <div className="mb-3" onClick={() => setModalHelpOpen2(true)}>
              <QuestionCircleOutlined className="mr-10" />
              {t<string>("pro.certificate.form.help.title")}
            </div>
          </Section>
          <Section
            title={t<string>("pro.certificate.form.section4.subtitle4")}
            type="h3"
          >
            <InputFormField
              module="pro.certificate.form"
              field="section4Text9"
              className="flex-fill mt-20"
              showLabel
              labelTooltip={t<string>(
                "pro.certificate.form.form.fields.section4Text9.label",
              )}
            />
          </Section>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section4") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section4.subtitle5")}
          </div>
          {hasErrors("section4") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <div onClick={() => setModalHelpOpen4(true)}>
            <QuestionCircleOutlined className="mr-10" />
            {t<string>("pro.certificate.form.help.title")}
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section4.text11")}
            </span>
            <RadioFormField
              required
              field="section4Text11"
              options={[
                {
                  value: "standard",
                  label: t<string>(
                    "pro.certificate.form.section4.radioButton.standard",
                  ),
                },
                {
                  value: "condensation",
                  label: t<string>(
                    "pro.certificate.form.section4.radioButton.condensation",
                  ),
                },
              ]}
            />
          </div>
          <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
            <span className="radio-label flex-grow-1">
              {t<string>("pro.certificate.form.section4.text12")}
            </span>
            <RadioFormField
              required
              field="section4Text12"
              options={[
                {
                  value: "av2005",
                  label: t<string>(
                    "pro.certificate.form.section4.radioButton.av2005",
                  ),
                },
                {
                  value: "ap2005",
                  label: t<string>(
                    "pro.certificate.form.section4.radioButton.ap2005",
                  ),
                },
              ]}
            />
          </div>
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div>
            {t<string>(
              "pro.certificate.form.form.fields.mandatoryMeasureOfBruleurAirSouffleComment.label",
            )}
          </div>
        </div>
      ),
      content: (
        <TextAreaFormField
          module="pro.certificate.form"
          field="mandatoryMeasureOfBruleurAirSouffleComment"
          className="flex-fill"
          fieldPrefix="section4"
          maxLength={500}
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          <div className={`${hasErrors("section5") ? "text-danger" : ""} `}>
            {t<string>("pro.certificate.form.section5.title")}
          </div>
          {hasErrors("section5") && (
            <WarningOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
      content: (
        <>
          <Section
            title={t<string>("pro.certificate.form.section5.subtitle1")}
            type="h3"
          >
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text1")}
              </span>
              <CheckboxFormField
                field="adviceText1"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text2")}
              </span>
              <CheckboxFormField
                field="adviceText2"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text3")}
              </span>
              <CheckboxFormField
                field="adviceText3"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text4")}
              </span>
              <CheckboxFormField
                field="adviceText4"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text5")}
              </span>
              <CheckboxFormField
                field="adviceText5"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text6")}
              </span>
              <CheckboxFormField
                field="adviceText6"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
          </Section>
          <Section
            title={t<string>("pro.certificate.form.section5.subtitle2")}
            type="h3"
          >
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text7")}
              </span>
              <CheckboxFormField
                field="adviceText7"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text8")}
              </span>
              <CheckboxFormField
                field="adviceText8"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text9")}
              </span>
              <CheckboxFormField
                field="adviceText9"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text10")}
              </span>
              <CheckboxFormField
                field="adviceText10"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text11")}
              </span>
              <CheckboxFormField
                field="adviceText11"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text12")}
              </span>
              <CheckboxFormField
                field="adviceText12"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text13")}
              </span>
              <CheckboxFormField
                field="adviceText13"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text14")}
              </span>
              <CheckboxFormField
                field="adviceText14"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text15")}
              </span>
              <CheckboxFormField
                field="adviceText15"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text16")}
              </span>
              <CheckboxFormField
                field="adviceText16"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text17")}
              </span>
              <CheckboxFormField
                field="adviceText17"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {t<string>("pro.certificate.form.section5.text18")}
              </span>
              <CheckboxFormField
                field="adviceText18"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
            <div className="radio-container flex-wrap flex-xl-nowrap d-flex justify-content-end gap-2 gap-xl-5 mt-20">
              <span className="radio-label flex-grow-1">
                {parse(t<string>("pro.certificate.form.section5.text19"))}
              </span>
              <CheckboxFormField
                field="adviceText19"
                fieldPrefix="section5"
                noLabel
                module="pro.certificate.form"
              />
            </div>
          </Section>
        </>
      ),
    },
  ];

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        void confirmationPopup().then((confirmed: boolean) => {
          if (confirmed) {
            setIsLoading(true);
            if (maintenanceCertificate?.id) {
              void requestPublishUpdatedMaintenanceCertificate({
                id: maintenanceCertificate?.id,
                dto: getPublishRequest(values, sigCanvas, sigTechicianCanvas),
              });
            } else {
              void requestPublishMaintenanceCertificate({
                dto: getPublishRequest(values, sigCanvas, sigTechicianCanvas),
              });
            }
          }
        });
      })
      .catch(() => {
        toastError(t<string>("pro.certificate.form.form.errors"));
        let sectionWithError: string[] = [];
        setPanelWithError(sectionWithError);
        form.getFieldsError().forEach((m) => {
          if (m.errors.length != 0) {
            const section: string = m.name[0].toString().substring(0, 8);
            if (!sectionWithError.includes(section)) {
              sectionWithError = [...sectionWithError, section];
            }
          }
        });
        setPanelWithError(sectionWithError);
      });
  };

  useEffect(() => {
    return requestPublishMaintenanceCertificate.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setStatus?.(result.data.status);
        navigate(
          ROUTES.professional.maintenanceCertificate.details.generate(
            result.data.id,
          ),
        );
      } else {
        toastError(result.errorMessage);
      }
      setIsLoading(false);
    });
  });

  useEffect(() => {
    return requestPublishUpdatedMaintenanceCertificate.done.watch(
      ({ result }) => {
        if (result.ok && result.data) {
          setStatus?.(result.data.status);
        } else {
          toastError(result.errorMessage);
        }
        setIsLoading(false);
      },
    );
  });

  return (
    <>
      <Form
        form={form}
        {...formItemBlockLayout}
        initialValues={
          maintenanceCertificate && initialValue(maintenanceCertificate)
        }
      >
        <>
          <Accordions sections={section} />
          <Section
            title={t<string>("pro.certificate.form.section6.subtitle1")}
            type="h2"
            className="mt-30"
          >
            <div className="d-flex flex-column flex-sm-row justify-content-between mt-20 gap-sm-5">
              <InputFormField
                module="pro.certificate.form"
                field="nameOfPersonPresent"
                className="flex-fill "
                showLabel
                fieldPrefix="section6"
                required
              />
              <DatePickerFormField
                module="pro.certificate.form"
                field="dateOfVisite"
                className="flex-fill"
                showLabel
                fieldPrefix="section6"
                required
              />
            </div>
            <InputFormField
              module="pro.certificate.form"
              field="comment"
              className="flex-fill "
              fieldPrefix="section6"
              showLabel
            />

            <div className="signature-container">
              <div
                className={`signature-placeholder ${
                  isEmpty ? "signature-empty" : ""
                }`}
              >
                {t<string>("pro.certificate.form.section6.signature")}
              </div>
              <SignatureCanvas
                canvasProps={{ className: "sigCanvas" }}
                ref={sigCanvas}
                onBegin={() => setIsEmpty(false)}
              />
              <Row className="mt-8">
                <Col xs={24}>
                  <BasicButton
                    className="w-100"
                    text={t<string>("buttons.wipe")}
                    variant="tertiary"
                    disabled={isEmpty}
                    onClick={() => clearSignature()}
                  />
                </Col>
              </Row>
            </div>

            <InputFormField
              module="pro.certificate.form"
              field="technicianName"
              className="flex-fill mt-30 "
              showLabel
              fieldPrefix="section6"
              required
            />

            <div className="signature-container">
              <div
                className={`signature-placeholder ${
                  isTechnicianSignatureEmpty ? "signature-empty" : ""
                }`}
              >
                {t<string>("pro.certificate.form.section6.signature")}
              </div>
              <SignatureCanvas
                canvasProps={{ className: "sigCanvas" }}
                ref={sigTechicianCanvas}
                onBegin={() => setIsTechnicianSignatureEmpty(false)}
              />
              <Row className="mt-8">
                <Col xs={24}>
                  <BasicButton
                    className="w-100"
                    text={t<string>("buttons.wipe")}
                    variant="tertiary"
                    disabled={isTechnicianSignatureEmpty}
                    onClick={() => clearTechnicianSignature()}
                  />
                </Col>
              </Row>
            </div>
          </Section>
          <div className="mt-3 mb-1">
            {parse(t<string>("pro.certificate.form.saveInformation"))}
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <BasicButton
              onClick={() => handleSubmit()}
              variant="primary"
              className="mw-190 mb-30 mx-2 "
              text={t<string>("pro.certificate.form.form.actions.submit")}
              isLoading={isLoading}
              disabled={isTechnicianSignatureEmpty}
            />
            <BasicButton
              onClick={() => handleSave()}
              variant="primary"
              className="mw-190 mb-30 mx-2 "
              text={t<string>("buttons.save")}
              isLoading={isLoading}
            />
          </div>
        </>
      </Form>
      <BasicModal
        className="large-modal"
        open={isModalHelpOpen1}
        onCancel={() => setModalHelpOpen1(false)}
        title={
          "Rendement sur PCI de la chaudière à puissance nominalement utile de fonctionnement pour chaudière type B uniquement"
        }
      >
        <>
          <h3 className="mb-20">
            {t<string>("pro.certificate.form.help.section4.subtitle1")}
          </h3>
          <div>
            {parse(t<string>("pro.certificate.form.help.section4.text1"))}
          </div>
          <h3 className="mt-30 mb-20">
            {t<string>("pro.certificate.form.help.section4.subtitle2")}
          </h3>

          <div className="mb-30">
            {parse(t<string>("pro.certificate.form.help.section4.text2"))}
          </div>
          <div className="maintenance-certificate-help">
            <img
              src="/img/pro/maintenanceCertificateRendementChaudiere.png"
              alt={"Rendement sur PCI"}
            />
          </div>
        </>
      </BasicModal>

      <MaintenanceCertificateHelp2Modal
        className="large-modal"
        open={isModalHelpOpen2}
        onCancel={() => setModalHelpOpen2(false)}
      />
      <MaintenanceCertificateHelp3Modal
        className="large-modal"
        open={isModalHelpOpen3}
        onCancel={() => setModalHelpOpen3(false)}
      />
      <MaintenanceCertificateHelp4Modal
        className="large-modal"
        open={isModalHelpOpen4}
        onCancel={() => setModalHelpOpen4(false)}
      />
    </>
  );
};
