import configuration from "@utils/Config";
import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { createEffect } from "effector";
import {
  QualivisaFile,
  QualivisaInformation,
  QualiVisaItemResponseDto,
  QualiVisaRecapResponseDto,
  QualivisaReponseDto,
} from "@state/qualivisa/dto/quali.visa.item.response.dto";
import {
  QualiVisaSaveConformityAnswersRequestDto,
  QualiVisaSaveInformationRequestDto,
  QualiVisaSaveRequestDto,
  QualiVisaSaveRoomRequestDto,
} from "@state/qualivisa/dto/quali.visa.save.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/qualivisa`;

export const requestCreateQualivisa = createEffect({
  handler: restCreationHandler<null, QualiVisaItemResponseDto>(baseUrl),
});

export const requestUpdateQualivisaInformation = createEffect({
  handler: restUpdateHandler<
    QualiVisaSaveInformationRequestDto,
    QualivisaReponseDto
  >(`${baseUrl}/`, "/information"),
});

export const requestUpdateQualivisa = createEffect({
  handler: restUpdateHandler<QualiVisaSaveRequestDto, QualivisaReponseDto>(
    `${baseUrl}/`,
  ),
});

export const requestUpdateRoomQualivisa = createEffect({
  handler: restUpdateHandler<QualiVisaSaveRoomRequestDto, QualivisaReponseDto>(
    `${baseUrl}/`,
    "/room",
  ),
});

export const requestUpdateConformityAnswersQualivisa = createEffect({
  handler: restUpdateHandler<
    QualiVisaSaveConformityAnswersRequestDto,
    QualivisaInformation
  >(`${baseUrl}/`, "/conformity"),
});

export const requestGetQualivisaStructure = createEffect({
  handler: restGetUniqueHandler<
    QualivisaReponseDto,
    { id: string; step: string }
  >(`${baseUrl}/step`),
});

export const requestGetQualivisaInformation = createEffect({
  handler: restDetailsHandler<QualivisaInformation>(`${baseUrl}/`),
});

export const requestGetRecapQualivisa = createEffect({
  handler: restDetailsHandler<QualiVisaRecapResponseDto>(
    `${baseUrl}/`,
    "/recap",
  ),
});

export const requestGetQualivisas = createEffect({
  handler: restListHandler<QualivisaFile>(baseUrl),
});

export const requestDeleteMaintenanceCertificates = createEffect({
  handler: restDeletionHandler<QualiVisaItemResponseDto>(`${baseUrl}/`),
});

export const requestGetQualivisaFormExport = createEffect({
  handler: restDetailsHandler<Blob>(`${baseUrl}/`, "/export"),
});
