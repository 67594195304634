import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Empty, Spin, Table } from "antd";
import FileSaver from "file-saver";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import Section from "@components/section/Section";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { requestGetMentionSmokeDuctExport } from "@state/mention/smokeduct/MentionSmokeDuctEffects";
import { columns } from "@components/lists/WorksiteColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { WorksiteItemsResponseDto } from "@state/worksite/dto/worksite.response.dto";
import { requestGetWorksites } from "@state/worksite/WorksiteEffects";
import { SorterResult } from "antd/es/table/interface";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { mapResourceTofileType } from "@utils/upload-utils";
import { File } from "@type/resource-type-interface";
import FilesCard from "@components/files/FilesCard";
import { requestGetWebinar } from "@state/webinar/WebinarEffects";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import WebinarRegister from "@components/webinar/WebinarRegister";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import CertificateCard from "@components/cards/CertificateCard";
import BasicCard from "@components/cards/BasicCard";

export const SmokeDuctMentionScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [webinar, setWebinar] = useState<WebinarItemResponse>();
  const [smokeDuctFiles, setSmokeDuctFiles] = useState<File[]>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const mentionSmokeDuct = authentifiedUser?.smokeDuct;

  const [worksites, setWorksites] =
    useState<Pageable<WorksiteItemsResponseDto>>(emptySearchResult);

  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "createdAt",
    order: "descend",
  });

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const handleDownload = () => {
    setButtonLoading(true);
    void requestGetMentionSmokeDuctExport({
      dto: {},
    });
  };

  useEffect(() => {
    return requestGetMentionSmokeDuctExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: result.data.type,
      });
      FileSaver.saveAs(data, result.fileName);
    } else {
      toastError(t<string>("error"));
    }
  };

  const fetchWorksite = useCallback(() => {
    if (
      authentifiedUser?.smokeDuct &&
      authentifiedUser?.smokeDuct.elearningStatus === "VALIDATED" &&
      authentifiedUser?.smokeDuct.status === "ACCEPTED"
    ) {
      setDataHasLoaded(false);
      requestGetWorksites({
        page: pageNumber - 1,
        limit,
        sorter,
        dto: {},
      })
        .catch(() => {
          toastError(t<string>("notification.void messages.error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
    }
  }, [authentifiedUser?.smokeDuct, pageNumber, limit, sorter, t]);

  useEffect(() => {
    fetchWorksite();
  }, [fetchWorksite]);

  useEffect(() => {
    return requestGetWorksites.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWorksites(result.data);
      }
    });
  });

  useEffect(() => {
    setIsLoading(true);
    void requestGetWebinar("presentation-smoke-duct-20221116");
    void requestGetResources({
      dto: {
        domain: ["SMOKE_DUCT"],
        ids: [],
      },
    });
  }, []);

  useEffect(() => {
    return requestGetWebinar.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWebinar(result.data);
      }
    });
  });

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setSmokeDuctFiles(
          result.data.map((resource) => {
            return mapResourceTofileType(resource);
          }),
        );
      }
      setIsLoading(false);
    });
  });

  const renderScreenFromMentionStatus = () => {
    if (
      authentifiedUser?.smokeDuct == null ||
      authentifiedUser?.smokeDuct?.elearningStatus === "IN_PROGRESS"
    ) {
      return (
        <>
          <BasicCard className="card-mention mb-60">
            <div className="d-flex flex-column flex-lg-row">
              <div className="card-mention-left">
                <h2 className="mb-4">
                  {t<string>(
                    "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.title",
                  )}
                </h2>
                <div className="d-flex align-items-center mb-4">
                  <img
                    src="/img/pro/clock.svg"
                    alt={t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.hoursAlt",
                    )}
                  />
                  <div className="card-mention-left-description ms-3">
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.hours",
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <img
                    src="/img/pro/question.svg"
                    alt={t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.questionsAlt",
                    )}
                  />
                  <div className="card-mention-left-description ms-3">
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.questions",
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <img
                    src="/img/pro/euros.svg"
                    alt={t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.priceAlt",
                    )}
                  />
                  <div className="card-mention-left-description ms-3">
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.price",
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <img
                    src="/img/pro/module.svg"
                    alt={t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.moduleAlt",
                    )}
                  />
                  <div className="d-flex flex-column">
                    <div className="card-mention-left-description ms-3">
                      {t<string>(
                        "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.module",
                      )}
                    </div>
                    <div className="card-mention-left-description ms-3">
                      {t<string>(
                        "pro.mentions.smokeDuct.detail.noCertificate.formation.detail.moduleMore",
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-mention-right">
                <h2 className="mb-4">
                  {t<string>(
                    "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.title",
                  )}
                </h2>
                <div className="mb-20">
                  {t<string>(
                    "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.description",
                  )}
                </div>
                <ul>
                  <li>
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.list1",
                    )}
                  </li>
                  <li>
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.list2",
                    )}
                  </li>
                  <li>
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.list3",
                    )}
                  </li>
                  <li>
                    {t<string>(
                      "pro.mentions.smokeDuct.detail.noCertificate.formation.explanation.list4",
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </BasicCard>
          <div className="smoke-duct-frieze">
            <img
              alt={t<string>("smokeDuct.frieze.img.alt")}
              src="/img/pro/smoke-duct-frieze.svg"
            />
          </div>
          <h3>
            {t<string>(
              "pro.mentions.smokeDuct.detail.noCertificate.mentions.title",
            )}
          </h3>
          <div className="mentions-details">
            <span>
              {t<string>(
                "pro.mentions.smokeDuct.detail.noCertificate.mentions.details",
              )}
            </span>
          </div>
        </>
      );
    } else if (authentifiedUser?.smokeDuct?.status === "INIT") {
      return (
        <div className="requested-mention-container d-flex flex-column flex-lg-row align-items-center align-items-lg-start mt-2">
          <div className="request-text-container mt-20">
            <h2 className="mb-2">
              {t<string>(
                "pro.mentions.smokeDuct.detail.requestedCertificate.subtitle",
              )}
            </h2>
            <div>
              {t<string>(
                "pro.mentions.smokeDuct.detail.requestedCertificate.description1",
              )}
            </div>
            <div>
              {t<string>(
                "pro.mentions.smokeDuct.detail.requestedCertificate.description2",
              )}
            </div>
          </div>
          <div className="request-img">
            <img
              alt={t<string>("error.img.alt")}
              src="/img/error404/error2.svg"
            />
          </div>
        </div>
      );
    } else if (authentifiedUser?.smokeDuct?.status === "ACCEPTED") {
      return (
        <Spin spinning={buttonLoading}>
          <Section
            type="h2"
            title={t<string>(
              "pro.mentions.smokeDuct.detail.certificate.titleSection1",
            )}
            titleClassName="text-primary px-12"
          >
            <CertificateCard
              type="smokeDuct"
              date={
                (authentifiedUser && authentifiedUser?.smokeDuct?.updatedAt) ||
                ""
              }
              title={t<string>(
                "pro.mentions.smokeDuct.detail.certificate.titleCertificate",
              )}
              onClick={handleDownload}
              className="mb-3"
            />
          </Section>
          <Section
            type="h2"
            title={t<string>(
              "pro.mentions.smokeDuct.detail.certificate.titleSection2",
            )}
            titleClassName="text-black my-5"
          >
            {mentionSmokeDuct?.canObtainABonusOnWorksite ? (
              <div className="d-flex flex-wrap px-12">
                <BasicButton
                  variant="primary"
                  text={t<string>(
                    "pro.mentions.smokeDuct.detail.certificate.buttonSection2",
                  )}
                  onClick={() =>
                    navigate(ROUTES.professional.worksites.form.generate())
                  }
                  className="mention-button mb-4"
                />
              </div>
            ) : (
              <span>
                {
                  "Depuis le 01/01/2025, le dépôt des chantiers doit être soumis à validation par votre responsable de secteur GRDF. Merci de le contacter pour pouvoir valider le dépôt du dossier."
                }
              </span>
            )}
          </Section>
          <Section
            type="h3"
            title={t<string>(
              "pro.mentions.smokeDuct.detail.certificate.titleSection3",
            )}
            titleClassName="text-primary px-12 my-5"
          >
            <div>
              {worksites.content.length != 0 ? (
                <Table
                  locale={{
                    emptyText: (
                      <Empty description={t<string>("common.noData")} />
                    ),
                  }}
                  columns={columns}
                  dataSource={worksites.content}
                  loading={!dataHasLoaded}
                  size="small"
                  className="app-contact-pro-table"
                  rowClassName={(record: WorksiteItemsResponseDto) =>
                    record.worksiteStatus.toLowerCase()
                  }
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(
                          ROUTES.professional.worksites.details.generate(
                            record.id,
                          ),
                          {
                            state: {
                              initialContact: record,
                            },
                          },
                        );
                      },
                    };
                  }}
                  pagination={{
                    total: worksites.totalElements,
                    pageSize: worksites.size,
                    current: worksites.number + 1,
                    onChange: setPageNumber,
                    onShowSizeChange: onChangeLimit,
                    showSizeChanger: true,
                  }}
                  onChange={(pagination, filters, sorter) =>
                    setSorter(sorter as SorterResult<any>)
                  }
                />
              ) : (
                t<string>(
                  "pro.mentions.smokeDuct.detail.certificate.noWorksite",
                )
              )}
            </div>
          </Section>
          <Section
            type="h2"
            title={t<string>(
              "pro.mentions.smokeDuct.detail.certificate.titleSection4",
            )}
            titleClassName="text-primary px-12 my-5"
          >
            {!isLoading ? (
              <div className="d-flex justify-content-center flex-wrap">
                <FilesCard files={smokeDuctFiles} />
              </div>
            ) : (
              <div className="anticon-resource">
                <Spin size="large" />
              </div>
            )}
          </Section>
        </Spin>
      );
    } else return null;
  };

  return (
    <PageLayout
      container
      title={
        mentionSmokeDuct
          ? t<string>("pro.mentions.smokeDuct.detail.certificate.title")
          : t<string>("pro.mentions.smokeDuct.detail.noCertificate.title")
      }
      titleClassName="mention-smokeDuct-title"
      titleCentered={false}
      headerRightContent={
        !mentionSmokeDuct ||
        mentionSmokeDuct.elearningStatus === "IN_PROGRESS" ? (
          <img
            src="/img/pro/logo-smoke-duct.svg"
            alt={t<string>(
              "pro.mentions.smokeDuct.detail.noCertificate.imgAlt",
            )}
            className="mention-smokeDuct-logo"
          />
        ) : null
      }
      headerLeftContent={
        !mentionSmokeDuct ? (
          <div className="mt-20">
            <BasicButton
              variant="primary"
              text={t<string>(
                "pro.mentions.smokeDuct.detail.noCertificate.buttonText",
              )}
              onClick={() =>
                navigate(ROUTES.professional.smokeDucts.form.generate())
              }
              className="mention-button mb-4"
            />
          </div>
        ) : (
          <div>
            {" "}
            {authentifiedUser.smokeDuct?.elearningStatus === "IN_PROGRESS" && (
              <BasicButton
                variant="primary"
                text={t<string>(
                  "pro.mentions.smokeDuct.detail.certificate.buttonText.iframe",
                )}
                onClick={() =>
                  navigate(ROUTES.professional.smokeDucts.iframe.generate())
                }
                className="mention-button mb-4"
              />
            )}
          </div>
        )
      }
    >
      <>
        {webinar && (
          <WebinarRegister
            webinar={webinar}
            title="webinar.presentation-smoke-duct.title"
            description="webinar.presentation-smoke-duct.description"
            modalContent="webinar.presentation-smoke-duct.modal.content"
            className="mb-20"
          />
        )}
        {renderScreenFromMentionStatus()}
      </>
    </PageLayout>
  );
};
